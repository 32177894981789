import React from 'react';
import 'normalize.css';
import type { AppProps } from 'next/app';
import 'animate.css';
import 'react-toastify'; //Import react-toastify so that it will be included in every bundle
import 'react-toastify/dist/ReactToastify.css';
import '@laasco/ui-lib/global.css';
import { LaaSIntlProvider } from '../provider/LaaSIntlProvider';
import { PortalToastContainer } from '@laasco/ui-lib/toast/PortalToastContainer';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { ErrorPageView } from '../views/ErrorPageView';
import { ResizeObserver } from '@juggle/resize-observer';
import { enableStaticRendering } from 'mobx-react-lite';
import { MarkerIOWidgetProvider } from '../provider/MarkerIOWidgetProvider';

const getTopDomain = () => {
  return _.takeRight(window.location.hostname.split('.'), 2).join('.');
};

const usePolyfills = () => {
  React.useEffect(() => {
    if (global['window']) {
      if (!window.ResizeObserver) {
        window.ResizeObserver = ResizeObserver;
      }
    }
  }, []);
};

const useSetLastVisitedLaas = () => {
  React.useEffect(() => {
    if (global['window']) {
      const cookies = new Cookies();
      cookies.set('last-visited-laas', window.location.host, {
        domain: getTopDomain(),
        httpOnly: false,
        path: '/',
        maxAge: 60 * 60 * 24 * 30,
      });
    }
  }, []);
};

const errorFallback = () => <ErrorPageView forceHideNav />;

const onUncaughtException = (e: ErrorEvent) => {
  if (e.message === 'ResizeObserver loop limit exceeded') {
    e.stopPropagation();
    e.preventDefault();
    console.warn('Ignoring ResizeObserver loop limit exceeded');
  }
};

const useIgnoreExceptions = () => {
  React.useEffect(() => {
    window.addEventListener('error', onUncaughtException);
    return () => {
      window.removeEventListener('error', onUncaughtException);
    };
  }, []);
};

function LaaSApp({ Component, pageProps }: AppProps) {
  useSetLastVisitedLaas();
  useIgnoreExceptions();
  usePolyfills();
  enableStaticRendering(global['window'] === undefined);
  return (
    <>
      <LaaSIntlProvider>
        <Sentry.ErrorBoundary fallback={errorFallback} showDialog={true}>
          <MarkerIOWidgetProvider>
            <>
              <Component {...pageProps} />
              <PortalToastContainer />
            </>
          </MarkerIOWidgetProvider>
        </Sentry.ErrorBoundary>
      </LaaSIntlProvider>
    </>
  );
}

export default LaaSApp;
