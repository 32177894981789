import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { brandColors, mediaQuery } from '../constants';
import LoginPageAnimation from '../background/LoginPageAnimation';

const globalStyles = css`
  body {
    background-color: ${brandColors.laasLightBg};
  }
`;

const TopContainer = styled.div`
  position: fixed;
  left: 24px;
  top: 16px;
  @media only screen and ${mediaQuery.small} {
    top: 8px;
    left: 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 100vh;
  min-height: 520px;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  @media screen and ${mediaQuery.medium} {
    margin-top: -2vh;
  }
`;

export interface LoginPageLayoutProps {
  topLeftContent: React.ReactElement;
}

export const LoginPageLayout: React.FunctionComponent<
  React.PropsWithChildren<LoginPageLayoutProps>
> = (props) => {
  return (
    <>
      <Global styles={globalStyles} />
      <LoginPageAnimation />
      <ContentContainer>{props.children}</ContentContainer>
      <TopContainer>{props.topLeftContent}</TopContainer>
    </>
  );
};
