import { iconColors } from '../colors';

export const AIIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill={props.fill ?? iconColors.strong}
  >
    <g>
      <path d="M17.5013 9.16927L16.9805 8.02344L15.8346 7.5026L16.9805 6.98177L17.5013 5.83594L18.0221 6.98177L19.168 7.5026L18.0221 8.02344L17.5013 9.16927ZM15.0013 5.83594L14.2096 4.1276L12.5013 3.33594L14.2096 2.54427L15.0013 0.835938L15.793 2.54427L17.5013 3.33594L15.793 4.1276L15.0013 5.83594ZM6.66797 19.1693C6.20964 19.1693 5.81741 19.0062 5.4913 18.6801C5.16464 18.3534 5.0013 17.9609 5.0013 17.5026H8.33464C8.33464 17.9609 8.17158 18.3534 7.84547 18.6801C7.5188 19.0062 7.1263 19.1693 6.66797 19.1693ZM3.33464 16.6693V15.0026H10.0013V16.6693H3.33464ZM3.54297 14.1693C2.58464 13.5998 1.82436 12.8359 1.26214 11.8776C0.699358 10.9193 0.417969 9.8776 0.417969 8.7526C0.417969 7.01649 1.02575 5.54094 2.2413 4.32594C3.4563 3.11038 4.93186 2.5026 6.66797 2.5026C8.40408 2.5026 9.87964 3.11038 11.0946 4.32594C12.3102 5.54094 12.918 7.01649 12.918 8.7526C12.918 9.8776 12.6369 10.9193 12.0746 11.8776C11.5119 12.8359 10.7513 13.5998 9.79297 14.1693H3.54297ZM4.04297 12.5026H9.29297C9.91797 12.0582 10.4005 11.5095 10.7405 10.8568C11.081 10.204 11.2513 9.5026 11.2513 8.7526C11.2513 7.47483 10.8069 6.39149 9.91797 5.5026C9.02908 4.61371 7.94575 4.16927 6.66797 4.16927C5.39019 4.16927 4.30686 4.61371 3.41797 5.5026C2.52908 6.39149 2.08464 7.47483 2.08464 8.7526C2.08464 9.5026 2.25491 10.204 2.59547 10.8568C2.93547 11.5095 3.41797 12.0582 4.04297 12.5026Z" />
      <path d="M8.74399 5.84702C8.49322 5.84702 8.28133 6.0097 8.28133 6.26672C8.28133 6.55493 8.49322 6.71835 8.74399 6.71835C8.95588 6.71835 9.16777 6.55567 9.16777 6.26672C9.16777 6.0097 8.95588 5.84702 8.74399 5.84702Z" />
      <path d="M9.10122 7.20201H8.34969V9.865H9.10122V7.20201Z" />
      <path d="M5.3499 9.27295C5.34761 9.27295 5.34609 9.27295 5.3438 9.27295H5.22718C4.91239 9.27295 4.86437 9.03822 4.86437 8.95948C4.86437 8.81909 4.90248 8.70321 4.9909 8.62967C5.11209 8.65195 5.24014 8.66384 5.37276 8.66384C6.356 8.66384 7.04351 8.07478 7.04351 7.27329C7.04351 7.02221 6.98787 6.78748 6.87735 6.58321L7.29351 6.43242V5.86342L6.4368 6.11078C6.16926 5.9377 5.81408 5.83594 5.37353 5.83594C4.29196 5.83594 3.70278 6.52081 3.70278 7.27403C3.70278 7.74126 3.96803 8.16392 4.40324 8.41722C4.25385 8.55836 4.1723 8.7448 4.1723 8.95948C4.1723 9.14518 4.23022 9.29003 4.32245 9.40294C3.76299 9.56487 3.33464 9.89171 3.33464 10.4711C3.33464 11.5734 4.75995 11.6693 5.3499 11.6693C5.84152 11.6693 7.41394 11.5734 7.41394 10.4711C7.41394 9.32123 5.82475 9.27295 5.3499 9.27295ZM5.37734 6.5067C5.89258 6.5067 6.26759 6.86251 6.26759 7.30671C6.26759 7.70709 5.89258 8.01833 5.37734 8.01833C4.86209 8.01833 4.48708 7.70709 4.48708 7.30671C4.48632 6.86251 4.86133 6.5067 5.37734 6.5067ZM5.35676 11.0096C4.58465 11.0096 4.13114 10.8024 4.13114 10.4696C4.13114 10.3032 4.22184 9.92959 5.35676 9.92959C6.44518 9.92959 6.62811 10.3032 6.62811 10.4696C6.62811 10.6769 6.39488 11.0096 5.35676 11.0096Z" />
      <path d="M15.418 11.6693L15.0273 10.8099L14.168 10.4193L15.0273 10.0286L15.418 9.16927L15.8086 10.0286L16.668 10.4193L15.8086 10.8099L15.418 11.6693Z" />
    </g>
  </svg>
);
