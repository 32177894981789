import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { brandColors } from '../constants';
import React from 'react';

const BounceContainer = styled.div`
  position: relative;
`;

const bounceAnimation = keyframes`
  0%, 100% {
    transform: scale(0);
  }
  45%, 55% {
    transform: scale(1);
  }
`;

const BounceDot = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${brandColors.laasPurple};
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${bounceAnimation} 2s infinite
    cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:nth-of-type(2) {
    animation-delay: -1s;
  }

  @media (prefers-reduced-motion) {
    animation: ${bounceAnimation} 20s infinite
      cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
`;

export interface BouncingLoaderProps {
  radius: number;
  className?: string;
}

export const BouncingLoader: React.FunctionComponent<
  React.PropsWithChildren<BouncingLoaderProps>
> = (props, className) => {
  return (
    <BounceContainer
      className={className}
      style={{ width: props.radius, height: props.radius }}
    >
      <BounceDot />
      <BounceDot />
    </BounceContainer>
  );
};
