export const FeedbackIcon = () => (
  <svg
    width="323"
    height="232"
    viewBox="0 0 323 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M207.676 188.797C256.65 188.797 296.351 149.219 296.351 100.398C296.351 51.5773 256.65 12 207.676 12C158.701 12 119 51.5773 119 100.398C119 149.219 158.701 188.797 207.676 188.797Z"
      fill="#EDD4CF"
    />
    <path
      d="M174 51C174 24.4904 195.49 3 222 3H320V59.1797C320 85.6894 298.51 107.18 272 107.18H225.237L203.131 122V107.18H174V51Z"
      stroke="#4D2A6F"
      strokeWidth="5"
    />
    <path
      d="M115 31C115 15.536 102.464 3 87 3H3V64.6629C3 80.1269 15.536 92.6629 31 92.6629H33.5L50.6432 108L46.2488 92.6629H115V31Z"
      stroke="#907BA6"
      strokeWidth="5"
    />
    <circle cx="179.5" cy="149.5" r="18.5" fill="#B8AAC5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161 225V212.5C161 203.08 157.617 194.451 152 187.76C157.452 179.472 166.837 174 177.5 174C194.344 174 208 187.655 208 204.5V225H161Z"
      fill="#B8AAC5"
    />
    <circle cx="66.5" cy="149.5" r="18.5" fill="#B8AAC5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84 225V212.5C84 202.772 87.6081 193.886 93.5591 187.109C88.0504 179.186 78.8808 174 68.5 174C51.6553 174 38 187.655 38 204.5V225H84Z"
      fill="#B8AAC5"
    />
    <circle
      cx="122"
      cy="136"
      r="27"
      fill="#E9C9C3"
      stroke="#4D2A6F"
      strokeWidth="6"
    />
    <path
      d="M158 229V210C158 190.118 141.882 174 122 174V174C102.118 174 86 190.118 86 210V229"
      stroke="#4D2A6F"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <line
      x1="209.5"
      y1="39.5"
      x2="285.5"
      y2="39.5"
      stroke="#4D2A6F"
      strokeWidth="7"
      strokeLinecap="round"
    />
    <line
      x1="209.5"
      y1="55.5"
      x2="285.5"
      y2="55.5"
      stroke="#4D2A6F"
      strokeWidth="7"
      strokeLinecap="round"
    />
    <line
      x1="209.5"
      y1="71.5"
      x2="261.5"
      y2="71.5"
      stroke="#4D2A6F"
      strokeWidth="7"
      strokeLinecap="round"
    />
    <line
      x1="30.5"
      y1="39.5"
      x2="68.5"
      y2="39.5"
      stroke="#907BA6"
      strokeWidth="7"
      strokeLinecap="round"
    />
    <line
      x1="30.5"
      y1="55.5"
      x2="82.5"
      y2="55.5"
      stroke="#907BA6"
      strokeWidth="7"
      strokeLinecap="round"
    />
  </svg>
);
