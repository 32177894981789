export const Translate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="#4D2A6F"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4 22L16.95 10H19.05L23.6 22H21.5L20.45 18.95H15.6L14.5 22H12.4ZM16.2 17.2H19.8L18.05 12.25H17.95L16.2 17.2ZM4.5 19L3.1 17.6L8.15 12.55C7.51667 11.85 6.9625 11.125 6.4875 10.375C6.0125 9.625 5.6 8.83333 5.25 8H7.35C7.65 8.6 7.97083 9.14167 8.3125 9.625C8.65417 10.1083 9.06667 10.6167 9.55 11.15C10.2833 10.35 10.8917 9.52917 11.375 8.6875C11.8583 7.84583 12.2667 6.95 12.6 6H1.5V4H8.5V2H10.5V4H17.5V6H14.6C14.25 7.18333 13.775 8.33333 13.175 9.45C12.575 10.5667 11.8333 11.6167 10.95 12.6L13.35 15.05L12.6 17.1L9.5 14L4.5 19Z"
      fill="current-color"
    />
  </svg>
);
