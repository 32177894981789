import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { heading4Styles } from '@laasco/ui-lib/typography';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Spacer } from '../Spacer';
import { SupplementaryButton } from '../buttons';
import { brandColors, mediaQuery } from '../constants';
import { CloseIcon } from '../icons';
import { BaseModalDialog, BaseModalDialogProps } from './BaseModalDialog';
import { AnimationPlaceholder } from './assets/AnimationPlaceholder';

type ModalSize = 'medium' | 'large' | 'full-screen';

const sizeToWidth: { [k in ModalSize]: string } = {
  medium: `552px`,
  large: `960px`,
  'full-screen': '100vw',
};

const footerHeight = '72px';
const headerHeight = '72px';

const fullScreenStyles = css`
  max-height: 100vh;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
`;

const DialogContainer = styled.div<{
  size: ModalSize;
  hasFooter: boolean;
  takeFullAvailableWidth?: boolean;
  takeFullAvailableHeight?: boolean;
}>`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  /* Varjo */

  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  border-radius: 4px;
  margin: 9vh 0 16vh 0;

  max-width: ${(props) => sizeToWidth[props.size]};
  width: ${(props) =>
    props.takeFullAvailableWidth ? sizeToWidth[props.size] : 'auto'};
  height: ${(props) => (props.takeFullAvailableHeight ? '100vh' : 'auto')};
  max-height: calc(100vh - 9vh - 16vh);

  display: grid;
  grid-template-rows: auto 1fr ${(p) => (p.hasFooter ? footerHeight : '24px')};
  position: relative;
  -webkit-overflow-scrolling: touch;

  @media screen and ${mediaQuery.medium} {
    ${fullScreenStyles};
  }

  ${(props) => (props.size === 'full-screen' ? fullScreenStyles : '')}
`;

const StyledAnimationPlaceholder = styled(AnimationPlaceholder)`
  position: absolute;
  left: -2px;
  top: 0;
  pointer-events: none;
`;

const HeaderContainer = styled.div`
  background: ${brandColors.laasPurple5};
  /* Neutrals/Grey 8 */

  border-bottom: 1px solid ${brandColors.coalGrey8};
  border-radius: 4px 4px 0px 0px;
  padding: 0 24px;
  min-height: ${headerHeight};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${heading4Styles}

  @media screen and ${mediaQuery.small} {
    padding: 0 16px;
  }
`;

const StickyContentContainer = styled.div`
  grid-row: 1;
`;

const contentPadding = css`
  padding: 24px;
  @media screen and ${mediaQuery.small} {
    padding: 24px 16px;
  }
`;

const DialogScrollingContentContainer = styled.div<{
  noContentPadding?: boolean;
}>`
  grid-row: 2;
  background: #ffffff;
  /* Neutrals/Grey 8 */
  overflow-x: hidden;
  overflow-y: auto;
  ${(p) => (p.noContentPadding ? '' : contentPadding)};
`;

const FooterContainer = styled.div`
  grid-row: 3;
  display: flex;
  border-top: 1px solid ${brandColors.coalGrey8};
  border-radius: 0px 0px 4px 4px;
  padding: 0px 24px;
  gap: 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const BasicModalDialogLayout = React.forwardRef<
  HTMLDivElement,
  Pick<
    BasicModalDialogProps,
    | 'children'
    | 'size'
    | 'className'
    | 'footerContent'
    | 'headerContent'
    | 'noContentPadding'
    | 'stickyContent'
    | 'takeFullAvailableWidth'
    | 'takeFullAvailableHeight'
  >
>((props, ref) => (
  <DialogContainer
    hasFooter={!!props.footerContent}
    size={props.size!}
    onClick={(e) => e.stopPropagation()}
    takeFullAvailableWidth={props.takeFullAvailableWidth}
    takeFullAvailableHeight={props.takeFullAvailableHeight}
  >
    <StyledAnimationPlaceholder />
    <StickyContentContainer>
      <HeaderContainer>{props.headerContent}</HeaderContainer>
      {props.stickyContent}
    </StickyContentContainer>
    <DialogScrollingContentContainer
      noContentPadding={props.noContentPadding}
      ref={ref}
      className={props.className}
    >
      {props.children}
    </DialogScrollingContentContainer>
    {props.footerContent && (
      <FooterContainer>{props.footerContent}</FooterContainer>
    )}
  </DialogContainer>
));
BasicModalDialogLayout.displayName = 'BasicModalDialogLayout';

const CloseButtonLabelText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* identical to box height */
  letter-spacing: 0.05em;
`;

const CloseButton = styled(SupplementaryButton)`
  align-self: flex-start;
  margin-left: auto;
  margin-right: -8px;
  margin-top: 16px;
`;

export const CloseModalButton = (props: {
  onClick: React.EventHandler<any>;
  label: React.ReactNode;
}) => {
  const isSmall = useMediaQuery({ query: mediaQuery.small });
  return (
    <CloseButton onClick={props.onClick} icon={<CloseIcon />}>
      {isSmall ? (
        <CloseButtonLabelText>{props.label}</CloseButtonLabelText>
      ) : null}
    </CloseButton>
  );
};

export interface BasicModalDialogProps extends BaseModalDialogProps {
  onCloseClick: React.EventHandler<any>;
  closeButtonLabel: React.ReactNode;
  size?: ModalSize;
  className?: string;
  dataTest?: string;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  stickyContent?: React.ReactNode;
  noContentPadding?: boolean;
  takeFullAvailableWidth?: boolean;
  takeFullAvailableHeight?: boolean;
}

export const BasicModalDialog: React.FunctionComponent<
  BasicModalDialogProps
> = (props) => {
  const {
    children,
    isOpen,
    onCloseClick,
    onBackdropClick,
    closeButtonLabel,
    size = 'medium',
    className,
    dataTest,
    headerContent,
    footerContent,
    noContentPadding,
    stickyContent,
    takeFullAvailableWidth,
    takeFullAvailableHeight,
  } = props;
  return (
    <BaseModalDialog
      isOpen={isOpen}
      onBackdropClick={onBackdropClick}
      dataTest={dataTest}
    >
      <BasicModalDialogLayout
        size={size}
        stickyContent={stickyContent}
        className={className}
        noContentPadding={noContentPadding}
        takeFullAvailableWidth={takeFullAvailableWidth}
        takeFullAvailableHeight={takeFullAvailableHeight}
        headerContent={
          <>
            {headerContent}
            <Spacer width={16} />
            <CloseModalButton onClick={onCloseClick} label={closeButtonLabel} />
          </>
        }
        footerContent={footerContent}
      >
        {children}
      </BasicModalDialogLayout>
    </BaseModalDialog>
  );
};
