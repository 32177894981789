import styled from '@emotion/styled';
import {
  HeadingM,
  HeadingXS,
  PrimaryButton,
  Select,
  Spacer,
  Switch,
  TextField,
} from '@laasco/ui-lib';
import { mediaQuery } from '@laasco/ui-lib/constants';
import { TextArea } from '@laasco/ui-lib/inputs/TextArea';
import { LegacyModalDialog } from '@laasco/ui-lib/modal/LegacyModalDialog';
import {
  UseProgressToastProps,
  useProgressToast,
} from '@laasco/ui-lib/toast/useProgressToast';
import * as Sentry from '@sentry/nextjs';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FeedbackInput,
  FeedbackType,
  useGiveFeedbackMutation,
} from '../../generated/graphqlApiTypes';
import {
  CloseMessage,
  LoadingMessage,
  SelectMessage,
  SendMessage,
} from '../../i18n/commonMessages';

const toastOptions: UseProgressToastProps = {
  failureOptions: {
    title: (
      <FormattedMessage
        defaultMessage="Error sending feedback"
        description="Feeback modal error toast title"
      />
    ),
    type: 'error',
  },
  loadingOptions: {
    title: LoadingMessage(),
    type: 'info',
  },
  successOptions: {
    title: (
      <FormattedMessage
        defaultMessage="Your feedback was send succesfully"
        description="Feedback modal success toast title"
      />
    ),
    type: 'success',
  },
};

const StyledModal = styled(LegacyModalDialog)`
  width: 100%;
  padding: 72px 48px 65px 48px;
  @media screen and ${mediaQuery.medium} {
    padding: 72px 32px;
  }
  @media screen and ${mediaQuery.small} {
    padding: 72px 24px;
  }
`;
const Title = styled(HeadingM)`
  margin-bottom: 40px;
`;

const InputLabel = styled(HeadingXS)`
  margin-bottom: 16px;
`;

const StyledSelect = styled(Select)`
  width: 50%;
  @media screen and ${mediaQuery.medium} {
    width: 100%;
  }
`;

const FeedbackTextArea = styled(TextArea)`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 112px;
`;

const UpperSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media screen and ${mediaQuery.medium} {
    flex-direction: column;
    gap: 32px;
  }
`;

const SubjectField = styled(TextField)`
  width: 100%;
`;

const useSelectItems = () => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage({
        defaultMessage: 'General feedback',
        description: 'feedback modal select type general feedback',
      }),
      value: FeedbackType.General,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Suggest a new service',
        description: 'feedback modal select type suggest new service',
      }),
      value: FeedbackType.ServiceSuggestion,
    },
  ];
};

export interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialType?: FeedbackType;
}

const defaultState = {
  type: null,
  description: '',
  subject: '',
  isConfidential: false,
};

const FeedBackModalContent: React.FC<
  React.PropsWithChildren<FeedbackModalProps>
> = (props) => {
  const [selectedType, setSelectedType] = useState<FeedbackType | null>(
    props.initialType || defaultState.type,
  );
  const feedbackTypes = useSelectItems();
  const [isConfidential, setIsConfidential] = useState(
    defaultState.isConfidential,
  );
  const [subject, setSubject] = useState(defaultState.subject);
  const [feedback, setFeedback] = useState(defaultState.description);
  const intl = useIntl();
  const [submitFeedback] = useGiveFeedbackMutation();
  const { setToastState } = useProgressToast(toastOptions);

  const resetState = useCallback(() => {
    setFeedback(defaultState.description);
    setSubject(defaultState.subject);
    setIsConfidential(defaultState.isConfidential);
    setSelectedType(defaultState.type);
  }, []);

  const handleSubmit = async () => {
    if (selectedType === null || subject === '' || feedback === '') return;
    setToastState('loading');
    const data: FeedbackInput = {
      description: feedback,
      subject: subject,
      isConfidential: isConfidential,
      type: selectedType,
    };
    const result = await submitFeedback({ variables: { feedback: data } });
    if (result.errors) {
      Sentry.captureException(result.errors);
      setToastState('error');
      return;
    }
    if (result.data?.giveFeedback) {
      setToastState('success');
      resetState();
    }
    props.onClose();
  };

  return (
    <>
      <Title>
        <FormattedMessage
          defaultMessage="Send feedback"
          description="Feedback modal title"
        />
      </Title>
      <InputLabel>
        <>
          <FormattedMessage
            defaultMessage="Feedback type"
            description="Feedback modal type label"
          />
          *
        </>
      </InputLabel>
      <UpperSection>
        <StyledSelect
          placeholder={<SelectMessage />}
          options={feedbackTypes}
          value={feedbackTypes.find((o) => o.value === selectedType) || null}
          onChange={({ value }: any) => setSelectedType(value)}
        />
        <Switch
          checked={isConfidential}
          onChange={() => setIsConfidential(!isConfidential)}
        >
          <FormattedMessage
            defaultMessage="Send as anonymous"
            description="Feedback modal switch confidential label"
          />
        </Switch>
      </UpperSection>
      <Spacer height={40} />
      <InputLabel>
        <>
          <FormattedMessage
            defaultMessage="Subject"
            description="Feedback modal subject label"
          />
          *
        </>
      </InputLabel>
      <SubjectField
        value={subject}
        onChange={({ target }) => setSubject(target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'What’s your feedback about',
          description: 'feedback modal subject input placeholder',
        })}
      />
      <Spacer height={40} />
      <InputLabel>
        <>
          <FormattedMessage
            defaultMessage="Feedback"
            description="Feedback modal feedback text input label"
          />
          *
        </>
      </InputLabel>
      <FeedbackTextArea
        value={feedback}
        onChange={({ target }) => setFeedback(target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Share your thoughts',
          description: 'feedback modal feedback input placeholder',
        })}
      />
      <Spacer height={40} />
      <PrimaryButton
        disabled={selectedType === null || subject === '' || feedback === ''}
        onClick={() => handleSubmit()}
      >
        {<SendMessage />}
      </PrimaryButton>
    </>
  );
};

export const FeedbackModal: React.FC<
  React.PropsWithChildren<FeedbackModalProps>
> = (props) => (
  <StyledModal
    isOpen={props.isOpen}
    size="large"
    closeButtonLabel={<CloseMessage />}
    onCloseClick={props.onClose}
    onBackdropClick={props.onClose}
  >
    <FeedBackModalContent {...props} />
  </StyledModal>
);
