import { SVGProps } from 'react';

export const ArrowForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 4L10.59 5.41L16.17 11L4 11L4 13L16.17 13L10.59 18.59L12 20L20 12L12 4Z"
      fill="currentColor"
    />
  </svg>
);
