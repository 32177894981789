import React from 'react';
import Image, { StaticImageData } from 'next/image';
import { bodyMStyles, HeadingS } from '../typography';
import Truncate from '../Truncate';
import useMeasure from 'react-use-measure';
import { useMediaQuery } from 'react-responsive';
import { animationDuration, mediaQuery } from '../constants';
import styled from '@emotion/styled';

export const Container = styled.article`
  background: #ffffff;

  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  border-radius: 40px 4px 4px 4px;
  width: 100%;
  height: 100%;

  @media (hover: hover) {
    transition: all ${animationDuration.medium} linear;
    :hover {
      box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.1);
    }
  }
`;

export const ImageContainer = styled.div<{ horizontal: boolean }>`
  position: relative;
  border-radius: 40px 0px 40px 0px;
  overflow: hidden;
  width: 100%;
  height: ${(p) => (p.horizontal ? '214px' : '290px')};
`;

export const TextContainer = styled.div`
  padding: 32px 24px;
  > * {
    margin-top: 8px;
  }
  > :nth-child(1) {
    margin-top: 0px;
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center;
`;

export interface NewsCardProps {
  title: React.ReactNode;
  contentHtml: string;
  imageSrc: string | StaticImageData;
  wide?: boolean;
}

const WideWrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  max-height: 214px;
`;

const BodyContainer = styled.div`
  ${bodyMStyles}
`;

export const NewsCard = ({
  title,
  contentHtml,
  imageSrc,
  wide = false,
}: NewsCardProps) => {
  const [contentContainerRef, contentContainerBounds] = useMeasure();
  const isMedium = useMediaQuery({ query: mediaQuery.medium });
  const isHorizontal = !isMedium && wide;

  const cardContent = (
    <>
      <ImageContainer horizontal={isHorizontal}>
        <StyledImage src={imageSrc} fill alt={'News image'} />
      </ImageContainer>
      <TextContainer ref={contentContainerRef}>
        <HeadingS>{title}</HeadingS>
        <BodyContainer>
          <Truncate
            lines={4}
            width={contentContainerBounds.width - 24 * 2}
            trimWhitespace
          >
            <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
          </Truncate>
        </BodyContainer>
      </TextContainer>
    </>
  );

  return (
    <Container>
      {isHorizontal ? <WideWrapper>{cardContent}</WideWrapper> : cardContent}
    </Container>
  );
};
