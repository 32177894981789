import { useState } from 'react';
import { components } from 'react-select';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { brandColors } from '../../constants';

const Wrapper = styled.div<{
  isFocused: boolean;
  isMouseOn: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  align-items: center;
  min-height: 55px;
  padding: ${(props) => (props.isFocused && !props.isMouseOn ? 14 : 16)}px;
  border: ${(props) =>
    props.isFocused && !props.isMouseOn
      ? css`2px solid ${brandColors.laasPurple60}`
      : 0};
  box-sizing: border-box;
  border-radius: 2px;
  background: ${(props) => {
    if (props.isMouseOn) {
      return brandColors.laasPurple20;
    }
    if (props.isSelected) {
      return brandColors.laasPurple10;
    }
    return 'inherit';
  }};
`;

export const Option = ({ onClick, ...props }: any) => {
  const [isMouseOn, setIsMouseOn] = useState(false);

  return (
    <components.Option {...props}>
      <Wrapper
        {...(props.indicateSelection && {
          isSelected: props.isSelected,
        })}
        isFocused={props.isFocused}
        isMouseOn={isMouseOn}
        onMouseEnter={() => setIsMouseOn(true)}
        onMouseLeave={() => setIsMouseOn(false)}
        onClick={onClick}
        data-test="select-option"
      >
        {props.children}
      </Wrapper>
    </components.Option>
  );
};
