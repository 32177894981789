import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { brandColors, contentMaxWidthPx, mediaQuery } from '../constants';
import React from 'react';

const createGlobalStyles = (background?: string) => css`
  body {
    background: ${background || brandColors.laasLightBg};
  }
`;

const desktopPadding = 24;
const mobilePadding = 12;

export const DefaultContentContainer = styled.div<{
  desktopPadding?: number;
  mediumPadding?: number;
}>`
  position: relative;
  padding: 0px
    ${(p) => (p.desktopPadding == null ? desktopPadding : p.desktopPadding)}px;
  max-width: calc(
    ${contentMaxWidthPx} +
      ${(p) =>
        p.desktopPadding == null ? desktopPadding : p.desktopPadding}px*2
  );
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @media screen and ${mediaQuery.medium} {
    padding: 0px
      ${(p) => (p.mediumPadding == null ? desktopPadding : p.mediumPadding)}px;
    max-width: calc(
      ${contentMaxWidthPx} +
        ${(p) =>
          p.mediumPadding == null ? desktopPadding : p.mediumPadding}px*2
    );
  }

  @media screen and ${mediaQuery.small} {
    padding: 0px ${mobilePadding}px;
    max-width: calc(${contentMaxWidthPx} + ${mobilePadding}px*2);
  }
`;

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PageChildrenContainer = styled.main`
  flex: 1 1 0%;
`;

export interface DefaultPageLayoutProps {
  navbar: React.ReactNode;
  background?: string;
  footer?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

export const DefaultPageLayout = React.forwardRef<
  HTMLDivElement,
  DefaultPageLayoutProps
>((props, ref) => {
  return (
    <PageContainer className={props.className} ref={ref}>
      <Global styles={createGlobalStyles(props.background)} />
      {props.navbar}
      <PageChildrenContainer>{props.children}</PageChildrenContainer>
      {props.footer}
    </PageContainer>
  );
});
DefaultPageLayout.displayName = 'DefaultPageLayout';
