import styled from '@emotion/styled';
import { AccordionDrawer } from '@laasco/ui-lib/accordion/AccordionDrawer';
import {
  animationDurationNumbers,
  brandColors,
} from '@laasco/ui-lib/constants';
import { ExpandChevron } from '@laasco/ui-lib/ExpandChevron';
import { NotificationBadgeWrapper } from '@laasco/ui-lib/indicator/NotificationBadgeWrapper';
import { BodyL } from '@laasco/ui-lib/typography';
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TabProps } from './Tab';
import { TabsProps } from './Tabs';

const TabsContainer = styled.div`
  border: 1px solid ${brandColors.laasPurple15};
  border-radius: 4px;
`;

const TabContainer = styled.div<{ isLast: boolean }>`
  border-bottom: ${(p) =>
    !p.isLast ? '1px solid ' + brandColors.laasPurple15 : 'none'};
  position: relative;
  overflow: hidden;
`;

const TabHeader = styled.button`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  justify-content: space-between;
  cursor: pointer;
  border: none;
  padding: 16px;
  width: 100%;
  background: none;
`;

const TabHeaderLeftSide = styled.span`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(BodyL)``;

const TabContentContainer = styled.div`
  border-top: 1px solid ${brandColors.laasPurple15};
  padding: 16px;
`;

export type MobileTabProps = Omit<TabProps, 'title'> & {
  title: ReactNode;
  isLast?: boolean;
  children: ReactNode;
};

export const MobileTab: React.FC<MobileTabProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setIsExpanded(true);
  }, [props.isActive]);

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (props.onClick) props.onClick(e);

    if (props.isActive) {
      setIsExpanded(!isExpanded);
    }

    setTimeout(() => {
      if (
        ref.current &&
        ref.current.getBoundingClientRect().y < window.scrollY
      ) {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, animationDurationNumbers.long + 100);
  };

  return (
    <TabContainer isLast={!!props.isLast}>
      <TabHeader
        onClick={clickHandler}
        ref={ref}
        role="tab"
        aria-selected={!!props.isActive}
      >
        <NotificationBadgeWrapper
          showBadge={!!props.showBadge}
          right={props.childCountBubble ? -4 : -12}
          top={props.childCountBubble ? -2 : -4}
          style={{ width: props.childCountBubble ? '100%' : undefined }}
        >
          <TabHeaderLeftSide>
            <Title>{props.title}</Title>
            {props.childCountBubble}
          </TabHeaderLeftSide>
        </NotificationBadgeWrapper>
        <ExpandChevron isOpen={!!props.isActive && isExpanded} />
      </TabHeader>
      <AccordionDrawer isOpen={!!props.isActive && isExpanded} role="region">
        <TabContentContainer>{props.children}</TabContentContainer>
      </AccordionDrawer>
    </TabContainer>
  );
};

export type MobileTabsProps = TabsProps & {
  tabs: [];
};

export const MobileTabs: React.FC<PropsWithChildren<TabsProps>> = ({
  children,
  activeTab,
}) => {
  const childCount = React.Children.count(children);

  return (
    <TabsContainer>
      {React.Children.map(children, (child, i) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...child.props,
              isActive: i === activeTab,
              isLast: i === childCount - 1,
            })
          : child,
      )}
    </TabsContainer>
  );
};
