import { SVGProps } from 'react';

export const PersonStrengths = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.667 18.6668V14.3335H20.3337V12.3335H24.667V8.00013H26.667V12.3335H31.0003V14.3335H26.667V18.6668H24.667ZM12.3337 15.9668C10.867 15.9668 9.66699 15.5001 8.73366 14.5668C7.80033 13.6335 7.33366 12.4335 7.33366 10.9668C7.33366 9.50013 7.80033 8.30013 8.73366 7.3668C9.66699 6.43346 10.867 5.9668 12.3337 5.9668C13.8003 5.9668 15.0003 6.43346 15.9337 7.3668C16.867 8.30013 17.3337 9.50013 17.3337 10.9668C17.3337 12.4335 16.867 13.6335 15.9337 14.5668C15.0003 15.5001 13.8003 15.9668 12.3337 15.9668ZM1.66699 26.6668V23.5335C1.66699 22.7557 1.86699 22.0501 2.26699 21.4168C2.66699 20.7835 3.22255 20.3112 3.93366 20.0001C5.60033 19.2668 7.08366 18.7501 8.38366 18.4501C9.68366 18.1501 11.0003 18.0001 12.3337 18.0001C13.667 18.0001 14.9837 18.1501 16.2837 18.4501C17.5837 18.7501 19.0559 19.2668 20.7003 20.0001C21.4114 20.3335 21.9725 20.8112 22.3837 21.4335C22.7948 22.0557 23.0003 22.7557 23.0003 23.5335V26.6668H1.66699ZM3.66699 24.6668H21.0003V23.5335C21.0003 23.1779 20.9114 22.839 20.7337 22.5168C20.5559 22.1946 20.2781 21.9557 19.9003 21.8001C18.3448 21.0446 17.017 20.5557 15.917 20.3335C14.817 20.1112 13.6225 20.0001 12.3337 20.0001C11.0448 20.0001 9.85032 20.1168 8.75033 20.3501C7.65033 20.5835 6.31144 21.0668 4.73366 21.8001C4.40033 21.9557 4.13921 22.1946 3.95033 22.5168C3.76144 22.839 3.66699 23.1779 3.66699 23.5335V24.6668ZM12.3337 13.9668C13.2003 13.9668 13.917 13.6835 14.4837 13.1168C15.0503 12.5501 15.3337 11.8335 15.3337 10.9668C15.3337 10.1001 15.0503 9.38346 14.4837 8.8168C13.917 8.25013 13.2003 7.9668 12.3337 7.9668C11.467 7.9668 10.7503 8.25013 10.1837 8.8168C9.61699 9.38346 9.33366 10.1001 9.33366 10.9668C9.33366 11.8335 9.61699 12.5501 10.1837 13.1168C10.7503 13.6835 11.467 13.9668 12.3337 13.9668Z"
      fill="currentColor"
    />
  </svg>
);
