import styled from '@emotion/styled';
import { getTransitionSizes } from '@formkit/auto-animate';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import React from 'react';
import { animationDurationNumbers } from '../constants';

const useAccordionAnimate = (options: KeyframeEffectOptions) =>
  useAutoAnimate<HTMLDivElement>((el, action, oldCoords, newCoords) => {
    const keyframes = () => {
      if (action === 'remain') {
        const deltaX = oldCoords!.left - newCoords!.left;
        const deltaY = oldCoords!.top - newCoords!.top;
        const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(
          el,
          oldCoords!,
          newCoords!,
        );
        const start: Record<string, any> = {
          transform: `translate(${deltaX}px, ${deltaY}px)`,
        };
        const end: Record<string, any> = {
          transform: `translate(0, 0)`,
        };
        if (widthFrom !== widthTo) {
          start.width = `${widthFrom}px`;
          end.width = `${widthTo}px`;
        }
        if (heightFrom !== heightTo) {
          start.height = `${heightFrom}px`;
          end.height = `${heightTo}px`;
        }
        return [start, end];
      } else if (action === 'add') {
        return [{ opacity: 0 }, { opacity: 0, offset: 0.5 }, { opacity: 1 }];
      } else if (action === 'remove') {
        return [{ opacity: 1 }, { opacity: 0 }];
      }
      return [];
    };

    return new KeyframeEffect(el, keyframes(), options);
  });

const DefaultContentContainer = styled.div``;

const effectOptions = {
  duration: animationDurationNumbers.long,
  easing: 'ease-in-out',
};

export interface AccordionDrawerProps {
  isOpen: boolean;
  'aria-labelledby'?: string;
  role?: React.AriaRole;
  className?: string;
  children?: React.ReactNode;
  Container?: typeof DefaultContentContainer;
}

export const AccordionDrawer = ({
  isOpen,
  children,
  Container = DefaultContentContainer,
  ...containerProps
}: AccordionDrawerProps) => {
  const [collapseRef] = useAccordionAnimate(effectOptions);
  const [style, setStyle] = React.useState<React.CSSProperties | undefined>();
  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setStyle({ overflow: 'hidden' });
    const handle = setTimeout(
      () => setStyle(undefined),
      effectOptions.duration,
    );
    return () => clearTimeout(handle);
  }, [isOpen]);

  return (
    <div style={style} {...containerProps}>
      <div ref={collapseRef}>
        {isOpen ? <Container>{children}</Container> : null}
      </div>
    </div>
  );
};
