import React, { PropsWithChildren } from 'react';
import { components } from 'react-select';
import { Spacer } from '../../Spacer';
import { CheckedCount } from './CheckedCount';

interface SelectProps {
  numberOfCheckedOptions: number;
}

const TruncatedValues = ({
  children,
  numberOfCheckedOptions,
}: PropsWithChildren<SelectProps>) => {
  const allChildren = React.Children.toArray(children);
  const inputElement = allChildren[allChildren.length - 1];
  const otherElements = allChildren.slice(0, allChildren.length - 1);

  // 0 values selected: Placeholder and Input are shown
  // N values selected: ...Value and Input are shown
  return (
    <>
      {numberOfCheckedOptions > 1 ? (
        <>
          {otherElements[0]}
          <Spacer width={10} />
          <CheckedCount>+{numberOfCheckedOptions - 1}</CheckedCount>
        </>
      ) : (
        otherElements
      )}
      {inputElement}
    </>
  );
};

export const MultiValueContainer = ({ children, ...props }: any) => (
  <components.ValueContainer {...props}>
    <TruncatedValues {...props.selectProps}>{children}</TruncatedValues>
  </components.ValueContainer>
);
