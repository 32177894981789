export const InfoIcon34 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="#4D2A6F"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.334 8.66634H18.6673V11.9997H15.334V8.66634ZM15.334 15.333H18.6673V25.333H15.334V15.333ZM17.0007 0.333008C7.80065 0.333008 0.333984 7.79967 0.333984 16.9997C0.333984 26.1997 7.80065 33.6663 17.0007 33.6663C26.2007 33.6663 33.6673 26.1997 33.6673 16.9997C33.6673 7.79967 26.2007 0.333008 17.0007 0.333008ZM17.0007 30.333C9.65065 30.333 3.66732 24.3497 3.66732 16.9997C3.66732 9.64968 9.65065 3.66634 17.0007 3.66634C24.3507 3.66634 30.334 9.64968 30.334 16.9997C30.334 24.3497 24.3507 30.333 17.0007 30.333Z" />
  </svg>
);
