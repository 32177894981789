import styled from '@emotion/styled';
import React from 'react';
import { brandColors } from '../constants';
import { ReviewHeartIcon, ReviewStarIcon } from '../icons';

const ReviewScore = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.22px;
`;

const ReviewContainer = styled.div<{ hasReviews: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  height: 24px;
  color: ${(p) => (p.hasReviews ? 'currentColor' : brandColors.coalGrey8)};
`;

const roundReview = (t: number) => t.toFixed(1);

interface ServiceCardReviewsProps {
  hideHeart?: boolean;
  heartScore?: number;
  starScore?: number;
  className?: string;
}

export const ServiceCardReviews: React.FC<
  React.PropsWithChildren<ServiceCardReviewsProps>
> = (props) => {
  const hasStarScore = props.starScore !== undefined;
  const hasHeartScore = props.heartScore !== undefined;
  const hasReviews = hasStarScore || hasHeartScore;
  return (
    <ReviewContainer hasReviews={hasReviews} className={props.className}>
      <ReviewStarIcon />
      <ReviewScore>
        {hasStarScore ? roundReview(props.starScore!) : '-'}
      </ReviewScore>
      {!props.hideHeart && (
        <>
          <ReviewHeartIcon data-test="review-heart-icon" />
          <ReviewScore>
            {hasHeartScore ? roundReview(props.heartScore!) : '-'}
          </ReviewScore>
        </>
      )}
    </ReviewContainer>
  );
};
