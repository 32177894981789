import { SVGProps } from 'react';

export const Analytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M25.0002 4.58203H6.65536C5.21398 4.58203 4.03467 5.75412 4.03467 7.18668V25.4192C4.03467 26.8518 5.21398 28.0239 6.65536 28.0239H25.0002C26.4416 28.0239 27.6209 26.8518 27.6209 25.4192V7.18668C27.6209 5.75412 26.4416 4.58203 25.0002 4.58203ZM25.0002 25.4192H6.65536V7.18668H25.0002V25.4192Z"
        fill="#907BA6"
      />
      <path
        d="M11.8966 16.3027H9.27588V22.8144H11.8966V16.3027Z"
        fill="#907BA6"
      />
      <path
        d="M22.3795 9.79102H19.7588V22.8143H22.3795V9.79102Z"
        fill="#907BA6"
      />
      <path
        d="M17.1383 18.9072H14.5176V22.8142H17.1383V18.9072Z"
        fill="#907BA6"
      />
      <path
        d="M17.1383 13.6982H14.5176V16.3029H17.1383V13.6982Z"
        fill="#907BA6"
      />
    </g>
    <defs>
      <clipPath id="clip0_4465_11211">
        <rect
          width="31.4483"
          height="31.2558"
          fill="white"
          transform="translate(0.103516 0.674805)"
        />
      </clipPath>
    </defs>
  </svg>
);
