import styled from '@emotion/styled';
import { SentimentDissatisfied48 } from '../icons';
import { BodyL, bodyMStyles, HeadingL, headingSStyles } from '../typography';
import { brandColors, mediaQuery } from '../constants';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
  max-width: 700px;
`;

const DissatisfiedContainer = styled.div`
  background: rgba(77, 42, 111, 0.15);
  border-radius: 24px 0px;
  color: white;
  width: 86px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled(HeadingL)`
  color: ${brandColors.laasPurple};
  @media screen and ${mediaQuery.small} {
    ${headingSStyles}
  }
`;

const DescriptionText = styled(BodyL)`
  color: ${brandColors.laasPurple};
  opacity: 0.8;
  @media screen and ${mediaQuery.small} {
    ${bodyMStyles}
  }
`;

export interface ErrorScreenProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

export const ErrorScreen = ({ title, description }: ErrorScreenProps) => {
  return (
    <Container>
      <ContentContainer>
        <DissatisfiedContainer>
          <SentimentDissatisfied48 />
        </DissatisfiedContainer>
        <TitleText>{title}</TitleText>
        <DescriptionText>{description}</DescriptionText>
      </ContentContainer>
    </Container>
  );
};
