import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaQuery } from '@laasco/ui-lib/constants';
import { textColors } from './textColors';

const commonHeadingStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};
`;

export const heading2Styles = css`
  ${commonHeadingStyles}
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.005em;

  @media screen and ${mediaQuery.medium} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const heading3Styles = css`
  ${commonHeadingStyles}
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.005em;

  @media screen and ${mediaQuery.medium} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const heading4Styles = css`
  ${commonHeadingStyles}
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;

  @media screen and ${mediaQuery.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const heading5Styles = css`
  ${commonHeadingStyles}
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;

  @media screen and ${mediaQuery.medium} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Heading1 = styled.h1`
  ${heading2Styles}
`;

export const Heading2 = styled.h1`
  ${heading2Styles}
`;

export const Heading3 = styled.h2`
  ${heading3Styles}
`;

export const Heading4 = styled.h3`
  ${heading4Styles}
`;

export const Heading5 = styled.h4`
  ${heading5Styles}
`;

export const subtitle1Styles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #211d17;
`;

export const Subtitle1 = styled.h5`
  ${subtitle1Styles}
`;

export const subtitle2Styles = css`
  ${subtitle1Styles}
  font-size: 14px;
  line-height: 16px;
  color: #34312d;
`;

export const Subtitle2 = styled.h6`
  ${subtitle2Styles}
`;

export const body1Styles = css`
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.002em;
  color: ${textColors.primary};
`;

export const body2Styles = css`
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: ${textColors.primary};
`;

export const Body1 = styled.p`
  ${body1Styles}
`;

export const Body1Bold = styled(Body1)`
  font-weight: 700;
`;

export const Body2 = styled.p`
  ${body2Styles}
`;

export const buttonTextStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: ${textColors.primaryButton};
`;

export const ButtonText = styled.p`
  ${buttonTextStyles}
`;

export const CardHeadingText = styled(Heading5)`
  font-weight: 700;
`;

export const caption1Styles = css`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
`;

export const caption2Styles = css`
  ${caption1Styles}
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
`;

export const Caption1 = styled.p`
  ${caption1Styles}
`;

export const Caption2 = styled.p`
  ${caption2Styles}
`;

export const body1LinkStyles = css`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${textColors.linkHover};
  }
`;

export const Body1Link = styled(Body1.withComponent('a'))`
  ${body1LinkStyles};
`;
