import React, { TextareaHTMLAttributes, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { brandColors } from '../constants';
import { body1Styles } from '../typography';

const StyledInput = styled.textarea<{
  isBlank: boolean;
  hasErrors?: boolean;
  resize?: boolean;
}>`
  padding: ${(props) => (props.hasErrors ? '11px 15px' : '12px 16px')};

  background: white;
  outline: none;

  border: ${(props) =>
    props.hasErrors
      ? css`2px solid ${brandColors.systemError}`
      : css`1px solid ${brandColors.laasPurple60}`};

  box-sizing: border-box;
  border-radius: 2px;

  ${body1Styles}
  font-weight: ${(props) => (props.isBlank ? 'normal' : '600')};

  color: ${brandColors.laasPurple};
  caret-color: black;

  &:focus {
    border: 2px solid ${brandColors.laasPurple};
    padding: 11px 15px;
  }

  &:disabled {
    border: 1px solid ${brandColors.coalGrey20};
  }

  ::placeholder {
    color: ${brandColors.coalGrey70};
  }

  ${({ resize }) => (resize === false ? 'resize: none;' : '')}
`;

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasErrors?: boolean;
  resize?: boolean;
  className?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const [value, setValue] = useState(props.defaultValue ?? props.value);

    return (
      <StyledInput
        className={props.className}
        isBlank={!value}
        ref={ref}
        {...props}
        onChange={(event) => {
          setValue(event.target.value);
          props.onChange?.(event);
        }}
      />
    );
  },
);
TextArea.displayName = 'TextArea';
