import React from 'react';
import markerSDK, { MarkerSdk } from '@marker.io/browser';

type WidgetValue = MarkerSdk | null;

const MarkerIOWidgetContext = React.createContext<{
  widget: WidgetValue;
}>({ widget: null });

export const useMarkerIOWidget = () => {
  return React.useContext(MarkerIOWidgetContext);
};

export const MarkerIOWidgetProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [widget, setWidget] = React.useState<WidgetValue>(null);

  React.useEffect(() => {
    if (!process.env.NEXT_PUBLIC_NO_MARKER_IO) {
      markerSDK
        .loadWidget({ destination: '636e22c59971393db1da9f94' })
        .then((widget) => setWidget(widget))
        .catch(() => {});
    }
  }, [setWidget]);

  return (
    <MarkerIOWidgetContext.Provider value={{ widget }}>
      {children}
    </MarkerIOWidgetContext.Provider>
  );
};
