export const Check = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.99991 16.1698L4.82991 11.9998L3.40991 13.4098L8.99991 18.9998L20.9999 6.99984L19.5899 5.58984L8.99991 16.1698Z" />
  </svg>
);

export const Check12 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clipPath="url(#clip0_7477_48791)">
      <path
        d="M4.5002 8.0998L2.4002 5.9998L1.7002 6.6998L4.5002 9.4998L10.5002 3.4998L9.8002 2.7998L4.5002 8.0998Z"
        fill="#211D17"
      />
    </g>
    <defs>
      <clipPath id="clip0_7477_48791">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
