import { SVGProps } from 'react';

export const EventBusy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 2.40039H18V0.400391H16V2.40039H8V0.400391H6V2.40039H5C3.9 2.40039 3 3.30039 3 4.40039V18.4004C3 19.5004 3.9 20.4004 5 20.4004H19C20.1 20.4004 21 19.5004 21 18.4004V4.40039C21 3.30039 20.1 2.40039 19 2.40039ZM19 18.4004H5V8.40039H19V18.4004ZM5 6.40039V4.40039H19V6.40039H5ZM8.23 15.8104L9.29 16.8704L11.73 14.4304L14.17 16.8704L15.23 15.8104L12.79 13.3704L15.23 10.9304L14.17 9.87039L11.73 12.3104L9.29 9.87039L8.23 10.9304L10.67 13.3704L8.23 15.8104Z"
      fill="currentColor"
    />
  </svg>
);
