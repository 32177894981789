import styled from '@emotion/styled';
import { BodyLSecondary } from '../typography';
import { brandColors } from '../constants';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Tag = styled.span`
  padding: 0px 16px;
  background: ${brandColors.coalGrey8};
  border-radius: 50vh;
`;

export interface TagsProps {
  tags: string[];
}

export const Tags = (props: TagsProps) => (
  <Wrapper>
    {props.tags.map((tag) => (
      <Tag key={tag}>
        <BodyLSecondary>{tag}</BodyLSecondary>
      </Tag>
    ))}
  </Wrapper>
);
