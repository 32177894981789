import { StylesConfig } from 'react-select';
import { baseSelectStyles } from './baseSelectStyles';
import { TreeItemOptionData } from './TreeItemOption';

const typedBaseSelectStyles =
  baseSelectStyles as StylesConfig<TreeItemOptionData>;

export const treeSelectStyles: StylesConfig<TreeItemOptionData> = {
  ...typedBaseSelectStyles,
  placeholder: (styles, state) => ({
    ...typedBaseSelectStyles.placeholder!(styles, state),
    padding: '0px 10px',
  }),
  input: (styles, state) => ({
    ...typedBaseSelectStyles.input!(styles, state),
    padding: '0px 10px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    flexWrap: 'nowrap',
    padding: '2px 6px',
  }),
  option: (styles, state) => ({
    ...typedBaseSelectStyles.option!(styles, state),
    padding: 0,
  }),
};

export const singleTreeSelectStyles: StylesConfig<TreeItemOptionData> = {
  ...treeSelectStyles,
  multiValue: (styles) => ({
    ...styles,
    background: 'inherit',
    borderRadius: 'none',
    margin: 0,
  }),

  valueContainer: (styles) => ({
    ...styles,
    flexWrap: 'nowrap',
    padding: 0,
  }),

  multiValueLabel: (styles, state) => ({
    ...typedBaseSelectStyles.multiValueLabel!(styles, state),
    paddingLeft: '16px',
  }),
};
