import { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { HeadingL, HeadingXS } from '../../typography';
import { Spacer } from '../../Spacer';
import { brandColors, mediaQuery, zIndex } from '../../constants';
import { Stepper } from '../../navigation/Stepper';
import { ProgressBar } from '../ProgressBar';
import { ModalPageHeading } from '../ModalPageLayout/ModalPageHeading';

const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        background: ${brandColors.laasLightBg};
      }
    `}
  />
);

const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  padding: 88px 120px 48px 120px;
  max-width: 1920px;
  width: 100%;

  @media screen and ${mediaQuery.large} {
    padding: 88px 48px 48px 48px;
  }
  @media screen and ${mediaQuery.medium} {
    padding: 88px 16px 48px 16px;
  }
`;

const FooterDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${brandColors.laasPurple20};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BottomActionsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const StickyHeadingContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${zIndex.nav};

  display: flex;
  flex-direction: column;
`;

const StaticPageHeading = styled(ModalPageHeading)`
  position: static;
`;

const ModalPageHeadingTitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ModalPageHeadingTitleSeparator = styled.div`
  width: 1px;
  background: ${brandColors.laasPurple40};
`;

const ModalPageHeadingTitle = (props: {
  subtitle: ReactNode;
  stepText: ReactNode;
}) => (
  <ModalPageHeadingTitleContainer>
    {props.subtitle}
    <ModalPageHeadingTitleSeparator />
    {props.stepText}
  </ModalPageHeadingTitleContainer>
);

export interface WizardProps {
  steps: {
    label: ReactNode;
    pageHeading: ReactNode;
    disabled?: boolean;
    error?: boolean;
    dataTest?: string;
  }[];
  activeStep: number;
  onStepChange: (step: number) => void;

  subtitle: ReactNode;
  activeStepLabel: ReactNode;
  pageTopActions?: ReactNode;
  pageBottomActions?: ReactNode;
}

const WizardLayout = (props: PropsWithChildren<WizardProps>) => {
  const { activeStep, onStepChange } = props;
  const totalSteps = props.steps.length;

  return (
    <Wrapper>
      <StickyHeadingContainer>
        <StaticPageHeading
          title={
            <ModalPageHeadingTitle
              subtitle={props.subtitle}
              stepText={
                <span>
                  {props.activeStepLabel} {activeStep + 1} / {totalSteps}
                </span>
              }
            />
          }
          actions={props.pageTopActions}
        />

        <ProgressContainer>
          <ProgressBar
            progressPercentage={((activeStep + 1) / totalSteps) * 100}
          />
        </ProgressContainer>
      </StickyHeadingContainer>

      <ContentWrapper>
        <HeadingContainer>
          <HeadingXS>{props.subtitle}</HeadingXS>
          <HeadingL>{props.steps[activeStep].pageHeading}</HeadingL>
        </HeadingContainer>
        <Spacer height={48} />

        <Stepper
          activeStep={activeStep}
          steps={props.steps.map((step, index) => ({
            ...step,
            onClick: () => onStepChange(index),
          }))}
        />

        <Spacer height={48} />
        {props.children}
        <Spacer height={48} />

        <FooterDivider />

        <Spacer height={48} />
        <BottomActionsContainer>
          {props.pageBottomActions}
        </BottomActionsContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

export const WizardPageLayout = (props: PropsWithChildren<WizardProps>) => (
  <>
    <GlobalStyle />
    <WizardLayout {...props} />
  </>
);
