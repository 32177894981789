export const FormatQuote = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4707_66616"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4707_66616)">
      <path
        d="M15 17L17 13H13V6H20V13L18 17H15ZM6 17L8 13H4V6H11V13L9 17H6Z"
        fill="#907BA6"
      />
    </g>
  </svg>
);
