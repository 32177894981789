export const PreviewSurvey = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4170_61250"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4170_61250)">
      <path
        d="M5.3077 20.5C4.81058 20.5 4.38503 20.3229 4.03102 19.9689C3.67701 19.6149 3.5 19.1894 3.5 18.6923V5.3077C3.5 4.81058 3.67701 4.38503 4.03102 4.03102C4.38503 3.67701 4.81058 3.5 5.3077 3.5H18.6923C19.1894 3.5 19.6149 3.67701 19.9689 4.03102C20.3229 4.38503 20.5 4.81058 20.5 5.3077V18.6923C20.5 19.1894 20.3229 19.6149 19.9689 19.9689C19.6149 20.3229 19.1894 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.782 19 18.8557 18.9711 18.9134 18.9134C18.9711 18.8557 19 18.782 19 18.6923V6.99998H4.99997V18.6923C4.99997 18.782 5.02883 18.8557 5.08653 18.9134C5.14423 18.9711 5.21795 19 5.3077 19ZM12 16.5C10.7743 16.5 9.67531 16.1756 8.70288 15.5269C7.73046 14.8782 7.0122 14.0359 6.5481 13C7.0122 11.9641 7.73046 11.1218 8.70288 10.4731C9.67531 9.82436 10.7743 9.5 12 9.5C13.2256 9.5 14.3246 9.82436 15.2971 10.4731C16.2695 11.1218 16.9878 11.9641 17.4519 13C16.9878 14.0359 16.2695 14.8782 15.2971 15.5269C14.3246 16.1756 13.2256 16.5 12 16.5ZM12 15.3077C12.8628 15.3077 13.6567 15.1045 14.3817 14.6981C15.1067 14.2916 15.6955 13.7256 16.148 13C15.6955 12.2743 15.1067 11.7083 14.3817 11.3019C13.6567 10.8955 12.8628 10.6923 12 10.6923C11.1372 10.6923 10.3432 10.8955 9.61825 11.3019C8.89325 11.7083 8.30448 12.2743 7.85193 13C8.30448 13.7256 8.89325 14.2916 9.61825 14.6981C10.3432 15.1045 11.1372 15.3077 12 15.3077ZM12.0023 14.3077C12.3661 14.3077 12.6747 14.1803 12.9279 13.9256C13.1811 13.6709 13.3077 13.3616 13.3077 12.9977C13.3077 12.6338 13.1803 12.3253 12.9256 12.0721C12.6709 11.8189 12.3616 11.6923 11.9977 11.6923C11.6338 11.6923 11.3253 11.8197 11.0721 12.0744C10.8189 12.3291 10.6923 12.6384 10.6923 13.0023C10.6923 13.3661 10.8197 13.6747 11.0744 13.9279C11.3291 14.1811 11.6384 14.3077 12.0023 14.3077Z"
        fill="#4D2A6F"
      />
    </g>
  </svg>
);
