import React from 'react';
import { showToast, ShowToastOptions, updateToast } from './showToast';
import { toast, ToastOptions } from 'react-toastify';
import { toastNotificationAutoCloseMs } from '../constants';
export type ProgressToastState = 'loading' | 'error' | 'success' | 'initial';
export interface UseProgressToastProps {
  successOptions: ShowToastOptions;
  failureOptions: ShowToastOptions;
  loadingOptions: ShowToastOptions;
}

let current = 0;
const getToastId = () => {
  current += 1;
  return `progress-toast-${current}`;
};

const showOrUpdate = (
  toastId: string | undefined,
  options: ShowToastOptions,
  toastOptions: ToastOptions = {},
) => {
  const shouldCreate = !toastId;
  toastId = toastId || getToastId();
  if (shouldCreate) {
    showToast(options, { toastId, ...toastOptions });
  } else {
    updateToast(toastId, options, toastOptions);
  }
  return toastId;
};

export const useProgressToast = (props: UseProgressToastProps) => {
  const currentRef = React.useRef<string | undefined>(undefined);
  const [state, setState] = React.useState<ProgressToastState>('initial');

  const setToastState = React.useCallback(
    (newState: ProgressToastState) => {
      switch (newState) {
        case 'success':
          currentRef.current = showOrUpdate(
            currentRef.current,
            props.successOptions,
            { autoClose: toastNotificationAutoCloseMs, isLoading: false },
          );
          break;
        case 'error':
          currentRef.current = showOrUpdate(
            currentRef.current,
            props.failureOptions,
            { isLoading: false },
          );
          break;
        case 'loading':
          if (currentRef.current) {
            toast.dismiss(currentRef.current);
          }
          currentRef.current = showOrUpdate(undefined, props.loadingOptions, {
            isLoading: true,
          });
          break;
        case 'initial':
          if (currentRef.current) {
            toast.dismiss(currentRef.current);
            currentRef.current = undefined;
          }
          break;
      }
      setState(newState);
    },
    [currentRef, props],
  );
  return {
    setToastState,
    toastState: state,
  };
};
