import { isValid } from 'date-fns';
import moment, { Moment } from 'moment';
import React from 'react';
import { DayPickerSingleDateControllerShape } from 'react-dates';
import { SingleDatePickerControl } from '../calendar/SingleDatePickerControl';
import { withLabel, WithLabelProps } from '../form';
import { TextField, TextFieldProps } from './TextField';

const TextFieldWithLabel = withLabel(TextField);

const conformDateInputValueToDate = (
  value: string,
  dateFormat: string,
): Moment | null => {
  const parsedDate = moment(value.trim(), dateFormat, true);
  return parsedDate.isValid() ? parsedDate : null;
};

export interface DateInputProps {
  datePickerProps: Omit<
    DayPickerSingleDateControllerShape,
    'focused' | 'onFocusChange' | 'initialVisibleMonth'
  >;
  textFieldProps: WithLabelProps<TextFieldProps>;
  dateFormat?: string;
  'data-test'?: string;
}

export const DateInput = ({
  datePickerProps,
  textFieldProps,
  dateFormat = moment.localeData().longDateFormat('L'),
  'data-test': dataTest,
}: DateInputProps) => {
  const formatDateInputValue = (date: Moment | null) =>
    date ? date.format(dateFormat) : '';

  const [dateInputValue, setDateInputValue] = React.useState<string>(
    formatDateInputValue(datePickerProps.date),
  );
  const [datePickerFocused, setDatePickerFocused] = React.useState(false);
  const textFieldRef = React.useRef<HTMLInputElement>(null);

  return (
    <SingleDatePickerControl
      {...datePickerProps}
      focused={datePickerFocused}
      onFocusChange={({ focused }) => {
        setDatePickerFocused(focused);
        if (!focused) {
          textFieldRef.current?.blur();
        }
      }}
      onDateChange={(date) => {
        setDateInputValue(formatDateInputValue(date));
        datePickerProps.onDateChange?.(date);
      }}
      renderMonthText={datePickerProps.renderMonthText as any}
      initialVisibleMonth={
        datePickerProps.date ? () => datePickerProps.date! : null
      }
    >
      <TextFieldWithLabel
        {...textFieldProps}
        data-test={dataTest}
        ref={textFieldRef}
        onClick={(e) => {
          e.preventDefault();
          textFieldProps.onClick?.(e);
          setDatePickerFocused(true);
        }}
        onFocus={(e) => {
          e.preventDefault();
          textFieldProps.onFocus?.(e);
          setDatePickerFocused(true);
        }}
        onBlur={(e) => {
          if (datePickerFocused) {
            e.preventDefault();
          } else {
            textFieldProps.onBlur?.(e);
          }
        }}
        value={dateInputValue}
        onChange={(e) => {
          setDateInputValue(e.target.value);

          const conformedDate = conformDateInputValueToDate(
            e.target.value,
            dateFormat,
          );
          datePickerProps.onDateChange?.(conformedDate);

          if (isValid(conformedDate?.toDate())) {
            textFieldRef.current?.focus();
            setDatePickerFocused(false);
          }
        }}
      />
    </SingleDatePickerControl>
  );
};
