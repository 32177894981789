import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import { arrayToTree, TreeItem } from 'performant-array-to-tree';
import { every } from 'lodash';
import { CustomSelectProps, TreeItemOptionData } from './TreeItemOption';

export type TreeReactSelect = Select<TreeItemOptionData, true>;

export interface TreeItemData {
  id: string;
  parentId?: string | null;
  disabled?: boolean;
  label: ReactNode;
  optionLabel?: ReactNode;
}

export const traverseRoots = <Result,>(
  initialValue: Result,
  roots: TreeItem[],
  visit: (
    acc: Result,
    item: TreeItem,
    depth: number,
    index: number,
    parent: TreeItem | undefined,
  ) => Result,
): Result => {
  const dfs = (
    tree: TreeItem,
    value: Result,
    depth: number,
    index: number,
    parent: TreeItem | undefined,
  ) => {
    let result = visit(value, tree, depth, index, parent);
    tree.children.forEach((child: TreeItem, index: number) => {
      result = dfs(child, result, depth + 1, index, tree);
    });
    return result;
  };
  return roots.reduce<Result>(
    (acc, root, index) => dfs(root, acc, 0, index, undefined),
    initialValue,
  );
};

const mapTreeItemToOptionData = (item: TreeItem, depth: number) => ({
  value: item.id,
  label: item.label,
  depth,
  hasChildren: item.children.length > 0,
  optionLabel: item.optionLabel,
  disabled: item.disabled,
});

const mapTreeDataToOptionDatas = (trees: TreeItem[]) =>
  traverseRoots<TreeItemOptionData[]>([], trees, (acc, item, depth) =>
    acc.concat(mapTreeItemToOptionData(item, depth)),
  );

const getDescendantNodeIds = (item: TreeItem) => {
  const allIds = new Set(
    traverseRoots<string[]>([], [item], (ids, item) => ids.concat(item.id)),
  );
  allIds.delete(item.id);
  return allIds;
};

const mapTreeDataToOptionProps = (
  trees: TreeItem[],
  checkedNodeIds: Set<string>,
  collapsedNodeIds: Set<string>,
  onlyIncludeDirectNodesInCheckedCounts: boolean,
) => {
  type Result = CustomSelectProps['optionPropsById'];

  const recur = (
    acc: Result,
    item: TreeItem,
    isHidden: boolean,
    isParentChecked: boolean,
  ): Result => {
    const isCollapsed = collapsedNodeIds.has(item.id);
    const isChecked = checkedNodeIds.has(item.id);

    const tails = item.children.reduce(
      (acc: Result, item: TreeItem) => ({
        ...acc,
        ...recur(
          acc,
          item,
          isHidden || isCollapsed,
          isParentChecked || isChecked,
        ),
      }),
      acc,
    );

    const isAncestorChecked =
      !onlyIncludeDirectNodesInCheckedCounts && (isParentChecked || isChecked);

    return {
      ...tails,
      [item.id]: {
        numberOfCheckedDescendants: item.children.reduce(
          (count: number, child: TreeItem) =>
            count +
            tails[child.id].numberOfCheckedDescendants +
            (checkedNodeIds.has(child.id) || isAncestorChecked ? 1 : 0),
          0,
        ),
        isCollapsed,
        isHidden,
        isParentChecked,
      },
    };
  };

  return recur({}, { id: null, children: trees }, false, false);
};

export const setApply = (
  values: Set<string>,
  id: string,
  isIncluded: boolean,
) => {
  const newValues = new Set(values);
  if (isIncluded) {
    newValues.add(id);
  } else {
    newValues.delete(id);
  }
  return newValues;
};

const deselectNode = (
  id: string,
  trees: TreeItem[],
  checkedNodeIds: Set<string>,
) => {
  const newCheckedNodeIds = new Set(checkedNodeIds);

  const recur = (item: TreeItem, parentIds: string[]) => {
    let didUncheckParent = false;

    item.children.forEach((child: TreeItem) => {
      const parentIdsForChild = [...parentIds, item.id];

      if (child.id === id) {
        newCheckedNodeIds.delete(id);
        didUncheckParent = parentIdsForChild.reduce<boolean>(
          (acc, nodeId) => acc || newCheckedNodeIds.delete(nodeId),
          false,
        );
      } else {
        recur(child, parentIdsForChild);
      }
    });

    if (didUncheckParent) {
      item.children.forEach((child: TreeItem) => {
        if (child.id !== id) {
          newCheckedNodeIds.add(child.id);
        }
      });
    }
  };

  recur({ id: null, children: trees }, []);

  return newCheckedNodeIds;
};

const selectNodeWithoutChildren = (
  item: TreeItem,
  checkedNodeIds: Set<string>,
) => {
  const newCheckedNodeIds = new Set(checkedNodeIds);
  newCheckedNodeIds.add(item.id);
  getDescendantNodeIds(item).forEach((nodeId) =>
    newCheckedNodeIds.delete(nodeId),
  );
  return newCheckedNodeIds;
};

const selectNode = (
  id: string,
  trees: TreeItem[],
  checkedNodeIds: Set<string>,
) => {
  let newCheckedNodeIds = new Set(checkedNodeIds);

  const recur = (item: TreeItem) => {
    let didSelectNode = false;

    item.children.forEach((child: TreeItem) => {
      if (child.id === id) {
        newCheckedNodeIds = selectNodeWithoutChildren(child, checkedNodeIds);
        didSelectNode = true;
      } else {
        recur(child);
      }
    });

    if (
      didSelectNode &&
      !!item.id &&
      every(item.children, (child: TreeItem) => newCheckedNodeIds.has(child.id))
    ) {
      newCheckedNodeIds = selectNode(item.id, trees, newCheckedNodeIds);
    }
  };

  recur({ id: null, children: trees });

  return newCheckedNodeIds;
};

const getNodeIdsForSelection = (
  id: string,
  isIncluded: boolean,
  trees: TreeItem[],
  checkedNodeIds: Set<string>,
) =>
  isIncluded
    ? selectNode(id, trees, checkedNodeIds)
    : deselectNode(id, trees, checkedNodeIds);

export const useCustomSelect = ({
  setCollapsed,
  selectOption,
}: {
  setCollapsed: (id: string, isCollapsed: boolean) => void;
  selectOption: (ref: TreeReactSelect, newValue: TreeItemOptionData) => void;
}) =>
  useCallback(
    (node: TreeReactSelect | undefined | null) => {
      if (node != null) {
        node.selectOption = (newValue) => selectOption(node, newValue);

        // This method is used to navigate between the selected options in the control component,
        // but because we hide all > 1 selections, it makes this feature redundant. Instead we'll
        // reuse the navigation events to toggle focused option collapsed state.
        node.focusValue = (direction: 'next' | 'previous') => {
          const { focusedOption } = node.state;
          if (focusedOption) {
            setCollapsed(focusedOption.value, direction === 'previous');
          }
        };
      }
    },
    [setCollapsed, selectOption],
  );

export const useTreeState = (
  treeData: TreeItemData[],
  onlyIncludeDirectNodesInCheckedCounts: boolean,
  value?: TreeItemData[],
) => {
  const [checkedNodeIds, setCheckedNodeIds] = useState(
    new Set(value?.map((item) => item.id) || []),
  );

  const [collapsedNodeIds, setCollapsedNodeIds] = useState(
    new Set([] as string[]),
  );

  const [treeItems, options] = useMemo(() => {
    const treeItems = arrayToTree(treeData, { dataField: null });
    const options = mapTreeDataToOptionDatas(treeItems);
    return [treeItems, options];
  }, [treeData]);

  const optionPropsById = useMemo(
    () =>
      mapTreeDataToOptionProps(
        treeItems,
        checkedNodeIds,
        collapsedNodeIds,
        onlyIncludeDirectNodesInCheckedCounts,
      ),
    [
      treeItems,
      checkedNodeIds,
      collapsedNodeIds,
      onlyIncludeDirectNodesInCheckedCounts,
    ],
  );

  const numberOfCheckedOptions = treeItems.reduce(
    (count, treeItem) =>
      count +
      optionPropsById[treeItem.id].numberOfCheckedDescendants +
      (checkedNodeIds.has(treeItem.id) ? 1 : 0),
    0,
  );

  const setCollapsed = (id: string, isCollapsed: boolean) =>
    setCollapsedNodeIds(setApply(collapsedNodeIds, id, isCollapsed));

  useEffect(
    () => setCheckedNodeIds(new Set(value?.map((item) => item.id) || [])),
    [value],
  );

  return {
    checkedNodeIds,
    setCheckedNodeIds,
    collapsedNodeIds,
    setCollapsed,
    treeItems,
    options,
    reactSelectValue: options.filter(({ value }) => checkedNodeIds.has(value)),
    optionPropsById,
    numberOfCheckedOptions,
  };
};

export const basicSelectOption =
  ({
    checkedNodeIds,
    optionPropsById,
    treeItems,
    options,
  }: {
    checkedNodeIds: Set<string>;
    optionPropsById: CustomSelectProps['optionPropsById'];
    treeItems: TreeItem[];
    options: TreeItemOptionData[];
  }) =>
  (ref: TreeReactSelect, newValue: TreeItemOptionData) => {
    const isIncluded = !(
      checkedNodeIds.has(newValue.value) ||
      optionPropsById[newValue.value].isParentChecked
    );
    const filteredIds = getNodeIdsForSelection(
      newValue.value,
      isIncluded,
      treeItems,
      checkedNodeIds,
    );
    const newOptions = options.filter((option) =>
      filteredIds.has(option.value),
    );

    ref.setValue(
      newOptions,
      isIncluded ? 'select-option' : 'deselect-option',
      newValue,
    );
  };
