import React from 'react';
import { TextArea, TextAreaProps } from './TextArea';

export const AutoSizingTextArea = (props: TextAreaProps) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = React.useState(props.defaultValue ?? props.value);
  const [focused, setFocused] = React.useState(false);

  const hasThickBorders = props.hasErrors || focused;

  React.useEffect(() => {
    const textAreaRef = ref.current;
    if (textAreaRef) {
      textAreaRef.style.height = '0';
      textAreaRef.style.height =
        textAreaRef.scrollHeight + (hasThickBorders ? 4 : 2) + 'px';
    }
  }, [ref, value, hasThickBorders]);

  return (
    <TextArea
      {...props}
      ref={ref}
      rows={1}
      resize={false}
      onFocus={(e) => {
        setFocused(true);
        props.onFocus?.(e);
      }}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange?.(e);
      }}
    />
  );
};
