import { SVGAttributes } from 'react';

export const GuidinLogo = (props: SVGAttributes<any>) => (
  <svg
    width="120"
    height="45"
    viewBox="0 0 120 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_35_426)">
      <path
        d="M40.4199 26.5519C39.4259 27.9438 38.2469 28.76 36.7235 28.76C34.3686 28.76 33.6312 27.0332 33.6312 24.6235V14.5424H28.6648V25.5796C28.6648 30.76 31.4712 33.0591 35.002 33.0591C37.8051 33.0591 39.3642 31.8656 40.4199 30.6299V32.6201H45.3798V14.5489H40.4199V26.5519Z"
        fill="#4D2A6F"
      />
      <path
        d="M52.5941 5.35559C50.9408 5.35559 49.5376 6.46126 49.5376 8.20431C49.5376 10.1555 50.9408 11.2644 52.5941 11.2644C53.9973 11.2644 55.3973 10.1587 55.3973 8.20431C55.4025 7.83234 55.3338 7.46304 55.1953 7.11785C55.0567 6.77266 54.8511 6.45845 54.5904 6.19344C54.3296 5.92843 54.0188 5.71791 53.6762 5.57408C53.3335 5.43026 52.9657 5.35599 52.5941 5.35559V5.35559Z"
        fill="#4D2A6F"
      />
      <path
        d="M54.9491 14.549H49.9827V32.6136H54.9491V14.549Z"
        fill="#4D2A6F"
      />
      <path
        d="M71.7095 15.1896C71.0274 14.5944 69.7996 14.1067 68.117 14.1067C62.3028 14.1067 58.3726 18.0806 58.3726 23.7813C58.3726 29.2803 61.5265 32.9323 66.6976 32.9323C69.0233 32.9323 70.4687 32.025 71.6933 30.7892V32.6136H76.6564V0.289062H71.6933L71.7095 15.1896ZM71.7095 26.5714C70.6993 27.5795 69.4358 28.3568 67.8117 28.3568C65.447 28.3568 63.5891 26.6105 63.5891 23.5048C63.5891 20.2821 65.7816 18.4968 68.4224 18.4968C69.5966 18.5052 70.7406 18.8703 71.703 19.5439L71.7095 26.5714Z"
        fill="#4D2A6F"
      />
      <path
        d="M101.898 14.227C99.7315 14.227 97.2466 15.1018 96.2072 16.1782V14.5522H91.27V32.6168H96.2072V20.2919C97.2888 19.209 99.202 18.5651 100.755 18.5651C103.028 18.5651 104.548 19.9309 104.548 22.8219V32.6168H109.512V22.0154C109.512 16.3538 106.296 14.227 101.898 14.227Z"
        fill="#4D2A6F"
      />
      <path
        d="M13.642 28.5974H12.83C10.7512 28.5974 10.4329 27.0072 10.4329 26.4739C10.4329 25.5211 10.683 24.7373 11.2709 24.2398C12.1033 24.3943 12.9481 24.4716 13.7947 24.4707C20.291 24.4707 24.8385 20.474 24.8385 15.04C24.859 13.4132 24.4833 11.8059 23.7438 10.3572L26.4918 9.33603V5.47595L20.8237 7.14747C19.0697 5.97351 16.7116 5.27759 13.7947 5.27759C6.64876 5.27759 2.75097 9.92139 2.75097 15.0335C2.75097 18.2041 4.50173 21.0691 7.3796 22.7862C6.88727 23.2625 6.49798 23.8351 6.23592 24.4683C5.97386 25.1016 5.84462 25.782 5.85621 26.4674C5.82362 27.5527 6.168 28.6158 6.83066 29.4755C3.14075 30.5714 0.30835 32.7892 0.30835 36.7208C0.30835 44.2004 9.72801 44.8508 13.6258 44.8508C16.874 44.8508 27.2681 44.2004 27.2681 36.7208C27.2843 28.9226 16.796 28.5974 13.642 28.5974ZM13.8239 9.83359C17.228 9.83359 19.7064 12.2466 19.7064 15.2644C19.7064 17.9765 17.228 20.0903 13.8239 20.0903C10.4199 20.0903 7.93827 17.9765 7.93827 15.2644C7.93827 12.2466 10.4166 9.83034 13.8239 9.83034V9.83359ZM13.6875 40.376C8.58791 40.376 5.58661 38.9679 5.58661 36.7143C5.58661 35.5891 6.18752 33.0526 13.6875 33.0526C20.8822 33.0526 22.0905 35.5891 22.0905 36.7143C22.0905 38.1224 20.5509 40.376 13.6875 40.376Z"
        fill="#4D2A6F"
      />
      <path
        d="M84.2052 5.35559C82.5519 5.35559 81.1487 6.46126 81.1487 8.20431C81.1487 10.1555 82.5519 11.2644 84.2052 11.2644C85.6084 11.2644 87.0084 10.1587 87.0084 8.20431C87.0135 7.83234 86.9448 7.46304 86.8063 7.11785C86.6678 6.77266 86.4622 6.45845 86.2015 6.19344C85.9407 5.92843 85.6299 5.71791 85.2872 5.57408C84.9446 5.43026 84.5768 5.35599 84.2052 5.35559Z"
        fill="#4D2A6F"
      />
      <path
        d="M86.5602 14.549H81.5938V32.6136H86.5602V14.549Z"
        fill="#4D2A6F"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_426">
        <rect
          width="119.633"
          height="44.5714"
          fill="white"
          transform="translate(0.324707 0.289062)"
        />
      </clipPath>
    </defs>
  </svg>
);
