import React from 'react';

export const CompetenceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5080_117154)">
        <path
          d="M29.3332 12.3193L19.7465 11.4927L15.9998 2.66602L12.2532 11.506L2.6665 12.3193L9.9465 18.626L7.75984 27.9993L15.9998 23.026L24.2398 27.9993L22.0665 18.626L29.3332 12.3193ZM15.9998 20.5327L10.9865 23.5593L12.3198 17.8527L7.89317 14.0127L13.7332 13.506L15.9998 8.13268L18.2798 13.5194L24.1198 14.026L19.6932 17.866L21.0265 23.5727L15.9998 20.5327Z"
          fill="#907BA6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5080_117154">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
