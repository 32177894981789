import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Body1 } from '../typography';
import { animationDuration, brandColors } from '../constants';

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 52px;
  gap: 16px;

  padding: 0 16px;

  border: none;
  cursor: pointer;

  color: ${brandColors.laasPurple};

  background: white;

  &:hover,
  &:focus,
  &:active {
    background: ${brandColors.laasPurple20};
  }

  transition: background ${animationDuration.short} ease-in-out;
`;

export interface PopoverMenuItemProps {
  onClick?: () => void;
  icon: ReactNode;
  label: ReactNode;
  'data-test'?: string;
}

export const PopoverMenuItem = (props: PopoverMenuItemProps) => (
  <MenuButton onClick={props.onClick} data-test={props['data-test']}>
    {props.icon}
    <Body1>{props.label}</Body1>
  </MenuButton>
);
