import React from 'react';
import usePortal from 'react-useportal';
import { useRenderClientSide } from '../useRenderClientSide';

const PortalWrapper: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const isClientSide = useRenderClientSide();
  const { Portal } = usePortal({
    bindTo: document.getElementById('modal-root')!,
  });

  if (!isClientSide) {
    return null;
  }

  return <Portal>{children}</Portal>;
};

export const ModalPortal: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const render = useRenderClientSide();

  if (!render) return null;
  return <PortalWrapper>{children}</PortalWrapper>;
};
