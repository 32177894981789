export const NavGuidinLogo = () => (
  <svg
    width="84"
    height="36"
    viewBox="0 0 84 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.8127 21.095C30.0501 22.1803 29.1443 22.8174 27.9714 22.8174C26.1636 22.8174 25.5946 21.4686 25.5946 19.5927V11.7275H21.7778V20.3438C21.7778 24.3865 23.934 26.1719 26.6475 26.1719C28.8036 26.1719 29.9998 25.2399 30.8127 24.2725V25.8259H34.6294V11.7275H30.8127V21.095Z"
      fill="#DBD4E2"
    />
    <path
      d="M40.169 4.55444C38.8955 4.55444 37.8193 5.41568 37.8193 6.77635C37.8193 8.3022 38.8955 9.16736 40.169 9.16736C41.2451 9.16736 42.3213 8.30613 42.3213 6.77635C42.3213 5.41568 41.2451 4.55444 40.169 4.55444Z"
      fill="#DBD4E2"
    />
    <path
      d="M41.9803 11.7275H38.1636V25.8259H41.9803V11.7275Z"
      fill="#DBD4E2"
    />
    <path
      d="M54.8554 12.227C54.3329 11.763 53.3884 11.3815 52.0954 11.3815C47.6283 11.3815 44.6206 14.4843 44.6206 18.9321C44.6206 23.2225 47.0438 26.0737 51.0154 26.0737C52.8038 26.0737 53.9148 25.3619 54.8554 24.3984V25.822H58.6722V0.598389H54.8554V12.227V12.227ZM54.8554 21.1107C54.0813 21.8973 53.1096 22.5029 51.8554 22.5029C50.0361 22.5029 48.6193 21.1383 48.6193 18.7158C48.6193 16.2029 50.3071 14.8068 52.3316 14.8068C53.2567 14.8068 54.1045 15.1096 54.8516 15.6248V21.1107H54.8554Z"
      fill="#DBD4E2"
    />
    <path
      d="M78.0423 11.4761C76.3777 11.4761 74.4694 12.1564 73.6681 12.998V11.7278H69.8745V25.8261H73.6681V16.207C74.5003 15.3615 75.9713 14.8581 77.1713 14.8581C78.9132 14.8581 80.0861 15.9238 80.0861 18.1772V25.8221H83.9029V17.5558C83.9068 13.1356 81.4332 11.4761 78.0423 11.4761Z"
      fill="#DBD4E2"
    />
    <path
      d="M10.2348 22.6917C10.2232 22.6917 10.2155 22.6917 10.2039 22.6917H9.61161C8.0129 22.6917 7.76903 21.449 7.76903 21.0321C7.76903 20.2889 7.96258 19.6754 8.41161 19.2861C9.0271 19.404 9.67742 19.467 10.351 19.467C15.3445 19.467 18.8361 16.3484 18.8361 12.1052C18.8361 10.7759 18.5536 9.53325 17.9923 8.45179L20.1058 7.65347V4.64111L15.7548 5.95066C14.3961 5.03437 12.5923 4.49561 10.3548 4.49561C4.86194 4.49561 1.86968 8.12145 1.86968 12.1091C1.86968 14.5827 3.21677 16.8203 5.4271 18.1613C4.66839 18.9085 4.25419 19.8956 4.25419 21.0321C4.25419 22.0153 4.54839 22.7821 5.01677 23.3799C2.17548 24.2372 0 25.9675 0 29.0349C0 34.8709 7.23871 35.3782 10.2348 35.3782C12.7316 35.3782 20.7174 34.8709 20.7174 29.0349C20.7174 22.9473 12.6465 22.6917 10.2348 22.6917ZM10.3742 8.04673C12.991 8.04673 14.8955 9.93044 14.8955 12.2821C14.8955 14.4018 12.991 16.0495 10.3742 16.0495C7.75742 16.0495 5.8529 14.4018 5.8529 12.2821C5.84903 9.93044 7.75355 8.04673 10.3742 8.04673ZM10.2697 31.8861C6.34839 31.8861 4.04516 30.7889 4.04516 29.0271C4.04516 28.1462 4.50581 26.1681 10.2697 26.1681C15.7974 26.1681 16.7265 28.1462 16.7265 29.0271C16.7265 30.1243 15.5419 31.8861 10.2697 31.8861Z"
      fill="#DBD4E2"
    />
    <path
      d="M64.4595 4.55444C63.186 4.55444 62.1099 5.41568 62.1099 6.77635C62.1099 8.3022 63.186 9.16736 64.4595 9.16736C65.5357 9.16736 66.6118 8.30613 66.6118 6.77635C66.6118 5.41568 65.5357 4.55444 64.4595 4.55444Z"
      fill="#DBD4E2"
    />
    <path
      d="M66.2709 11.7275H62.4541V25.8259H66.2709V11.7275Z"
      fill="#DBD4E2"
    />
  </svg>
);

export const NavGuidinLogoStrong = () => (
  <svg
    width="84"
    height="36"
    viewBox="0 0 84 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2056_10554)">
      <path
        d="M30.8132 21.095C30.0506 22.1803 29.1448 22.8174 27.9719 22.8174C26.1641 22.8174 25.5951 21.4686 25.5951 19.5927V11.7275H21.7783V20.3438C21.7783 24.3865 23.9344 26.1719 26.648 26.1719C28.8041 26.1719 30.0003 25.2399 30.8132 24.2725V25.8259H34.6299V11.7275H30.8132V21.095Z"
        fill="#4D2A6F"
      />
      <path
        d="M40.169 4.55444C38.8955 4.55444 37.8193 5.41568 37.8193 6.77635C37.8193 8.3022 38.8955 9.16736 40.169 9.16736C41.2451 9.16736 42.3213 8.30613 42.3213 6.77635C42.3213 5.41568 41.2451 4.55444 40.169 4.55444Z"
        fill="#4D2A6F"
      />
      <path
        d="M41.9808 11.7275H38.1641V25.8259H41.9808V11.7275Z"
        fill="#4D2A6F"
      />
      <path
        d="M54.8559 12.227C54.3334 11.763 53.3888 11.3815 52.0959 11.3815C47.6288 11.3815 44.6211 14.4843 44.6211 18.9321C44.6211 23.2225 47.0443 26.0737 51.0159 26.0737C52.8043 26.0737 53.9153 25.3619 54.8559 24.3984V25.822H58.6727V0.598389H54.8559V12.227ZM54.8559 21.1108C54.0817 21.8973 53.1101 22.5029 51.8559 22.5029C50.0366 22.5029 48.6198 21.1383 48.6198 18.7158C48.6198 16.2029 50.3075 14.8068 52.3321 14.8068C53.2572 14.8068 54.105 15.1096 54.8521 15.6248V21.1108H54.8559Z"
        fill="#4D2A6F"
      />
      <path
        d="M78.0427 11.4761C76.3782 11.4761 74.4698 12.1564 73.6685 12.998V11.7278H69.875V25.8261H73.6685V16.207C74.5008 15.3615 75.9718 14.8581 77.1718 14.8581C78.9137 14.8581 80.0866 15.9238 80.0866 18.1772V25.8221H83.9034V17.5558C83.9073 13.1356 81.4337 11.4761 78.0427 11.4761Z"
        fill="#4D2A6F"
      />
      <path
        d="M10.2348 22.6917C10.2232 22.6917 10.2155 22.6917 10.2039 22.6917H9.61161C8.0129 22.6917 7.76903 21.449 7.76903 21.0321C7.76903 20.2889 7.96258 19.6754 8.41161 19.2861C9.0271 19.404 9.67742 19.467 10.351 19.467C15.3445 19.467 18.8361 16.3484 18.8361 12.1052C18.8361 10.7759 18.5536 9.53325 17.9923 8.45179L20.1058 7.65347V4.64111L15.7548 5.95066C14.3961 5.03437 12.5923 4.49561 10.3548 4.49561C4.86194 4.49561 1.86968 8.12145 1.86968 12.1091C1.86968 14.5827 3.21677 16.8203 5.4271 18.1613C4.66839 18.9085 4.25419 19.8956 4.25419 21.0321C4.25419 22.0153 4.54839 22.7821 5.01677 23.3799C2.17548 24.2372 0 25.9675 0 29.0349C0 34.8709 7.23871 35.3782 10.2348 35.3782C12.7316 35.3782 20.7174 34.8709 20.7174 29.0349C20.7174 22.9473 12.6465 22.6917 10.2348 22.6917ZM10.3742 8.04673C12.991 8.04673 14.8955 9.93044 14.8955 12.2821C14.8955 14.4018 12.991 16.0495 10.3742 16.0495C7.75742 16.0495 5.8529 14.4018 5.8529 12.2821C5.84903 9.93044 7.75355 8.04673 10.3742 8.04673ZM10.2697 31.8861C6.34839 31.8861 4.04516 30.7889 4.04516 29.0271C4.04516 28.1462 4.50581 26.1681 10.2697 26.1681C15.7974 26.1681 16.7265 28.1462 16.7265 29.0271C16.7265 30.1243 15.5419 31.8861 10.2697 31.8861Z"
        fill="#4D2A6F"
      />
      <path
        d="M64.4591 4.55444C63.1855 4.55444 62.1094 5.41568 62.1094 6.77635C62.1094 8.3022 63.1855 9.16736 64.4591 9.16736C65.5352 9.16736 66.6113 8.30613 66.6113 6.77635C66.6113 5.41568 65.5352 4.55444 64.4591 4.55444Z"
        fill="#4D2A6F"
      />
      <path
        d="M66.2709 11.7275H62.4541V25.8259H66.2709V11.7275Z"
        fill="#4D2A6F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2056_10554">
        <rect
          width="84"
          height="35"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
