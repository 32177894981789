import styled from '@emotion/styled';
import { brandColors } from '../constants';

const Styled = styled.svg`
  position: relative;
  top: 2px;
`;

export const ShoppingCart = (props: any) => (
  <Styled
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.55 11.6182C15.3 11.6182 15.96 11.2082 16.3 10.5882L19.88 4.09816C20.25 3.43816 19.77 2.61816 19.01 2.61816H4.21L3.27 0.618164H0V2.61816H2L5.6 10.2082L4.25 12.6482C3.52 13.9882 4.48 15.6182 6 15.6182H18V13.6182H6L7.1 11.6182H14.55ZM5.16 4.61816H17.31L14.55 9.61816H7.53L5.16 4.61816ZM6 16.6182C4.9 16.6182 4.01 17.5182 4.01 18.6182C4.01 19.7182 4.9 20.6182 6 20.6182C7.1 20.6182 8 19.7182 8 18.6182C8 17.5182 7.1 16.6182 6 16.6182ZM16 16.6182C14.9 16.6182 14.01 17.5182 14.01 18.6182C14.01 19.7182 14.9 20.6182 16 20.6182C17.1 20.6182 18 19.7182 18 18.6182C18 17.5182 17.1 16.6182 16 16.6182Z"
      fill={props.fill || brandColors.laasPurple}
    />
  </Styled>
);
