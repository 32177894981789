import React from 'react';

export const Question = (props: React.SVGAttributes<any>) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 32.1494H22V28.1494H18V32.1494ZM20 0.149414C8.96 0.149414 0 9.10941 0 20.1494C0 31.1894 8.96 40.1494 20 40.1494C31.04 40.1494 40 31.1894 40 20.1494C40 9.10941 31.04 0.149414 20 0.149414ZM20 36.1494C11.18 36.1494 4 28.9694 4 20.1494C4 11.3294 11.18 4.14941 20 4.14941C28.82 4.14941 36 11.3294 36 20.1494C36 28.9694 28.82 36.1494 20 36.1494ZM20 8.14941C15.58 8.14941 12 11.7294 12 16.1494H16C16 13.9494 17.8 12.1494 20 12.1494C22.2 12.1494 24 13.9494 24 16.1494C24 20.1494 18 19.6494 18 26.1494H22C22 21.6494 28 21.1494 28 16.1494C28 11.7294 24.42 8.14941 20 8.14941Z"
        fill="currentColor"
      />
    </svg>
  );
};
