export const Mental = (props: any) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.48152 4.54414C9.75485 4.54414 10.7868 3.52674 10.7868 2.27137C10.7868 1.01716 9.75485 0 8.48152 0C7.20866 0 6.17676 1.01716 6.17676 2.27137C6.17676 3.52674 7.20866 4.54414 8.48152 4.54414Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4258 12.4665L12.3628 12.1758L4.97004 13.9153C4.37304 14.0608 4.08379 14.6508 4.28354 15.1511C4.58454 15.9048 5.23279 15.8832 5.60779 15.8131L8.11204 15.3491L11.0123 15.8944C11.7045 16.0153 12.5375 15.5927 12.5063 14.8273L10.8848 15.002L10.714 14.8136L13.515 14.3411C14.413 14.1817 14.9728 13.5239 14.7255 12.8795C14.665 12.7216 14.5585 12.5844 14.4258 12.4665Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5178 12.3465C2.3073 12.4598 2.14332 12.6061 2.05982 12.7825C1.80933 13.3112 2.37067 13.8519 3.27305 13.9839L3.59723 14.0338C3.72876 13.609 4.18951 13.2472 4.85372 13.1151L7 12.7029L4.17165 12.1562L2.5178 12.3465Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.924111 12.3681L4.6001 11.8711C5.03415 11.7756 5.155 11.5126 5.30486 11.2555C5.6519 10.6174 6.02439 9.69336 6.02439 9.69336C6.20147 10.3569 6.19053 11.1814 5.93941 12.4027L8.31094 13.0961L11.0313 12.3062C10.8275 11.4971 10.8275 10.3262 10.9835 9.69336C10.9835 9.69336 11.3562 10.6174 11.703 11.2555C11.8531 11.5126 11.9737 11.7756 12.4077 11.8711L16.0438 12.3681C16.6122 12.4703 16.887 12.0362 16.9679 11.6644C17.0765 11.163 16.9216 10.7406 16.3196 10.6117L13.4038 9.84097C13.4038 9.84097 12.219 7.37248 11.7338 6.66634C11.0427 5.66024 9.69248 5.58594 8.49158 5.58594C7.28457 5.58594 5.90531 5.72682 5.27408 6.66634C4.64767 7.59888 3.59994 9.84097 3.59994 9.84097L0.694362 10.6267C0.092129 10.7556 -0.0780837 11.1779 0.0308117 11.6794C0.111466 12.0511 0.355463 12.4703 0.924111 12.3681Z"
      fill="#907BA6"
    />
  </svg>
);
