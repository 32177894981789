export const ChevronDown = (props: { className?: string }) => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M1.41 0.649414L6 5.59408L10.59 0.649414L12 2.17168L6 8.64941L0 2.17168L1.41 0.649414Z"
      fill="currentColor"
    />
  </svg>
);
