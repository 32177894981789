import { StaticImageData } from 'next/image';

export interface PersonProps {
  id: string;
  profilePictureSrc?: string | StaticImageData | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
}

export const defaultProfilePictureSize = 64;

export interface ProfilePictureDimensionsProps {
  pictureSize?: number;
}

interface NamedPersonProps extends PersonProps {
  firstName: string;
  lastName: string;
}

export const personIsNamed = (
  person: PersonProps,
): person is NamedPersonProps =>
  !!(person.firstName?.length && person.lastName?.length);

export const personDisplayName = (person: PersonProps) =>
  personIsNamed(person)
    ? `${person.firstName} ${person.lastName}`
    : person.email;
