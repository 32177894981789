import styled from '@emotion/styled';
import { brandColors } from '../constants';
import { PersonBlack } from '../icons';
import { bodyLStyles } from '../typography';
import {
  defaultProfilePictureSize,
  ProfilePictureDimensionsProps,
} from './common';

export const ProfilePictureContainer = styled.div<{
  pictureSize: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${bodyLStyles};
  color: ${brandColors.laasPurple};
  background: ${brandColors.laasPurple10};
  object-fit: cover;
  border: ${(p) => (p.pictureSize > 48 ? 1 : 2)}px solid
    ${brandColors.laasPurple20};
  height: ${(p) => p.pictureSize}px;
  width: ${(p) => p.pictureSize}px;
  border-radius: ${(p) => p.pictureSize}px;
  font-weight: bold;
  flex-shrink: 0;
`;

export interface ProfilePicturePlaceholderProps
  extends ProfilePictureDimensionsProps {
  initials?: string;
  className?: string;
}

export const ProfilePicturePlaceholder = ({
  pictureSize = defaultProfilePictureSize,
  ...props
}: ProfilePicturePlaceholderProps) => (
  <ProfilePictureContainer
    className={props.className}
    pictureSize={pictureSize}
  >
    {props.initials || (
      <PersonBlack width={pictureSize / 2} height={pictureSize / 2} />
    )}
  </ProfilePictureContainer>
);
