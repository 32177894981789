export const EditCalendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_4170_2852"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4170_2852)">
      <path
        d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14ZM5 8H19V6H5V8Z"
        fill={props.fill ?? '#4D2A6F'}
      />
    </g>
  </svg>
);
