import { SVGProps } from 'react';

export const Reviews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_6242_69432"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6242_69432)">
      <path
        d="M11.55 13.975C11.6333 14.175 11.7833 14.275 12 14.275C12.2167 14.275 12.3667 14.175 12.45 13.975L13.55 11.55L15.975 10.45C16.175 10.3667 16.275 10.2167 16.275 10C16.275 9.78333 16.175 9.63333 15.975 9.55L13.55 8.45L12.45 6.025C12.3667 5.825 12.2167 5.725 12 5.725C11.7833 5.725 11.6333 5.825 11.55 6.025L10.45 8.45L8.025 9.55C7.825 9.63333 7.725 9.78333 7.725 10C7.725 10.2167 7.825 10.3667 8.025 10.45L10.45 11.55L11.55 13.975ZM2 19.575V4C2 3.45 2.196 2.979 2.588 2.587C2.97933 2.19567 3.45 2 4 2H20C20.55 2 21.021 2.19567 21.413 2.587C21.8043 2.979 22 3.45 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.021 17.8043 20.55 18 20 18H6L3.7 20.3C3.38333 20.6167 3.02067 20.6873 2.612 20.512C2.204 20.3373 2 20.025 2 19.575ZM4 17.175L5.175 16H20V4H4V17.175Z"
        fill="#4D2A6F"
      />
    </g>
  </svg>
);
