import { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { brandColors } from '../constants';
import { ErrorExclamationMark } from '../icons';
import { Body1 } from '../typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.span<{ disabled?: boolean; warning?: boolean }>`
  margin-bottom: 16px;

  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.05em;

  color: ${(props) => {
    if (props.warning) {
      return brandColors.systemError;
    }
    return props.disabled ? brandColors.coalGrey20 : brandColors.laasPurple;
  }};
`;

const Asterix = styled.span<{ warning?: boolean }>`
  color: ${(props) =>
    props.warning ? brandColors.systemError : brandColors.laasPurple60};
`;

const ErrorLabel = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: ${brandColors.systemError};
`;

const ErrorMessage = (props: { message: ReactNode }) => (
  <ErrorLabel>
    <ErrorExclamationMark />
    {props.message}
  </ErrorLabel>
);

const HintText = styled(Body1)`
  color: ${brandColors.coalGrey50};
  line-height: 20px;
  margin-top: 4px;
`;

export interface FormItemLabelProps {
  label: ReactNode;
  hint?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  warning?: boolean;
  errorMessage?: ReactNode;
  className?: string;
}

export const FormItemLabel = ({
  label,
  hint,
  disabled,
  required,
  warning,
  errorMessage,
  children,
  className,
}: PropsWithChildren<FormItemLabelProps>) => (
  <Container className={className}>
    <LabelText disabled={disabled} warning={warning}>
      {label}
      {(required || warning) && <Asterix warning={warning}>*</Asterix>}
    </LabelText>
    {children}
    {hint && <HintText>{hint}</HintText>}
    {errorMessage && <ErrorMessage message={errorMessage} />}
  </Container>
);
