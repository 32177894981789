import React, {
  PropsWithChildren,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import styled from '@emotion/styled';
import {
  autoPlacement,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react-dom';
import { brandColors, zIndex } from '../constants';
import { SupplementaryButton } from '../buttons';
import ReactDOM from 'react-dom';
import { useOnViewportChange } from '../useOnViewportChange';
import { BouncingLoader } from '../loader';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-width: 280px;

  background: white;

  button {
    border-bottom: 1px solid ${brandColors.coalGrey8};
    &:nth-last-of-type(1) {
      border-bottom: none;
    }
  }

  filter: drop-shadow(5px 4px 12px rgba(77, 42, 111, 0.1));
`;

const MenuPlacement = (
  props: PropsWithChildren<{ container?: Element | null }>,
) =>
  props.container ? (
    ReactDOM.createPortal(props.children, props.container)
  ) : (
    <>{props.children}</>
  );

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 64px;
`;

export interface PopoverMenuProps {
  menuIcon: ReactNode;
  menuContainer?: Element | null;
  ['data-test']?: string;
  label?: ReactNode;
  loading?: boolean;
}

export const PopoverMenu = (props: PropsWithChildren<PopoverMenuProps>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const { x, y, reference, floating, strategy, update } = useFloating({
    middleware: [autoPlacement(), shift({ padding: 8 }), offset(8)],
  });

  useOnViewportChange(update);

  useEffect(() => {
    if (clicked && !props.loading) {
      setIsOpen(false);
    }
  }, [props.loading, clicked]);

  const menuItems = useMemo(
    () =>
      React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        return React.cloneElement(child, {
          onClick: (e: MouseEvent<any, any>) => {
            e.stopPropagation();
            setClicked(true);
            child.props.onClick?.(e);
          },
        } as any);
      }),
    [props.children, setClicked],
  );

  useEffect(() => {
    if (isOpen) {
      setClicked(false);
      const closeMenu = () => setIsOpen(false);
      document.addEventListener('click', closeMenu);
      return () => document.removeEventListener('click', closeMenu);
    }
  }, [isOpen, setIsOpen]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
    >
      <SupplementaryButton ref={reference} data-test={props['data-test']}>
        {props.label}
        {props.menuIcon}
      </SupplementaryButton>

      {isOpen && (
        <MenuPlacement
          container={
            props.menuContainer ?? document.getElementById('tooltip-root')
          }
        >
          <MenuWrapper
            ref={floating}
            style={{
              zIndex: zIndex.tooltip,
              position: strategy,
              top: y ?? '',
              left: x ?? '',
            }}
          >
            {props.loading ? (
              <LoaderContainer>
                <BouncingLoader radius={32} />
              </LoaderContainer>
            ) : (
              menuItems
            )}
          </MenuWrapper>
        </MenuPlacement>
      )}
    </div>
  );
};
