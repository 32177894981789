import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import moment from 'moment';
import { useYupLocale } from '../hooks/useYupLocale';

const messagesMap: { [k: string]: any } = {
  en: require('../i18n/compiled-messages/en.json'),
  fi: require('../i18n/compiled-messages/fi.json'),
  fr: require('../i18n/compiled-messages/fr.json'),
  sv: require('../i18n/compiled-messages/sv.json'),
  nl: require('../i18n/compiled-messages/nl.json'),
  ko: require('../i18n/compiled-messages/ko.json'),
  es: require('../i18n/compiled-messages/es.json'),
};

const getMessages = (locale: string, defaultLocale: string) =>
  messagesMap[locale] ||
  messagesMap[locale?.split('-')[0]] ||
  messagesMap[defaultLocale] ||
  {};

const useMomentLocale = (locale: string) => {
  useEffect(() => {
    if (locale === 'en') {
      moment.updateLocale('en', null);
    } else {
      import(`moment/locale/${locale}`).then((localization) =>
        moment.updateLocale(locale, localization),
      );
    }
  }, [locale]);
};

const ProvideYupLocale: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  useYupLocale();
  return <>{children}</>;
};

// ts-unused-exports:disable-next-line
export const LaasIntlProviderWithoutNextRouter: React.FunctionComponent<
  React.PropsWithChildren<{ locale: string; defaultLocale: string }>
> = React.memo(({ children, locale, defaultLocale }) => {
  useMomentLocale(locale);

  return (
    <IntlProvider
      locale={locale}
      messages={getMessages(locale, defaultLocale)}
      wrapRichTextChunksInFragment={true}
      defaultRichTextElements={React.useMemo(
        () => ({
          br: () => <br />,
          u: (chunks) => <u>{chunks}</u>,
          b: (chunks) => <b>{chunks}</b>,
          p: (chunks) => <p>{chunks}</p>,
          h1: (chunks) => <h1>{chunks}</h1>,
          h2: (chunks) => <h2>{chunks}</h2>,
          h3: (chunks) => <h3>{chunks}</h3>,
          h4: (chunks) => <h4>{chunks}</h4>,
          li: (chunks) => <li>{chunks}</li>,
          ul: (chunks) => <ul>{chunks}</ul>,
          strong: (chunks) => <strong>{chunks}</strong>,
        }),
        [],
      )}
    >
      <ProvideYupLocale>{children}</ProvideYupLocale>
    </IntlProvider>
  );
});
LaasIntlProviderWithoutNextRouter.displayName =
  'LaasIntlProviderWithoutNextRouter';

export const LaaSIntlProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const router = useRouter();
  const defaultLocale = router?.defaultLocale || 'en';
  const locale = router?.locale || defaultLocale;
  return (
    <LaasIntlProviderWithoutNextRouter
      locale={locale}
      defaultLocale={defaultLocale}
    >
      {children}
    </LaasIntlProviderWithoutNextRouter>
  );
};
