import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { body2Styles, headingSStyles } from '../typography';
import {
  AccordionHeaderContainer,
  AccordionM,
  AccordionMHeading,
  AccordionMIcon,
  AccordionMItemCountBubble,
  AccordionMLabelContainer,
} from './AccordionM';

export const accordionSStyles = css`
  ${AccordionMLabelContainer} {
    padding-bottom: 12px;
  }

  ${AccordionHeaderContainer} {
    gap: 12px;
  }

  ${AccordionMItemCountBubble} {
    ${body2Styles};
    padding: 4px 16px;
  }

  ${AccordionMIcon} {
    width: 24px;
    height: 24px;
  }

  ${AccordionMHeading} {
    ${headingSStyles}
  }
`;

export const AccordionS = styled(AccordionM)`
  ${accordionSStyles}
`;
