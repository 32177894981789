import { SVGProps } from 'react';
import { brandColors } from '../constants';

export const Person = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={brandColors.laasPurple60}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.99984 7.99984C9.47317 7.99984 10.6665 6.8065 10.6665 5.33317C10.6665 3.85984 9.47317 2.6665 7.99984 2.6665C6.5265 2.6665 5.33317 3.85984 5.33317 5.33317C5.33317 6.8065 6.5265 7.99984 7.99984 7.99984ZM7.99984 9.33317C6.21984 9.33317 2.6665 10.2265 2.6665 11.9998V13.3332H13.3332V11.9998C13.3332 10.2265 9.77984 9.33317 7.99984 9.33317Z" />
    </svg>
  );
};
