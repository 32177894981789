import { createContext, useContext } from 'react';
import { NavChildrenContext } from './NavChildrenContext';

export const NavItemContext = createContext<{
  id: string;
  isOpen: boolean;
  setIsOpen: (b: boolean) => any;
}>({
  id: '',
  setIsOpen: () => {},
  isOpen: false,
});

export const NavItemContextProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    id: string;
  }>
> = ({ children, id }) => {
  const navChildrenContext = useContext(NavChildrenContext);
  return (
    <NavItemContext.Provider
      value={{
        id,
        isOpen: navChildrenContext.openId === id,
        setIsOpen: (isOpen: boolean) =>
          isOpen
            ? navChildrenContext.setOpenId(id)
            : navChildrenContext.setOpenId(null),
      }}
    >
      {children}
    </NavItemContext.Provider>
  );
};
