import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRenderClientSide } from '../useRenderClientSide';

const WaveContainer = styled.div`
  height: 78px;
  width: 100%;
  overflow: hidden;
  bottom: 0;
  position: relative;
  display: flex;
  flex-direction: row;
`;

const WaveSVG = (props: any) => (
  <svg
    width="100%"
    height="75"
    viewBox="0 0 1438 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M1372.78 17.6209C1409.7 20.4386 1434.31 22.1493 1442 22.6525V78H0V34.2251C37.6843 32.2125 121.359 27.8853 154.582 26.6777C196.112 25.1683 359.923 17.6209 452.211 17.6209C544.499 17.6209 696.774 -0.492868 793.677 0.0102917C890.579 0.513451 1012.86 12.0861 1070.54 12.0861C1128.22 12.0861 1199.74 7.05452 1248.2 8.06084C1296.65 9.06716 1326.64 14.0988 1372.78 17.6209Z"
      fill="#ffffff"
    />
  </svg>
);

const expandAnimation = (flipX: boolean) => keyframes`
  /* flipping with scaleX(-1) breaks svg width calculation */
  0%,
  100% {
    transform: translate3d(15px, -25px, 15px) ${flipX && 'scaleX(-1.15)'};
  }
  50% {
    transform: translate3d(-15px, 0px, -15px) ${flipX && 'scaleX(-1.15)'};
  }
`;

const Wave = styled(WaveSVG, { shouldForwardProp: () => false })<{
  animationDuration: number;
  flipX?: boolean;
}>`
  width: calc(100% + 200px);
  height: 78px;
  position: absolute;
  bottom: -56px;

  animation: ${({ flipX }) => expandAnimation(flipX || false)}
    ${({ animationDuration }) => animationDuration}s ease-in-out 0s infinite;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export interface WaveContainerProps {
  className?: string;
}

export const FooterWave = (props: WaveContainerProps) => {
  const [animationDurationTop] = React.useState(() => Math.random() * 10 + 10);
  const [animationDurationBottom] = React.useState(
    () => Math.random() * 10 + 20,
  );
  const isClientSide = useRenderClientSide();
  return (
    <WaveContainer className={props.className}>
      {isClientSide && (
        <>
          <Wave animationDuration={animationDurationTop} />
          <Wave animationDuration={animationDurationBottom} flipX={true} />
        </>
      )}
    </WaveContainer>
  );
};
