import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { brandColors } from '../constants';

const Container = styled.div`
  background-color: ${brandColors.laasPurple5};
  border-radius: 40px 0px;
  padding: 32px;
  padding-top: 40px;
  width: 100%;
`;

export const HighlightsContainer: React.FC<
  PropsWithChildren<{
    className?: string;
  }>
> = (props) => {
  return <Container className={props.className}>{props.children}</Container>;
};
