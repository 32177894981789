import { useCurrentUser } from '../../provider/UserProvider';
import {
  DropDownMenuProps,
  MenuItemProps,
  Navbar,
  NavGridMenuItemProps,
  NavGridMenuProps,
  TopMenuItemProps,
} from '@laasco/ui-lib/navigation';
import { getImageId } from '../../common/getImageId';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import {
  hasAdmin,
  hasHR as hasHr,
  hasProvider,
  hasSupervisor,
} from '../../common/userRoleUtils';
import { Exit, Gear, ListIcon, SpeechBalloon } from '@laasco/ui-lib/icons';
import { ProfileImage } from '../../components/images/ProfileImage';
import { useCurrentUserController } from '../../common/useCurrentUserController';
import { LanguageByLanguageCode } from '../../i18n/commonMessages';
import React, { useCallback, useMemo, useState } from 'react';
import { FeedbackModal } from '../../components/dialogs/FeedbackModal';
import { useEnabledFeaturesMatcher } from '../../hooks/useEnabledFeaturesMatcher';
import { AppModule } from '../../generated';
import { GlobeIcon } from '@laasco/ui-lib/icons/GlobeIcon';
import { getImageUrl } from '../../common/getImageUrl';

const setActive = (menuItem: any, currentRoute: string) => {
  if (menuItem.children) {
    menuItem.children.forEach((item: any) => setActive(item, currentRoute));
  } else {
    menuItem.active =
      menuItem.href === currentRoute ||
      (menuItem.matchActive
        ? typeof menuItem.matchActive === 'string'
          ? menuItem.matchActive === currentRoute
          : currentRoute.match(menuItem.matchActive)
        : false);
  }
};

const filterNavElementsByHref = (
  menuItems: readonly any[],
  isIncluded: (href: string) => boolean,
): any[] =>
  menuItems.reduce((filteredItems, originalMenuItem) => {
    const menuItem = { ...originalMenuItem };
    if (menuItem.href && !isIncluded(menuItem.href)) {
      return filteredItems;
    }

    if (menuItem.children) {
      menuItem.children = filterNavElementsByHref(
        menuItem.children,
        isIncluded,
      );
      if (menuItem.children.length === 0) {
        return filteredItems;
      }
    }

    return [...filteredItems, menuItem];
  }, []);

const commonMenuItems: TopMenuItemProps[] = [
  {
    type: 'LinkMenuItem',
    href: '/',
    label: (
      <FormattedMessage
        defaultMessage={'Home'}
        description={'navigation - Home'}
      />
    ),
    dataTest: 'home',
  },
  {
    type: 'LinkMenuItem',
    href: '/services',
    label: (
      <FormattedMessage
        defaultMessage={'Services'}
        description={'navigation - Services'}
      />
    ),
    dataTest: 'services',
  },
];

const userSurveysMenu: TopMenuItemProps = {
  type: 'DropDownMenu',
  label: (
    <FormattedMessage
      defaultMessage={'Surveys'}
      description={'navigation - Surveys'}
    />
  ),
  dataTest: 'surveys',
  children: [
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'My Well-being'}
          description={'navigation - Surveys/Well-being'}
        />
      ),
      matchActive: '/surveys/wellbeing/results/[tab]',
      href: '/surveys/wellbeing/results',
      dataTest: 'wellbeing',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'My Employee-Experience'}
          description={'navigation - Surveys/Employee-Experience'}
        />
      ),
      href: '/surveys/employee-experience/results',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          description={'navigation - Surveys/Pulse'}
          defaultMessage={'Pulse'}
        />
      ),
      href: '/surveys/pulse',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          description={'navigation - Surveys/Change Pulse'}
          defaultMessage={'Change pulse'}
        />
      ),
      href: '/surveys/change-pulse',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          description={'navigation - Surveys/Competence'}
          defaultMessage={'Competence'}
        />
      ),
      href: '/surveys/competence',
    },
  ],
};

const unitLeaderSurveysMenu: NavGridMenuProps = {
  type: 'NavGridMenu',
  label: (
    <FormattedMessage
      description={'navigation - unit-leader/Surveys'}
      defaultMessage={'Surveys'}
    />
  ),
  children: [
    {
      type: 'NavGridMenuCategory',
      label: <FormattedMessage defaultMessage={'Unit leader'} />,
      columns: 3,
      children: [
        {
          type: 'NavGridMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Pulse'}
              description={'navigation - unit-leader/Surveys/Pulse'}
            />
          ),
          children: [
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Pulse/My pulse'
                  }
                  defaultMessage={'My Pulse'}
                />
              ),
              href: '/surveys/pulse',
            },
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Pulse/Team pulse'
                  }
                  defaultMessage={'Team Pulse'}
                />
              ),
              href: '/surveys/pulse/group',
            },
          ],
        },
        {
          type: 'NavGridMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Well-being'}
              description={'navigation - unit-leader/Surveys/Well-being'}
            />
          ),
          children: [
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Well-being/My Well-being'
                  }
                  defaultMessage={'My Well-being'}
                />
              ),
              href: '/surveys/wellbeing/results',
              matchActive: '/surveys/wellbeing/results/[tab]',
            },
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Well-being/Team Well-being'
                  }
                  defaultMessage={'Team Well-being'}
                />
              ),
              href: '/surveys/wellbeing/results/group/summary',
              matchActive: /\/surveys\/wellbeing\/results\/group\/.*/,
            },
          ],
        },
        {
          type: 'NavGridMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Competence'}
              description={'navigation - unit-leader/Surveys/Competence'}
            />
          ),
          children: [
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Competence/My Competence'
                  }
                  defaultMessage={'My Competence'}
                />
              ),
              href: '/surveys/competence',
            },
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Competence/Team Competence'
                  }
                  defaultMessage={'Team Competence'}
                />
              ),
              href: '/surveys/competence/group',
            },
          ],
        },
        {
          type: 'NavGridMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Employee-Experience'}
              description={
                'navigation - unit-leader/Surveys/Employee-Experience'
              }
            />
          ),
          children: [
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Employee-Experience/My Employee-Experience'
                  }
                  defaultMessage={'My Employee-Experience'}
                />
              ),
              href: '/surveys/employee-experience/results',
            },
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Employee-Experience/Team Employee-Experience'
                  }
                  defaultMessage={'Team Employee-Experience'}
                />
              ),
              href: '/surveys/employee-experience/results/group/summary',
              matchActive: /\/surveys\/employee-experience\/results\/group\/.*/,
            },
          ],
        },
        {
          type: 'NavGridMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Change Pulse'}
              description={'navigation - unit-leader/Surveys/Change Pulse'}
            />
          ),
          children: [
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Change Pulse/My Change Pulse'
                  }
                  defaultMessage={'My Change Pulse'}
                />
              ),
              href: '/surveys/change-pulse',
            },
            {
              type: 'LinkMenuItem',
              label: (
                <FormattedMessage
                  description={
                    'navigation - unit-leader/Surveys/Change Pulse/Team Change Pulse'
                  }
                  defaultMessage={'Team Change Pulse'}
                />
              ),
              href: '/surveys/change-pulse/group',
            },
          ],
        },
      ],
    },
  ],
};

const adminProviderMenu: NavGridMenuItemProps = {
  type: 'NavGridMenuItem',
  hideOnMobile: true,
  label: (
    <FormattedMessage
      defaultMessage={'My services'}
      description={'admin - My services'}
    />
  ),
  children: [
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          description={'admin - My services/order management'}
          defaultMessage={'Order management'}
        />
      ),
      href: '/provider/orders',
      dataTest: 'ordermanagement',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          description={'admin - My services/Services'}
          defaultMessage={'Services'}
        />
      ),
      href: '/provider/services',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          description={'admin - My services/Statistics'}
          defaultMessage={'Statistics'}
        />
      ),
      href: '/provider/statistics',
    },
  ],
};

const adminOrganizationServicesMenu: NavGridMenuItemProps = {
  type: 'NavGridMenuItem',
  hideOnMobile: true,
  label: (
    <FormattedMessage
      defaultMessage={'Organization services'}
      description={'navigation - admin/organization services'}
    />
  ),
  dataTest: 'service-control',
  children: [
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Order management'}
          description={
            'navigation - admin/organization services/order management'
          }
        />
      ),
      href: '/admin/services/orders',
    },
    {
      type: 'DropDownMenu',
      label: (
        <FormattedMessage
          defaultMessage={'Service catalogue'}
          description={
            'navigation - admin/organization services/service catalogue'
          }
        />
      ),
      dataTest: 'servicemanagement',
      children: [
        {
          type: 'LinkMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Catalogue'}
              description={
                'navigation - admin/organization services/service catalogue/Catalogue'
              }
            />
          ),
          href: '/admin/services/catalogue',
          dataTest: 'catalogue',
        },
        {
          type: 'LinkMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Themes and service linking'}
              description={
                'navigation - admin/organization services/service catalogue/Themes and service linking'
              }
            />
          ),
          href: '/admin/services/themes',
        },
        {
          type: 'LinkMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Categories'}
              description={
                'navigation - admin/organization services/service catalogue/Categories'
              }
            />
          ),
          href: '/admin/services/categories',
        },
        {
          type: 'LinkMenuItem',
          label: (
            <FormattedMessage
              defaultMessage={'Tags'}
              description={
                'navigation - admin/organization services/service catalogue/Tags'
              }
            />
          ),
          href: '/admin/services/tags',
        },
      ],
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Service providers'}
          description={
            'navigation - admin/organization services/service catalogue/Service providers'
          }
        />
      ),
      href: '/admin/services/providers',
    },
  ],
};

const adminOrganizationMenu: NavGridMenuItemProps = {
  type: 'NavGridMenuItem',
  hideOnMobile: true,
  label: (
    <FormattedMessage
      defaultMessage={'Organization'}
      description={'navigation - admin/organization'}
    />
  ),
  children: [
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Users'}
          description={'navigation - admin/organization/users'}
        />
      ),
      href: '/admin/organization/users',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Locations'}
          description={'navigation - admin/organization/locations'}
        />
      ),
      href: '/admin/organization/locations',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Units'}
          description={'navigation - admin/organization/units'}
        />
      ),
      href: '/admin/organization/units',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Job titles'}
          description={'navigation - admin/organization/job titles'}
        />
      ),
      href: '/admin/organization/job-titles',
    },
  ],
};

const adminSurveysMenu: NavGridMenuItemProps = {
  type: 'NavGridMenuItem',
  hideOnMobile: true,
  label: (
    <FormattedMessage
      defaultMessage={'Surveys'}
      description={'navigation - admin/surveys'}
    />
  ),
  children: [
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Competence'}
          description={'navigation - admin/surveys/competence'}
        />
      ),
      href: '/admin/surveys/competence/list',
      matchActive: /\/admin\/surveys\/competence\/.*/,
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Well-being'}
          description={'navigation - admin/surveys/well-being'}
        />
      ),
      href: '/admin/surveys/wellbeing/list',
      matchActive: /\/admin\/surveys\/wellbeing\/.*/,
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Pulse'}
          description={'navigation - admin/surveys/pulse'}
        />
      ),
      href: '/admin/surveys/pulse/list',
      matchActive: /\/admin\/surveys\/pulse\/.*/,
      dataTest: 'admin-pulse',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Change pulse surveys'}
          description={'navigation - admin/surveys/change-pulse'}
        />
      ),
      href: '/admin/surveys/change-pulse/list',
      matchActive: /\/admin\/surveys\/change-pulse\/.*/,
      dataTest: 'admin-change-pulse',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Employee experience'}
          description={'navigation - admin/surveys/employee experience'}
        />
      ),
      href: '/admin/surveys/employee-experience/list',
      matchActive: /\/admin\/surveys\/employee-experience\/.*/,
    },
  ],
};

const getAdminQuestionLibraryMenu = (
  isModuleEnabled: (module: AppModule) => boolean,
): NavGridMenuItemProps => {
  const children = [
    {
      type: 'LinkMenuItem' as const,
      label: (
        <FormattedMessage
          defaultMessage={'Competencies and skills'}
          description={'navigation - admin/questions/competence'}
        />
      ),
      href: '/admin/questions/competence',
      matchActive: /\/admin\/questions\/competence\/.*/,
    },
  ];

  // These two require special care, because we want to show two distinct links for
  // the employee-experience/pulse questions page, even those those are linked to the same href.
  if (isModuleEnabled(AppModule.EmployeeExperience)) {
    children.push({
      type: 'LinkMenuItem' as const,
      label: (
        <FormattedMessage
          defaultMessage={'Meaningfulness of work'}
          description={'navigation - admin/questions/meaningfulness of work'}
        />
      ),
      href: '/admin/questions/pulse',
      matchActive: /\/admin\/questions\/pulse\/.*/,
    });
  }

  if (isModuleEnabled(AppModule.Pulse)) {
    children.push({
      type: 'LinkMenuItem' as const,
      label: (
        <FormattedMessage
          defaultMessage={'Pulse'}
          description={'navigation - admin/questions/pulse'}
        />
      ),
      href: '/admin/questions/pulse',
      matchActive: /\/admin\/questions\/pulse\/.*/,
    });
  }

  return {
    type: 'NavGridMenuItem',
    hideOnMobile: true,
    label: (
      <FormattedMessage
        defaultMessage={'Question library'}
        description={'navigation - admin/questions'}
      />
    ),
    children,
  };
};

const adminStatisticsMenu: NavGridMenuItemProps = {
  type: 'NavGridMenuItem',
  hideOnMobile: true,
  label: (
    <FormattedMessage
      defaultMessage="Statistics"
      description="navigation - admin/statistics"
    />
  ),
  children: [
    {
      label: (
        <FormattedMessage
          defaultMessage="Development plan statistics"
          description="navigation - admin/statistics/development-plan"
        />
      ),
      type: 'LinkMenuItem',
      href: '/admin/development-plan/statistics',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Service statistics'}
          description={
            'navigation - admin/organization services/service catalogue/Service statistics'
          }
        />
      ),
      href: '/admin/services/statistics',
    },
  ],
};

const adminSystemMenu: NavGridMenuItemProps = {
  type: 'NavGridMenuItem',
  hideOnMobile: true,
  label: (
    <FormattedMessage
      defaultMessage={'System'}
      description={'navigation - admin/system'}
    />
  ),
  children: [
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'News'}
          description={'navigation - admin/system/news'}
        />
      ),
      href: '/admin/system/news',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Customization'}
          description={'navigation - admin/system/customization'}
        />
      ),
      href: '/admin/system/customization',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Licenses'}
          description={'navigation - admin/system/license'}
        />
      ),
      href: '/admin/system/license',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Subscription'}
          description={'navigation - admin/system/subscription'}
        />
      ),
      href: '/admin/system/subscription',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Feedback'}
          description={'navigation - admin/system/feedback'}
        />
      ),
      href: '/admin/system/feedback',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Service suggestions'}
          description={'navigation - admin/system/service suggestions'}
        />
      ),
      href: '/admin/system/feedback',
    },
    {
      type: 'LinkMenuItem',
      label: (
        <FormattedMessage
          defaultMessage={'Support'}
          description={'navigation - admin/system/support'}
        />
      ),
      href: 'https://helpcenter.guidin.com/fi',
    },
  ],
};

export interface NavbarViewProps {
  alwaysOpaque?: boolean;
}

export const NavbarView = ({ alwaysOpaque }: NavbarViewProps) => {
  const user = useCurrentUser();
  const router = useRouter();
  const userController = useCurrentUserController();
  const featuresMatcher = useEnabledFeaturesMatcher();
  const isHr = hasHr(user?.userRoles ?? []);
  const isSupervisor = hasSupervisor(user?.userRoles ?? []);
  const isProvider = hasProvider(user?.userRoles ?? []);
  const isAdmin = hasAdmin(user?.userRoles ?? []);
  const isUrlAllowed = useCallback(
    (url: string) => featuresMatcher.isUrlAllowed(url),
    [featuresMatcher],
  );
  const isModuleEnabled = useCallback(
    (module: AppModule) => featuresMatcher.isModuleEnabled(module),
    [featuresMatcher],
  );
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const allMenuItems = useMemo(() => {
    const surveysMenuItem =
      isHr || isSupervisor ? unitLeaderSurveysMenu : userSurveysMenu;

    const adminMenuItems: NavGridMenuItemProps[] = isAdmin
      ? [
          adminOrganizationServicesMenu,
          adminSurveysMenu,
          getAdminQuestionLibraryMenu(isModuleEnabled),
          adminOrganizationMenu,
          adminStatisticsMenu,
          adminSystemMenu,
        ]
      : [];

    const adminMenuLabel = (
      <FormattedMessage
        description={'navigation - Admin'}
        defaultMessage={'Admin'}
      />
    );

    const providerMenuLabel = (
      <FormattedMessage
        defaultMessage={'Service Provider'}
        description={'navigation - service provider'}
      />
    );

    const serviceProviderMenu: DropDownMenuProps | null = isProvider
      ? {
          type: 'DropDownMenu',
          label: providerMenuLabel,
          children: adminProviderMenu.children,
          hideOnMobile: true,
        }
      : null;

    const maintenanceMenu: NavGridMenuProps | DropDownMenuProps | null = isAdmin
      ? {
          type: 'NavGridMenu',
          label: adminMenuLabel,
          dataTest: 'maintenance',
          children: [
            {
              type: 'NavGridMenuCategory',
              label: (
                <FormattedMessage
                  defaultMessage={'Admin'}
                  description={'Nominative'}
                />
              ),
              children: adminMenuItems,
              columns: 3,
            },
          ],
          hideOnMobile: true,
        }
      : null;

    const devPlanMenuLabel = (
      <FormattedMessage
        defaultMessage={'Development plan'}
        description={'navigation - development plan'}
      />
    );

    const developmentPlanLinkMenuItem = {
      type: 'LinkMenuItem',
      href: '/development-plan',
      label: devPlanMenuLabel,
      dataTest: 'development-plan',
      matchActive: /\/development-plan\/(?!team-plans)/,
    };

    const developmentPlanMenuItem =
      isSupervisor || isHr
        ? ({
            type: 'DropDownMenu',
            label: devPlanMenuLabel,
            dataTest: 'development-plan-supervisor',
            children: [
              {
                ...developmentPlanLinkMenuItem,
                label: (
                  <FormattedMessage
                    defaultMessage={'My plan'}
                    description={
                      ' Navigation item - Development plan supervisor menu - My plan'
                    }
                  />
                ),
              },
              {
                type: 'LinkMenuItem',
                href: '/development-plan/team-plans',
                dataTest: 'team-development-plans',
                label: (
                  <FormattedMessage
                    defaultMessage={'Team plans'}
                    description={
                      'Navigation item - Development plan supervisor menu - Team Plans'
                    }
                  />
                ),
              },
            ],
          } as DropDownMenuProps)
        : developmentPlanLinkMenuItem;

    const allMenuItems = [
      ...commonMenuItems,
      developmentPlanMenuItem,
      surveysMenuItem,
    ];

    if (serviceProviderMenu) {
      allMenuItems.push(serviceProviderMenu);
    }

    if (maintenanceMenu) {
      allMenuItems.push(maintenanceMenu);
    }

    allMenuItems.forEach((item) => setActive(item, router.pathname));

    return filterNavElementsByHref(allMenuItems, isUrlAllowed);
  }, [
    isAdmin,
    isHr,
    isModuleEnabled,
    isProvider,
    isSupervisor,
    isUrlAllowed,
    router.pathname,
  ]);

  const userMenuItems = useMemo(() => {
    const languageMenu: DropDownMenuProps = {
      type: 'DropDownMenu',
      children: (user?.availableLanguages || []).map((l) => ({
        type: 'LinkMenuItem',
        label: (
          <>
            {l.toUpperCase()} - {LanguageByLanguageCode[l]}
          </>
        ),
        onClick: () => userController.setLanguage(l),
        dataTest: l,
      })),
      label: (
        <>
          {router.locale!.toUpperCase()} -{' '}
          {LanguageByLanguageCode[router.locale!]}
        </>
      ),
      icon: <GlobeIcon />,
      dataTest: 'language',
    };

    const userMenuItems: MenuItemProps[] = [
      {
        type: 'LinkMenuItem',
        icon: <ListIcon />,
        label: (
          <FormattedMessage
            defaultMessage={'Order history'}
            description={'navigation - account/service order history'}
          />
        ),
        href: '/account/order-history',
      },
      {
        type: 'LinkMenuItem',
        icon: <Gear />,
        label: (
          <FormattedMessage
            defaultMessage={'Settings'}
            description={'navigation - account/settings'}
          />
        ),
        href: '/account/settings',
        dataTest: 'settings',
        showBadge: !user?.lastName || !user?.firstName,
      },
      {
        type: 'LinkMenuItem',
        onClick: () => {
          setFeedbackOpen(true);
        },
        icon: <SpeechBalloon />,
        label: (
          <FormattedMessage
            defaultMessage={'Give feedback'}
            description={'navigation - account/give feedback'}
          />
        ),
        dataTest: 'feedback',
      },
      languageMenu,
      {
        type: 'LinkMenuItem',
        icon: <Exit />,
        label: (
          <FormattedMessage
            defaultMessage={'Logout'}
            description={'navigation - account/logout'}
          />
        ),
        href: '/logout',
        dataTest: 'logout',
      },
    ];

    return filterNavElementsByHref(userMenuItems, isUrlAllowed);
  }, [isUrlAllowed, router.locale, user, userController]);

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = React.useState(false);

  return (
    <>
      <Navbar
        isBurgerMenuOpen={isBurgerMenuOpen}
        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
        alwaysOpaque={alwaysOpaque}
        key={router.pathname}
        logoUrl={
          user?.customizations.menuLogoUrl &&
          getImageUrl(
            `unsafe/trim/fit-in/${4 * 60}x${4 * 128}/${getImageId(
              user?.customizations.menuLogoUrl,
            )!}`,
          )
        }
        menuItems={allMenuItems}
        userMenuItems={userMenuItems}
        userMenuLabel={`${user?.firstName}`}
        mobileUserMenuLabel={
          <FormattedMessage
            defaultMessage={'My account'}
            description={'Expanded hamburger menu user menu label'}
          />
        }
        avatar={
          <ProfileImage
            width={48}
            cloudinaryImageUrl={
              user?.avatarPictureUri || 'cloudinary://v3/person-placeholder'
            }
          />
        }
      />
      <FeedbackModal
        isOpen={feedbackOpen}
        onClose={() => setFeedbackOpen(false)}
      />
    </>
  );
};
