import styled from '@emotion/styled';
import { animationDuration, brandColors } from '../constants';
import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';

export type SecondaryButtonProps = PrimaryButtonProps;

export const StyledButton = styled(PrimaryButton)`
  background: transparent;
  color: ${brandColors.laasPurple};
  border: 1px solid ${brandColors.laasPurple};
  box-shadow: none;

  &:disabled {
    background: transparent;
    border-color: ${brandColors.coalGrey20};
    color: ${brandColors.coalGrey20};
    cursor: not-allowed;
  }

  &:hover,
  &:active {
    background: transparent;

    &:not(:disabled) {
      box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
    }
  }

  &:focus-visible {
    outline: none;
    &:not(:disabled) {
      box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
    }
  }

  transition: box-shadow ${animationDuration.short} ease-in-out;
` as React.FunctionComponent<React.PropsWithChildren<SecondaryButtonProps>>;

export const SecondaryButton: React.FC<
  React.PropsWithChildren<SecondaryButtonProps>
> = (props) => <StyledButton {...props}>{props.children}</StyledButton>;
