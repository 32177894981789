import styled from '@emotion/styled';
import { brandColors } from '../constants';
import { ChevronDown } from '../icons/ChevronDown';
import { SkeletonListLoader } from './SkeletonListLoader';
import { TextSkeleton } from './TextSkeleton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 24px 8px 24px 0px;
  border-bottom: 1px solid ${brandColors.coalGrey20};
  justify-content: space-between;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const Chevron = styled(ChevronDown)`
  color: ${brandColors.laasPurple10};
`;

export const AccordionSkeleton: React.FC<
  React.PropsWithChildren<{ animationDelay: number }>
> = (props) => {
  return (
    <Container>
      <TitleContainer>
        <TextSkeleton
          animationDelay={props.animationDelay}
          height={32}
          width={172}
        />
        <TextSkeleton
          animationDelay={props.animationDelay}
          height={30}
          width={40}
        />
      </TitleContainer>
      <Chevron />
    </Container>
  );
};

interface AccordionSkeletonLoaderProps {
  count: number;
}

export const AccordionSkeletonLoader: React.FC<
  React.PropsWithChildren<AccordionSkeletonLoaderProps>
> = ({ count }) => {
  return (
    <SkeletonListLoader count={count} gap={0}>
      {(index) => <AccordionSkeleton animationDelay={index * 100} />}
    </SkeletonListLoader>
  );
};
