import * as React from 'react';

export const AnimationPlaceholder = (props: any) => (
  <svg
    width={123}
    height={125}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={123}
        height={125}
      >
        <path d="M0 0h123v125H0V0Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#b)">
        <path
          opacity={0.1}
          d="M60.093-28.631c-11.8 1.386-40.377 6.328-51.308 17.328-14.03 14.12-16.092 34.067-11.174 53.4C2.53 61.426 17.5 75.416 45.5 77.5c28 2.082 57.08-24.422 58.5-60 .424-10.615.001-11 0-15.5-4.045-21.372-25-32.852-43.907-30.631Z"
          fill="#9F8BB1"
        />
      </g>
      <mask
        id="c"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={0}
        width={123}
        height={125}
      >
        <path d="M2 0h123v125H2V0Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#c)">
        <path
          opacity={0.05}
          d="M-163.018-42.53c-6.017 31.256-17.052 108.106-.95 144.165 20.671 46.288 63.583 66.163 110.509 67.703 46.926 1.54 37.932-65.593 113.722-113.047 75.791-47.454 26.8-138.684-51.48-168.352-23.356-8.852-36.834-6.642-58.439-8.883-50.805-5.27-103.721 28.333-113.362 78.413Z"
          fill="#3E5853"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h123v125H0z" />
      </clipPath>
    </defs>
  </svg>
);
