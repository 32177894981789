import styled from '@emotion/styled';
import { PropsWithChildren, ReactNode } from 'react';
import { HeadingL, HeadingS } from '../typography';
import { Spacer } from '../Spacer';
import { mediaQuery } from '../constants';
import {
  DefaultContentContainer,
  DefaultPageLayout,
} from './DefaultPageLayout';
import { TextSkeleton } from '../loader';

export interface UserSurveyResultPageLayoutProps {
  header?: ReactNode;
  breadcrumbs?: ReactNode;
  tabs: ReactNode;
  navbar: React.ReactNode;
  footer?: React.ReactNode;
}

const MobileHeader = styled(HeadingS)`
  @media screen and ${mediaQuery.widerThanMedium} {
    display: none;
  }
`;

const DesktopHeader = styled(HeadingL)`
  @media screen and ${mediaQuery.medium} {
    display: none;
  }
`;

const DesktopBreadCrumbsContainer = styled.div`
  padding-left: 40px;

  @media screen and ${mediaQuery.small} {
    display: none;
  }
`;

const MobileSpacer = styled.div`
  @media screen and ${mediaQuery.small} {
    height: 24px;
  }
  @media screen and ${mediaQuery.widerThanSmall} {
    display: none;
  }
`;

export const UserSurveyResultPageLayout = (
  props: PropsWithChildren<UserSurveyResultPageLayoutProps>,
) => (
  <DefaultPageLayout navbar={props.navbar} footer={props.footer}>
    {props.breadcrumbs ? (
      <DesktopBreadCrumbsContainer>
        <Spacer height={32} />
        {props.breadcrumbs}
        <Spacer height={64} />
      </DesktopBreadCrumbsContainer>
    ) : (
      <Spacer height={64} />
    )}

    <MobileSpacer />

    <DefaultContentContainer>
      <MobileHeader>
        {props.header ?? (
          <TextSkeleton height={32} width={300} animationDelay={0} />
        )}
      </MobileHeader>
      <DesktopHeader>
        {props.header ?? (
          <TextSkeleton height={56} width={300} animationDelay={0} />
        )}
      </DesktopHeader>
      <Spacer height={16} />
      {props.tabs}
      <Spacer height={24} />
      {props.children}
    </DefaultContentContainer>

    <Spacer height={56} />
  </DefaultPageLayout>
);
