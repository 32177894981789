import styled from '@emotion/styled';
import { brandColors } from '../constants';
import {
  ServiceSearchFilter,
  ServiceSearchFilterProps,
} from './ServiceSearchFilter';

const StyledFilter = styled(ServiceSearchFilter)`
  input {
    border: 1px solid ${brandColors.laasPurple60};
    border-radius: 2px;
    box-shadow: none;
  }
`;

export type SearchFilterProps = ServiceSearchFilterProps;

export const SearchFilter = (props: SearchFilterProps) => (
  <StyledFilter {...props} />
);
