export const Trend = () => (
  <svg
    width="50"
    height="39"
    viewBox="0 0 50 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3155_1887)">
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M49.0001 38.0493H1.00009C0.73487 38.0493 0.480469 37.9439 0.292933 37.7563C0.105397 37.5688 8.64245e-05 37.3145 8.64245e-05 37.0493C-0.00338491 36.7734 0.0978288 36.5064 0.283412 36.3023C0.468994 36.0982 0.725138 35.972 1.00009 35.9492H49.0001C49.2653 35.9492 49.5196 36.0546 49.7072 36.2421C49.8947 36.4297 50.0001 36.684 50.0001 36.9492C50.0036 37.2251 49.9023 37.492 49.7167 37.6962C49.5311 37.9003 49.275 38.0265 49.0001 38.0493Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M5.00002 25.9688C4.01111 25.9688 3.04437 25.6755 2.22212 25.1261C1.39988 24.5767 0.75901 23.7958 0.380572 22.8821C0.00213388 21.9685 -0.0968389 20.9632 0.0960872 19.9933C0.289013 19.0234 0.765172 18.1325 1.46443 17.4332C2.1637 16.734 3.05465 16.2577 4.02455 16.0648C4.99446 15.8719 5.99978 15.9709 6.91341 16.3494C7.82704 16.7278 8.60796 17.3687 9.15737 18.1909C9.70677 19.0132 10 19.9798 10 20.9688C10 22.2948 9.47322 23.5666 8.53554 24.5043C7.59786 25.442 6.3261 25.9688 5.00002 25.9688ZM5.00002 17.9688C4.40667 17.9688 3.82662 18.1447 3.33327 18.4744C2.83992 18.804 2.45541 19.2725 2.22835 19.8207C2.00129 20.3689 1.94188 20.9721 2.05764 21.554C2.17339 22.136 2.45912 22.6705 2.87868 23.0901C3.29824 23.5096 3.83275 23.7954 4.41469 23.9111C4.99663 24.0269 5.59985 23.9675 6.14803 23.7404C6.69621 23.5134 7.16476 23.1288 7.4944 22.6354C7.82405 22.1421 8.00002 21.5621 8.00002 20.9688C8.00002 20.1731 7.6839 19.41 7.12129 18.8474C6.55869 18.2848 5.79567 17.9688 5.00002 17.9688Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M29 25.9688C28.0111 25.9688 27.0444 25.6755 26.2221 25.1261C25.3999 24.5767 24.759 23.7958 24.3806 22.8821C24.0021 21.9685 23.9032 20.9632 24.0961 19.9933C24.289 19.0234 24.7652 18.1325 25.4644 17.4332C26.1637 16.734 27.0546 16.2577 28.0246 16.0648C28.9945 15.8719 29.9998 15.9709 30.9134 16.3494C31.827 16.7278 32.608 17.3687 33.1574 18.1909C33.7068 19.0132 34 19.9798 34 20.9688C34 22.2948 33.4732 23.5666 32.5355 24.5043C31.5979 25.442 30.3261 25.9688 29 25.9688ZM29 17.9688C28.4067 17.9688 27.8266 18.1447 27.3333 18.4744C26.8399 18.804 26.4554 19.2725 26.2284 19.8207C26.0013 20.3689 25.9419 20.9721 26.0576 21.554C26.1734 22.136 26.4591 22.6705 26.8787 23.0901C27.2982 23.5096 27.8327 23.7954 28.4147 23.9111C28.9966 24.0269 29.5998 23.9675 30.148 23.7404C30.6962 23.5134 31.1648 23.1288 31.4944 22.6354C31.824 22.1421 32 21.5621 32 20.9688C32 20.1731 31.6839 19.41 31.1213 18.8474C30.5587 18.2848 29.7957 17.9688 29 17.9688Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M45 9.96875C44.0111 9.96875 43.0444 9.67551 42.2221 9.1261C41.3999 8.57669 40.759 7.79577 40.3806 6.88214C40.0021 5.96851 39.9032 4.96319 40.0961 3.99329C40.289 3.02338 40.7652 2.13249 41.4644 1.43323C42.1637 0.733967 43.0546 0.257748 44.0246 0.0648214C44.9945 -0.128105 45.9998 -0.0290709 46.9134 0.349367C47.827 0.727805 48.608 1.36867 49.1574 2.19092C49.7068 3.01317 50 3.97985 50 4.96875C50 6.29483 49.4732 7.56659 48.5355 8.50427C47.5979 9.44196 46.3261 9.96875 45 9.96875ZM45 1.96875C44.4067 1.96875 43.8266 2.14472 43.3333 2.47437C42.8399 2.80401 42.4554 3.2725 42.2284 3.82068C42.0013 4.36886 41.9419 4.97207 42.0576 5.55402C42.1734 6.13596 42.4591 6.67053 42.8787 7.09009C43.2982 7.50965 43.8327 7.79538 44.4147 7.91113C44.9966 8.02689 45.5998 7.96748 46.148 7.74042C46.6962 7.51336 47.1648 7.12879 47.4944 6.63544C47.824 6.14209 48 5.5621 48 4.96875C48 4.1731 47.6839 3.41002 47.1213 2.84741C46.5587 2.28481 45.7957 1.96875 45 1.96875Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M17 13.9688C16.0111 13.9688 15.0444 13.6755 14.2221 13.1261C13.3999 12.5767 12.759 11.7958 12.3806 10.8821C12.0021 9.96851 11.9032 8.96319 12.0961 7.99329C12.289 7.02338 12.7652 6.13249 13.4644 5.43323C14.1637 4.73397 15.0546 4.25775 16.0246 4.06482C16.9945 3.8719 17.9998 3.97093 18.9134 4.34937C19.827 4.72781 20.608 5.36867 21.1574 6.19092C21.7068 7.01317 22 7.97985 22 8.96875C22 10.2948 21.4732 11.5666 20.5355 12.5043C19.5979 13.442 18.3261 13.9688 17 13.9688ZM17 5.96875C16.4067 5.96875 15.8266 6.14472 15.3333 6.47437C14.8399 6.80401 14.4554 7.2725 14.2284 7.82068C14.0013 8.36886 13.9419 8.97207 14.0576 9.55402C14.1734 10.136 14.4591 10.6705 14.8787 11.0901C15.2982 11.5096 15.8327 11.7954 16.4147 11.9111C16.9966 12.0269 17.5998 11.9675 18.148 11.7404C18.6962 11.5134 19.1648 11.1288 19.4944 10.6354C19.824 10.1421 20 9.5621 20 8.96875C20 8.1731 19.6839 7.41002 19.1213 6.84741C18.5587 6.28481 17.7957 5.96875 17 5.96875Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M7.85999 19.2069C7.59765 19.2058 7.34623 19.1016 7.15997 18.9169L7.08997 18.8469C6.99624 18.7539 6.92186 18.6433 6.87109 18.5214C6.82033 18.3996 6.79419 18.2689 6.79419 18.1369C6.79419 18.0048 6.82033 17.8741 6.87109 17.7523C6.92186 17.6304 6.99624 17.5199 7.08997 17.4269L13.43 11.0869C13.5234 10.9942 13.6342 10.9209 13.756 10.8711C13.8779 10.8213 14.0083 10.7961 14.14 10.7969C14.2716 10.7961 14.402 10.8213 14.5239 10.8711C14.6457 10.9209 14.7565 10.9942 14.85 11.0869C15.0446 11.2806 15.1589 11.5407 15.17 11.8151C15.1811 12.0895 15.0882 12.358 14.91 12.5669L8.56995 18.9669C8.37334 19.1359 8.11882 19.2219 7.85999 19.2069Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M31.86 19.2069C31.5976 19.2058 31.3462 19.1016 31.16 18.9169L31.09 18.8469C30.9962 18.7539 30.9219 18.6433 30.8711 18.5214C30.8203 18.3996 30.7942 18.2689 30.7942 18.1369C30.7942 18.0048 30.8203 17.8741 30.8711 17.7523C30.9219 17.6304 30.9962 17.5199 31.09 17.4269L41.43 7.08687C41.5234 6.99419 41.6342 6.92088 41.756 6.87111C41.8779 6.82135 42.0083 6.79613 42.14 6.79689C42.2716 6.79613 42.402 6.82135 42.5239 6.87111C42.6457 6.92088 42.7565 6.99419 42.85 7.08687C43.0446 7.28064 43.1589 7.54075 43.17 7.81514C43.1811 8.08953 43.0882 8.35797 42.91 8.56685L32.5699 18.9669C32.3733 19.1359 32.1188 19.2219 31.86 19.2069Z"
          fill="#9F8BB1"
        />
        <path
          opacity="0.8"
          d="M26.1601 19.1692C25.8907 19.1821 25.6265 19.0928 25.4201 18.9192L19.0801 12.5692C18.8938 12.3818 18.7893 12.1284 18.7893 11.8642C18.7893 11.6 18.8938 11.3466 19.0801 11.1592C19.2746 10.9573 19.5399 10.839 19.8201 10.8292C20.0911 10.817 20.3563 10.9102 20.5601 11.0892L26.9001 17.4292C27.0863 17.6166 27.1909 17.87 27.1909 18.1342C27.1909 18.3984 27.0863 18.6518 26.9001 18.8392C26.7007 19.04 26.4328 19.1579 26.1501 19.1692H26.1601Z"
          fill="#9F8BB1"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3155_1887">
        <rect width="50" height="38.05" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
