import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren } from 'react';
import { useTransition, animated, SpringValue } from '@react-spring/web';
import * as easings from 'd3-ease';
import color from 'color';
import { animationDurationNumbers, mediaQuery, zIndex } from '../constants';
import { ModalPortal } from './ModalPortal';
import { useScrollLock } from '../useScrollLock';
const Backdrop = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  z-index: ${zIndex.modal};
`;

const DrawerPanelContainer = styled(animated.div)`
  position: relative;
  display: flex;
  flex-direction: column;

  background: white;

  width: 488px;

  @media screen and ${mediaQuery.small} {
    width: 100%;
  }
`;

type DrawerPanelProps = PropsWithChildren<{
  className?: string;
}>;
const DrawerPanel = (
  props: DrawerPanelProps & {
    transform: SpringValue<string>;
  },
) => (
  <DrawerPanelContainer
    className={props.className}
    style={{ transform: props.transform }}
    ref={useScrollLock({ isActive: true })}
    onClick={(e) => e.stopPropagation()}
  >
    {props.children}
  </DrawerPanelContainer>
);

export interface DrawerProps extends DrawerPanelProps {
  isOpen: boolean;
  onClose: () => any;
  backdropStyles?: CSSProperties;
}

export const Drawer = ({
  isOpen,
  onClose,
  backdropStyles = {},
  ...drawerPanelProps
}: DrawerProps) => {
  const enterColor = color('#000').alpha(0.25).toString();
  const exitColor = color('#000').alpha(0).toString();

  const transitions = useTransition(isOpen, {
    from: { background: exitColor, transform: 'translateX(100%)' },
    enter: { background: enterColor, transform: 'translateX(0%)' },
    leave: { background: exitColor, transform: 'translateX(100%)' },
    reverse: isOpen,
    config: {
      duration: animationDurationNumbers.long,
      easing: easings.easeExpInOut,
    },
  });

  return transitions(
    ({ background, transform }, show) =>
      show && (
        <ModalPortal>
          <Backdrop onClick={onClose} style={{ background, ...backdropStyles }}>
            <DrawerPanel {...drawerPanelProps} transform={transform} />
          </Backdrop>
        </ModalPortal>
      ),
  );
};
