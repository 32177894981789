import { brandColors } from '../../constants';

export const textColors = {
  primary: brandColors.laasPurple,
  primaryLight: brandColors.laasPurple80,
  secondary: brandColors.coalGrey,
  primaryButton: brandColors.white,
  disabled: brandColors.coalGrey50,
  error: brandColors.systemError,
  linkHover: brandColors.indianSummer,
};
