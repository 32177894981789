import { SVGProps } from 'react';

export const TeamsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_832_2786)">
      <path
        d="M33.4806 17.9351H45.879C47.0505 17.9351 48 18.9523 48 20.2069V32.3035C48 36.9147 44.5101 40.6531 40.2051 40.6531H40.1681C35.8631 40.6537 32.3726 36.9161 32.3721 32.3047V19.1226C32.372 18.9667 32.4007 18.8122 32.4564 18.6682C32.5121 18.5241 32.5937 18.3932 32.6967 18.2829C32.7996 18.1726 32.9218 18.0851 33.0563 18.0255C33.1908 17.9658 33.335 17.9351 33.4806 17.9351Z"
        fill="#5059C9"
      />
      <path
        d="M41.8605 15.544C44.6348 15.544 46.8838 13.135 46.8838 10.1633C46.8838 7.1917 44.6348 4.78271 41.8605 4.78271C39.0862 4.78271 36.8372 7.1917 36.8372 10.1633C36.8372 13.135 39.0862 15.544 41.8605 15.544Z"
        fill="#5059C9"
      />
      <path
        d="M26.2324 15.544C30.2397 15.544 33.4883 12.0643 33.4883 7.77198C33.4883 3.47964 30.2397 0 26.2324 0C22.2251 0 18.9766 3.47964 18.9766 7.77198C18.9766 12.0643 22.2251 15.544 26.2324 15.544Z"
        fill="#7B83EB"
      />
      <path
        d="M35.9073 17.9351H15.4415C14.2841 17.9658 13.3685 18.9947 13.3953 20.2344V34.0313C13.2337 41.4711 18.7288 47.6451 25.6743 47.8272C32.6199 47.6451 38.1149 41.4711 37.9535 34.0313V20.2344C37.9803 18.9947 37.0648 17.9658 35.9073 17.9351Z"
        fill="#7B83EB"
      />
      <path
        opacity="0.1"
        d="M26.7907 17.9351V37.2692C26.788 37.7029 26.6658 38.126 26.4394 38.4855C26.213 38.8451 25.8925 39.1249 25.5181 39.29C25.2744 39.4005 25.0124 39.4573 24.7479 39.4573H14.3774C14.2323 39.0627 14.0984 38.6681 13.9867 38.2618C13.5959 36.8897 13.3966 35.4634 13.3951 34.0289V20.2308C13.3683 18.9931 14.2824 17.9658 15.4379 17.9353H26.7905L26.7907 17.9351Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M25.6744 17.9351V38.465C25.6744 38.7484 25.6213 39.0289 25.5182 39.29C25.3641 39.691 25.1028 40.0343 24.7671 40.2768C24.4315 40.5193 24.0365 40.6502 23.6316 40.6531H14.9025C14.7128 40.2585 14.5341 39.8638 14.3779 39.4573C14.2279 39.0665 14.0974 38.6674 13.9872 38.2617C13.5964 36.8897 13.3971 35.4634 13.3956 34.0289V20.2308C13.3688 18.9931 14.2828 17.9658 15.4384 17.9353H25.6746L25.6744 17.9351Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M25.6743 17.9351V36.0736C25.6659 37.2782 24.7563 38.2527 23.6315 38.2617H13.9869C13.5962 36.8897 13.3968 35.4634 13.3954 34.0289V20.2308C13.3685 18.9931 14.2826 17.9658 15.4382 17.9353L25.6743 17.9351Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M24.5582 17.9351V36.0736C24.5495 37.2782 23.64 38.2527 22.5153 38.2617H13.9869C13.5962 36.8897 13.3968 35.4634 13.3954 34.0289V20.2308C13.3685 18.9931 14.2826 17.9658 15.4382 17.9353L24.5582 17.9351Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M26.7908 11.7535V15.5198C26.601 15.5319 26.4224 15.5439 26.2326 15.5439C26.0429 15.5439 25.8642 15.5319 25.6744 15.5198C25.2977 15.4931 24.924 15.4291 24.5582 15.3286C23.4438 15.046 22.4077 14.4848 21.5346 13.6909C20.6616 12.8971 19.9763 11.8931 19.5349 10.7612C19.3809 10.3756 19.2613 9.97521 19.1777 9.56543H24.748C25.8743 9.57005 26.7865 10.5469 26.7908 11.7535Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M25.6745 12.9491V15.5199C25.2977 15.4931 24.924 15.4291 24.5583 15.3287C23.4439 15.046 22.4078 14.4847 21.5348 13.6909C20.6617 12.897 19.9765 11.8931 19.5352 10.7612H23.6317C24.7582 10.7656 25.6702 11.7427 25.6745 12.9491Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M25.6745 12.9491V15.5199C25.2977 15.4931 24.924 15.4291 24.5583 15.3287C23.4439 15.046 22.4078 14.4847 21.5348 13.6909C20.6617 12.897 19.9765 11.8931 19.5352 10.7612H23.6317C24.7582 10.7656 25.6702 11.7427 25.6745 12.9491Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M24.5582 12.9493V15.3289C23.4438 15.0462 22.4077 14.4849 21.5346 13.691C20.6615 12.8972 19.9763 11.8932 19.5349 10.7612H22.5154C23.6419 10.7658 24.5539 11.7429 24.5582 12.9493Z"
        fill="black"
      />
      <path
        d="M2.04619 10.7609H22.5118C23.6421 10.7609 24.5582 11.7422 24.5582 12.9527V34.8742C24.5582 36.0847 23.6421 37.0659 22.512 37.0659H2.04619C0.916125 37.0659 0 36.0847 0 34.8742V12.9529C0 11.742 0.916125 10.7609 2.04619 10.7609Z"
        fill="url(#paint0_linear_832_2786)"
      />
      <path
        d="M17.6641 19.1044H13.5729V31.0373H10.9664V19.1044H6.89429V16.7896H17.6641V19.1044Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_832_2786"
        x1="4.26625"
        y1="9.04827"
        x2="21.9998"
        y2="37.7222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5A62C3" />
        <stop offset="0.5" stop-color="#4D55BD" />
        <stop offset="1" stop-color="#3940AB" />
      </linearGradient>
      <clipPath id="clip0_832_2786">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
