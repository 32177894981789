import styled from '@emotion/styled';
import { mediaQuery } from '@laasco/ui-lib/constants';
import React, { PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import { MobileTab, MobileTabs } from './MobileTabs';
import { Tab, TabProps } from './Tab';
import { Tabs, TabsProps } from './Tabs';

const DesktopTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and ${mediaQuery.medium} {
    gap: 16px;
  }
  @media screen and ${mediaQuery.small} {
    display: none;
  }
`;

const MobileTabsContainer = styled.div`
  display: none;
  @media screen and ${mediaQuery.small} {
    display: block;
  }
`;

const DesktopTabContainer = styled.div`
  display: block;
  @media screen and ${mediaQuery.small} {
    display: none;
  }
`;

const MobileTabContainer = styled.div`
  display: none;
  @media screen and ${mediaQuery.small} {
    display: block;
  }
`;

export interface ResponsiveTabProps extends Omit<TabProps, 'onClick'> {
  label: ReactNode;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
  defaultOpen?: boolean;
}

export const ResponsiveTab: React.FC<PropsWithChildren<ResponsiveTabProps>> = ({
  children,
  label,
  setActiveTab,
  ...props
}) => {
  return (
    <>
      <DesktopTabContainer>
        <Tab {...props} onClick={() => setActiveTab!(props.tabIndex!)}>
          {label}
        </Tab>
      </DesktopTabContainer>
      <MobileTabContainer>
        <MobileTab
          {...props}
          onClick={() => setActiveTab!(props.tabIndex!)}
          title={label}
        >
          {children}
        </MobileTab>
      </MobileTabContainer>
    </>
  );
};

export interface ResponsiveTabsProps
  extends Omit<TabsProps, 'activeTab' | 'setActivetab'> {}

export const ResponsiveTabs: React.FC<
  PropsWithChildren<ResponsiveTabsProps>
> = ({ children, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);

  const clonedChildren = useMemo(
    () =>
      React.Children.toArray(children)
        .filter((child) => React.isValidElement(child))
        .map((child, i) => {
          if (React.isValidElement(child)) {
            if (child.props.defaultOpen) {
              setActiveTab(i);
            }
            return React.cloneElement(child, {
              ...child.props,
              setActiveTab: setActiveTab,
              tabIndex: i,
            });
          }
          return child;
        }),
    [children],
  );

  const tabContent = useMemo(() => {
    const activeTabElement = React.Children.toArray(clonedChildren)[activeTab];
    return React.isValidElement(activeTabElement)
      ? (activeTabElement.props as ResponsiveTabProps).children
      : undefined;
  }, [clonedChildren, activeTab]);

  return (
    <>
      <DesktopTabsContainer>
        <Tabs {...props} activeTab={activeTab} setActiveTab={setActiveTab}>
          {clonedChildren}
        </Tabs>
        {tabContent}
      </DesktopTabsContainer>
      <MobileTabsContainer>
        <MobileTabs
          {...props}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          {clonedChildren}
        </MobileTabs>
      </MobileTabsContainer>
    </>
  );
};
