import styled from '@emotion/styled';
import { useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';
import React, {
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { RadialButton } from './RadialButton';
import {
  animationDuration,
  brandColors,
  mediaQuery,
  menuHeight,
} from '../constants';

const floatingButtonWidthPx = 56;
const floatingButtonMWidthPx = 52;
const floatingButtonSWidthPx = 38;

const FloatingButtonContainer = styled(animated.div)`
  position: fixed;
  z-index: 100;

  top: calc(${menuHeight.desktop}px + 24px);

  @media screen and ${mediaQuery.medium} {
    top: calc(${menuHeight.mobile}px + 24px);
  }

  @media screen and ${mediaQuery.small} {
    top: calc(${menuHeight.mobile}px + 8px);
  }
`;

const FloatingRadialButton = styled(RadialButton)`
  width: ${floatingButtonWidthPx}px;
  height: ${floatingButtonWidthPx}px;

  background: white;
  border: 1px solid ${brandColors.coalGrey20};
  box-shadow: 5px 4px 12px rgba(77, 42, 111, 0.1);

  color: ${brandColors.laasPurple};

  @media screen and ${mediaQuery.medium} {
    width: ${floatingButtonMWidthPx}px;
    height: ${floatingButtonMWidthPx}px;

    background: ${brandColors.laasPurple};
    color: white;
  }

  @media screen and ${mediaQuery.small} {
    width: ${floatingButtonSWidthPx}px;
    height: ${floatingButtonSWidthPx}px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const useIsIntersectingWithViewport = (ref: RefObject<HTMLButtonElement>) => {
  const [isIntersecting, setIsIntersecting] = useState(true);
  const isMedium = useMediaQuery({ query: mediaQuery.medium });

  useLayoutEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        (entries) => setIsIntersecting(entries[0].isIntersecting),
        {
          threshold: 0.5,
          rootMargin: `${-(isMedium
            ? menuHeight.mobile
            : menuHeight.desktop)}px 0px 0px 0px`,
        },
      );

      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref, isMedium]);

  return isIntersecting;
};

const useFloatingButtonPositions = (pageMarginPx: number) => {
  const isMedium = useMediaQuery({ query: mediaQuery.medium });
  const isSmall = useMediaQuery({ query: mediaQuery.small });

  const buttonWidthPx = isSmall
    ? floatingButtonSWidthPx
    : isMedium
    ? floatingButtonMWidthPx
    : floatingButtonWidthPx;

  const clientWidth =
    typeof window !== 'undefined' ? document.body.clientWidth : 0;

  const leftPx =
    pageMarginPx < buttonWidthPx + 16
      ? clientWidth - buttonWidthPx - 8
      : clientWidth - pageMarginPx + 8;

  return {
    initialPx: leftPx + buttonWidthPx + 64,
    finalPx: leftPx,
  };
};

const useFloatingButtonTransitions = (
  staticButtonRef: RefObject<HTMLButtonElement>,
  pageMarginPx: number,
) => {
  const isIntersecting = useIsIntersectingWithViewport(staticButtonRef);
  const { initialPx, finalPx } = useFloatingButtonPositions(pageMarginPx);
  const show = pageMarginPx > 0 && !isIntersecting;

  return useTransition(show, {
    from: { opacity: 0, left: `${initialPx}px` },
    enter: { opacity: 1, left: `${finalPx}px` },
    leave: { opacity: 0, left: `${initialPx}px` },
    reverse: show,
    duration: animationDuration.short,
  });
};

export interface FloatableButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon: ReactNode;
  staticButton: ReactElement<any>;
  pageMarginPx?: number;
}

export const FloatableButton = ({
  pageMarginPx = 120,
  ...props
}: FloatableButtonProps) => {
  const staticButtonRef = useRef<HTMLButtonElement>(null);

  const transitions = useFloatingButtonTransitions(
    staticButtonRef,
    pageMarginPx,
  );

  return (
    <>
      {React.cloneElement(props.staticButton, {
        ref: staticButtonRef,
        onClick: props.onClick,
      })}

      {transitions(
        (styles, show) =>
          show && (
            <FloatingButtonContainer style={styles}>
              <FloatingRadialButton icon={props.icon} onClick={props.onClick} />
            </FloatingButtonContainer>
          ),
      )}
    </>
  );
};
