import { SVGProps } from 'react';
import { brandColors } from '../constants';

export const Lock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={brandColors.coalGrey20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18 8.00098H17V6.00098C17 3.24098 14.76 1.00098 12 1.00098C9.24 1.00098 7 3.24098 7 6.00098V8.00098H6C4.9 8.00098 4 8.90098 4 10.001V20.001C4 21.101 4.9 22.001 6 22.001H18C19.1 22.001 20 21.101 20 20.001V10.001C20 8.90098 19.1 8.00098 18 8.00098ZM9 6.00098C9 4.34098 10.34 3.00098 12 3.00098C13.66 3.00098 15 4.34098 15 6.00098V8.00098H9V6.00098ZM18 20.001H6V10.001H18V20.001ZM12 17.001C13.1 17.001 14 16.101 14 15.001C14 13.901 13.1 13.001 12 13.001C10.9 13.001 10 13.901 10 15.001C10 16.101 10.9 17.001 12 17.001Z" />
  </svg>
);
