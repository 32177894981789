export const MicrosoftWindow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4285 1.14258H1.14282V11.4283H11.4285V1.14258Z"
      fill="#F25022"
    />
    <path d="M11.4285 12.5713H1.14282V22.857H11.4285V12.5713Z" fill="#00A4EF" />
    <path
      d="M22.8571 1.14258H12.5714V11.4283H22.8571V1.14258Z"
      fill="#7FBA00"
    />
    <path d="M22.8571 12.5713H12.5714V22.857H22.8571V12.5713Z" fill="#FFB900" />
  </svg>
);
