import { KeyboardEventHandler, useCallback, useEffect } from 'react';

export interface UseKeyboardSelectProps {
  selectedIndex?: number;
  setSelectedIndex: (i: number | undefined) => any;
  onSelectIndex: (i: number) => any;
  setIsOpen: (v: boolean) => any;
  itemCount: number;
  isOpen: boolean;
}

export const useKeyBoardSelect = ({
  selectedIndex,
  onSelectIndex,
  setSelectedIndex,
  itemCount,
  isOpen,
  setIsOpen,
}: UseKeyboardSelectProps) => {
  const onKeyDown: KeyboardEventHandler<any> = useCallback(
    (ev) => {
      switch (ev.key) {
        case 'ArrowDown':
          if (!isOpen) {
            setIsOpen(true);
          }
          if (selectedIndex == null) {
            setSelectedIndex(0);
          } else {
            setSelectedIndex(Math.min(itemCount - 1, selectedIndex + 1));
          }
          return;
        case 'ArrowUp':
          if (!isOpen) {
            setIsOpen(true);
          }
          if (selectedIndex == null) {
            setSelectedIndex(itemCount);
          } else {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
          }
          return;
        case ' ':
        case 'Enter':
          if (selectedIndex != null) {
            onSelectIndex(selectedIndex);
          }
          return;
        case 'Home':
          setSelectedIndex(0);
          return;
        case 'End':
          setSelectedIndex(itemCount - 1);
          return;
      }
    },
    [
      isOpen,
      selectedIndex,
      setSelectedIndex,
      itemCount,
      onSelectIndex,
      setIsOpen,
    ],
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedIndex(0);
    }
    return () => {
      setSelectedIndex(undefined);
    };
  }, [isOpen, setSelectedIndex]);
  return { onKeyDown };
};
