import { SVGProps } from 'react';

export const SlackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_832_4316)">
      <path
        d="M10.0952 30.2476C10.0952 33.0286 7.84765 35.2764 5.06665 35.2764C2.28565 35.2764 0.0380859 33.0286 0.0380859 30.2476C0.0380859 27.4668 2.28565 25.2192 5.06684 25.2192H10.095V30.2478L10.0952 30.2476ZM12.6096 30.2476C12.6096 27.4668 14.8572 25.2192 17.6382 25.2192C20.4192 25.2192 22.6667 27.4668 22.6667 30.2478V42.8191C22.6667 45.6001 20.4192 47.8479 17.638 47.8479C14.8573 47.8479 12.6096 45.6001 12.6096 42.8191V30.2476Z"
        fill="#E01E5A"
      />
      <path
        d="M17.6381 10.0571C14.8571 10.0571 12.6094 7.80956 12.6094 5.02856C12.6094 2.24756 14.8573 0 17.6381 0C20.4189 0 22.6667 2.24756 22.6667 5.02856V10.0573H17.6379L17.6381 10.0571ZM17.6381 12.6096C20.4191 12.6096 22.6667 14.8571 22.6667 17.6381C22.6667 20.4191 20.4191 22.6667 17.6379 22.6667H5.02875C2.24756 22.6667 0 20.4191 0 17.6379C0 14.8573 2.24756 12.6096 5.02856 12.6096H17.6381Z"
        fill="#36C5F0"
      />
      <path
        d="M37.7906 17.6381C37.7906 14.8571 40.0382 12.6094 42.819 12.6094C45.6 12.6094 47.8477 14.8571 47.8477 17.6381C47.8477 20.4191 45.6 22.6667 42.819 22.6667H37.7906V17.6381ZM35.2762 17.6381C35.2762 20.4191 33.0285 22.6667 30.2475 22.6667C27.4667 22.6667 25.2191 20.4191 25.2191 17.6379V5.02875C25.2191 2.24756 27.4667 0 30.2475 0C33.0283 0 35.2761 2.24756 35.2761 5.02856V17.6379L35.2762 17.6381Z"
        fill="#2EB67D"
      />
      <path
        d="M30.2475 37.7907C33.0285 37.7907 35.2762 40.0383 35.2762 42.8191C35.2762 45.6001 33.0285 47.8479 30.2475 47.8479C27.4667 47.8479 25.2191 45.6001 25.2191 42.8191V37.7907H30.2475ZM30.2475 35.2764C27.4667 35.2764 25.2191 33.0286 25.2191 30.2476C25.2191 27.4668 27.4667 25.2192 30.2477 25.2192H42.8571C45.6381 25.2192 47.8858 27.4668 47.8858 30.2478C47.8858 33.0286 45.6381 35.2764 42.8571 35.2764H30.2475Z"
        fill="#ECB22E"
      />
    </g>
    <defs>
      <clipPath id="clip0_832_4316">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
