import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { PlainButton } from '../buttons';
import { brandColors } from '../constants';
import { CloseIcon } from '../icons';
import { HeadingS } from '../typography';
import { Drawer } from './Drawer';

const StyledDrawer = styled(Drawer)`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid rgba(77, 42, 111, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  background-color: ${brandColors.laasPurple5};
`;

const Heading = styled(HeadingS)`
  flex-grow: 1;
`;

const FilterContentWrapper = styled.div`
  max-width: 100%;
  overflow: auto;
  padding: 16px 24px;
`;

const CloseButton = styled(PlainButton)``;

export type FilterPanelModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  closeButtonLabel: ReactNode;
  headerText: ReactNode;
  className?: string;
}>;

export const FilterPanelModal = (props: FilterPanelModalProps) => {
  return (
    <StyledDrawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={props.className}
    >
      <HeaderWrapper>
        <Heading>{props.headerText}</Heading>
        <CloseButton onClick={props.onClose}>
          <CloseIcon />
        </CloseButton>
      </HeaderWrapper>
      <FilterContentWrapper>{props.children}</FilterContentWrapper>
    </StyledDrawer>
  );
};
