import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { animationDuration, brandColors, zIndex } from '../constants';
import { DropdownExpansionChevron } from '../icons/DropdownExpansionChevron';
import { useKeyBoardSelect } from './useKeyboardSelect';
import { useFixedFloatingTooltip } from '../useFixedFloatingTooltip';
import usePortal from 'react-useportal';
import { useRenderClientSide } from '../useRenderClientSide';
import { body1Styles, heading5Styles } from '../typography';

const Container = styled.div<{ hasFocus: boolean }>`
  position: relative;

  ${heading5Styles}
  font-weight: 600;

  display: inline-flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  width: auto;
  outline: none;
  padding: 8px 8px;
  border: ${({ hasFocus }) =>
    hasFocus
      ? `2px solid ${brandColors.laasPurple60}`
      : '2px solid transparent'};

  :hover {
    border: 2px solid ${brandColors.laasPurple60};
  }
`;

const OptionsContainer = styled.div<{
  isOpen: boolean;
}>`
  position: absolute;
  cursor: pointer;
  left: 0;
  min-width: 180px;
  background: #fff;
  border: 1px solid ${brandColors.laasPurple60};

  > * {
    border-bottom: 1px solid ${brandColors.laasPurple60};
  }
  > *:last-child {
    border: none;
  }

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity ${animationDuration.short} ease-in-out,
    transform ${animationDuration.short} ease-in-out;
  transform: translateY(${({ isOpen }) => (isOpen ? 0 : -24)}px);
  z-index: ${zIndex.tooltip};
`;

const Option = styled.div<{ isSelected: boolean; isFocused: boolean }>`
  padding: ${(p) => (p.isFocused ? '16px 15px' : '16px')};
  transition: background-color ${animationDuration.short} ease-in-out;
  :hover {
    background-color: ${brandColors.laasPurple20};
  }
  ${body1Styles}
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 'normal')};
`;

export interface LiteSelectItem {
  value: string;
  label: React.ReactNode;
}

export interface LiteSelectProps {
  id?: string;
  items: LiteSelectItem[];
  value: string;
  onChange: (value: string) => any;
  tabIndex?: number;
  renderSelected?: (item: LiteSelectItem) => React.ReactNode;
  renderOption?: (item: LiteSelectItem) => React.ReactNode;
  placement?: 'bottom-end' | 'bottom-start';
}

export const LiteSelect: React.FunctionComponent<
  React.PropsWithChildren<LiteSelectProps>
> = ({
  items,
  onChange,
  value,
  tabIndex = 0,
  renderSelected = (i) => i.label,
  renderOption = (i) => i.label,
  id,
  placement = 'bottom-end',
}) => {
  const itemsById = React.useMemo(
    () => new Map(items.map((i) => [i.value, i])),
    [items],
  );
  const selectedItem = itemsById.get(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasFocus, setHasFocus] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<undefined | number>(
    undefined,
  );

  const { reference, floating, y, x, strategy, update } =
    useFixedFloatingTooltip({
      value,
      options: {
        placement,
      },
    });

  const { onKeyDown } = useKeyBoardSelect({
    selectedIndex,
    setSelectedIndex,
    isOpen,
    onSelectIndex: (i: number) => {
      onChange(items[i]?.value);
      setIsOpen(false);
    },
    setIsOpen,
    itemCount: items.length,
  });

  useEffect(() => update(), [isOpen, update, value]);

  const isClientSide = useRenderClientSide();
  const { Portal } = usePortal();

  return (
    <Container
      id={id}
      ref={reference}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={(e) => {
        setIsOpen(!isOpen);
        e.stopPropagation();
      }}
      onFocus={() => {
        setHasFocus(true);
      }}
      onBlur={() => {
        setHasFocus(false);
        setIsOpen(false);
        setSelectedIndex(undefined);
      }}
      hasFocus={hasFocus}
    >
      {selectedItem ? renderSelected(selectedItem) : null}
      <DropdownExpansionChevron isOpen={isOpen} />
      {isClientSide && (
        <Portal>
          <OptionsContainer
            role="combobox"
            aria-expanded={isOpen}
            ref={floating}
            style={{
              top: (y || 0) - (isOpen ? 0 : 24) - 1,
              left: x || 0,
              position: strategy,
              pointerEvents: isOpen ? 'auto' : 'none',
            }}
            isOpen={isOpen}
          >
            {items.map((item, idx: number) => (
              <Option
                role="option"
                aria-selected={selectedIndex === idx}
                key={item.value}
                isSelected={value === item.value}
                isFocused={selectedIndex === idx}
                style={
                  selectedIndex === idx
                    ? {
                        borderStyle: `solid`,
                        borderColor: brandColors.laasPurple60,
                        borderWidth: `1px 1px ${
                          idx === items.length - 1 ? 1 : 2
                        }px 1px`,
                      }
                    : {}
                }
                onMouseDown={() => {
                  onChange(item.value);
                }}
              >
                {renderOption(item)}
              </Option>
            ))}
          </OptionsContainer>
        </Portal>
      )}
    </Container>
  );
};
