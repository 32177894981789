import { SVGProps } from 'react';

export const UnfoldLess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.9001 19.6496L7.8501 18.5996L12.0001 14.4496L16.1501 18.5996L15.1001 19.6496L12.0001 16.5496L8.9001 19.6496ZM12.0001 9.54961L7.8501 5.39961L8.9001 4.34961L12.0001 7.44961L15.1001 4.34961L16.1501 5.39961L12.0001 9.54961Z"
      fill="currentColor"
    />
  </svg>
);
