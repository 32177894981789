import React from 'react';

export function PlusIcon({
  fill = 'white',
  ...props
}: React.SVGAttributes<any>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 8.14941H8.25V14.1494H6.25V8.14941H0.25V6.14941H6.25V0.149414H8.25V6.14941H14.25V8.14941Z"
        fill={fill}
      />
    </svg>
  );
}

export function PlusIconCurrentColor(props: React.SVGAttributes<any>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.25 8.14941H8.25V14.1494H6.25V8.14941H0.25V6.14941H6.25V0.149414H8.25V6.14941H14.25V8.14941Z" />
    </svg>
  );
}
