export const ThumbsUpIcon = () => (
  <svg
    width="38"
    height="34"
    viewBox="0 0 38 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9993 34.0003H28.9993C30.3827 34.0003 31.566 33.167 32.066 31.967L37.0993 20.217C37.2493 19.8337 37.3327 19.4337 37.3327 19.0003V15.667C37.3327 13.8337 35.8327 12.3337 33.9993 12.3337H23.4827L25.066 4.71699L25.116 4.18366C25.116 3.50033 24.8327 2.86699 24.3827 2.41699L22.616 0.666992L11.6327 11.6503C11.0327 12.2503 10.666 13.0837 10.666 14.0003V30.667C10.666 32.5003 12.166 34.0003 13.9993 34.0003ZM13.9993 14.0003L21.2327 6.76699L18.9993 15.667H33.9993V19.0003L28.9993 30.667H13.9993V14.0003ZM0.666016 14.0003H7.33268V34.0003H0.666016V14.0003Z"
      fill="white"
    />
  </svg>
);

export const ThumbsUpIcon24 = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 21H18C18.83 21 19.54 20.5 19.84 19.78L22.86 12.73C22.95 12.5 23 12.26 23 12V10C23 8.9 22.1 8 21 8H14.69L15.64 3.43L15.67 3.11C15.67 2.7 15.5 2.32 15.23 2.05L14.17 1L7.58 7.59C7.22 7.95 7 8.45 7 9V19C7 20.1 7.9 21 9 21ZM9 9L13.34 4.66L12 10H21V12L18 19H9V9ZM1 9H5V21H1V9Z"
      fill="white"
    />
  </svg>
);

export const ThumbsUpIcon22 = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 20.5H17C17.83 20.5 18.54 20 18.84 19.28L21.86 12.23C21.95 12 22 11.76 22 11.5V9.5C22 8.4 21.1 7.5 20 7.5H13.69L14.64 2.93L14.67 2.61C14.67 2.2 14.5 1.82 14.23 1.55L13.17 0.5L6.58 7.09C6.22 7.45 6 7.95 6 8.5V18.5C6 19.6 6.9 20.5 8 20.5ZM8 8.5L12.34 4.16L11 9.5H20V11.5L17 18.5H8V8.5ZM0 8.5H4V20.5H0V8.5Z"
      fill="currentColor"
    />
  </svg>
);
