import { useEffect } from 'react';

export const useOnViewportChange = (onViewportChange: () => void) => {
  useEffect(() => {
    const handler = () => {
      onViewportChange();
    };
    window.addEventListener('resize', handler);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('scroll', handler);
    };
  }, [onViewportChange]);
};
