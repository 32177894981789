import { SVGProps } from 'react';
import { brandColors } from '../constants';

export const BarChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={brandColors.laasPurple}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 9.2H8V19H5V9.2ZM10.6 5H13.4V19H10.6V5ZM16.2 13H19V19H16.2V13Z" />
  </svg>
);
