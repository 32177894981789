import { SVGProps } from 'react';

export const Desire = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M3.33398 35.0026V32.2249H18.6118V12.572C17.8896 12.322 17.2507 11.9192 16.6951 11.3637C16.1396 10.8081 15.7368 10.1692 15.4868 9.44702H9.30619L14.4451 21.7248C14.4266 23.0211 13.8803 24.123 12.8062 25.0304C11.7321 25.9378 10.4266 26.3915 8.88953 26.3915C7.3525 26.3915 6.04694 25.9378 4.97286 25.0304C3.8988 24.123 3.35251 23.0211 3.33398 21.7248L8.47286 9.44702H5.00065V6.66927H15.4868C15.8201 5.69705 16.3988 4.89844 17.2229 4.27344C18.0469 3.64844 18.9729 3.33594 20.0007 3.33594C21.0284 3.33594 21.9544 3.64844 22.7784 4.27344C23.6025 4.89844 24.1812 5.69705 24.5145 6.66927H35.0007V9.44702H31.5284L36.6673 21.7248C36.6488 23.0211 36.1025 24.123 35.0284 25.0304C33.9544 25.9378 32.6488 26.3915 31.1118 26.3915C29.5748 26.3915 28.2692 25.9378 27.1951 25.0304C26.121 24.123 25.5747 23.0211 25.5562 21.7248L30.6951 9.44702H24.5145C24.2645 10.1692 23.8618 10.8081 23.3062 11.3637C22.7506 11.9192 22.1118 12.322 21.3895 12.572V32.2249H36.6673V35.0026H3.33398ZM27.9868 21.6693H34.2368L31.1118 14.1415L27.9868 21.6693ZM5.76453 21.6693H12.0145L8.88953 14.1415L5.76453 21.6693ZM20.0007 10.0026C20.547 10.0026 21.0076 9.81048 21.3826 9.42623C21.7576 9.04195 21.9451 8.58592 21.9451 8.05815C21.9451 7.51184 21.7576 7.05119 21.3826 6.67619C21.0076 6.30119 20.547 6.11369 20.0007 6.11369C19.4729 6.11369 19.0169 6.30119 18.6326 6.67619C18.2483 7.05119 18.0562 7.51184 18.0562 8.05815C18.0562 8.58592 18.2483 9.04195 18.6326 9.42623C19.0169 9.81048 19.4729 10.0026 20.0007 10.0026Z"
        fill="#A694B7"
      />
    </g>
  </svg>
);
