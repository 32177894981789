import { ComponentType, ReactNode } from 'react';
import { FormItemLabel } from './FormItemLabel';

export type WithLabelProps<T> = T & {
  label?: ReactNode;
  hint?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  warning?: boolean;
  errorMessage?: ReactNode;
  className?: string;
};

export const withLabel =
  <OtherProps extends object>(
    Component: ComponentType<React.PropsWithChildren<OtherProps>>,
  ) =>
  // eslint-disable-next-line react/display-name
  ({
    label,
    hint,
    required,
    errorMessage,
    ...props
  }: WithLabelProps<OtherProps>) => {
    const { className, ...otherProps } = props;
    const Child = (
      <Component {...(otherProps as OtherProps)} hasErrors={!!errorMessage} />
    );

    if (label) {
      return (
        <FormItemLabel
          label={label}
          hint={hint}
          disabled={props.disabled}
          required={required}
          warning={props.warning}
          errorMessage={errorMessage}
          className={className}
        >
          {Child}
        </FormItemLabel>
      );
    }
    return Child;
  };
