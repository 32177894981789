import styled from '@emotion/styled';
import {
  defaultCardContentPadding,
  defaultCardStyles,
} from '../cards/defaultCardStyles';

export const CardContainer = styled.div`
  ${defaultCardStyles}
  ${defaultCardContentPadding}
`;
