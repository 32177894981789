import { useMemo } from 'react';
import { useCurrentUser } from '../provider/UserProvider';
import { EnabledFeaturesMatcher } from '../common/EnabledFeaturesMatcher';

export const useEnabledFeaturesMatcher = () => {
  const user = useCurrentUser();
  
  return useMemo(
    () =>
      new EnabledFeaturesMatcher(
        user,
        user?.tenantAdminVisibleModules || [],
        user?.tenantUserVisibleModules || [],
        !!user?.licenseSoftLimit,
      ),
    [user],
  );
};
