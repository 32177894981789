import React from 'react';

export const LaunchIcon = (props: React.SVGAttributes<any>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 19.1494H5V5.14941H12V3.14941H5C3.89 3.14941 3 4.04941 3 5.14941V19.1494C3 20.2494 3.89 21.1494 5 21.1494H19C20.1 21.1494 21 20.2494 21 19.1494V12.1494H19V19.1494ZM14 3.14941V5.14941H17.59L7.76 14.9794L9.17 16.3894L19 6.55941V10.1494H21V3.14941H14Z"
        fill={props.fill ?? 'white'}
      />
    </svg>
  );
};
