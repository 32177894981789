import { SVGProps } from 'react';

export const DateRange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.94549 11H7.59321V13H5.94549V11ZM17.4796 6V20C17.4796 21.1 16.7381 22 15.8319 22H4.29776C3.38327 22 2.65003 21.1 2.65003 20L2.65827 6C2.65827 4.9 3.38327 4 4.29776 4H5.12162V2H6.76935V4H13.3603V2H15.008V4H15.8319C16.7381 4 17.4796 4.9 17.4796 6ZM4.29776 8H15.8319V6H4.29776V8ZM15.8319 20V10H4.29776V20H15.8319ZM12.5364 13H14.1841V11H12.5364V13ZM9.24094 13H10.8887V11H9.24094V13Z"
      fill="currentColor"
    />
  </svg>
);
