import { shift, useFloating } from '@floating-ui/react-dom';
import { useEffect } from 'react';
import { useOnViewportChange } from './useOnViewportChange';

export interface UseFixedFloatingTooltipArgs {
  value: any;
  options?: Parameters<typeof useFloating>[0];
}
export const useFixedFloatingTooltip = ({
  value,
  options,
}: UseFixedFloatingTooltipArgs) => {
  const { reference, floating, y, x, strategy, update } = useFloating({
    strategy: 'fixed',
    middleware: [
      shift({
        padding: 8,
      }),
    ],
    ...(options || {}),
  });

  useOnViewportChange(update);

  useEffect(() => {
    update();
  }, [update, value]);

  return {
    reference,
    floating,
    x,
    y,
    update,
    strategy,
    floatingStyles: { position: strategy, top: y || 0, left: x || 0 },
  };
};
