import { ReactNode, useMemo } from 'react';
import ReactSelect, { MultiValue, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { baseSelectStyles } from './common/baseSelectStyles';
import { DropdownIndicator } from './common/DropdownIndicator';
import { MultiValueContainer } from './common/MultiValueContainer';
import { MultiValueRemove } from './common/MultiValueRemove';
import { CheckOption } from './common/CheckOption';
import { OptionType } from './common/OptionType';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from '../constants';
import { setMenuPortalTargetProps } from './common/setMenuPortalTargetProps';

const multiBaseSelectStyles = baseSelectStyles as StylesConfig<
  OptionType,
  true
>;

const styles: StylesConfig<OptionType, true> = {
  ...multiBaseSelectStyles,
  placeholder: (styles, state) => ({
    ...multiBaseSelectStyles.placeholder!(styles, state),
    padding: '0px 10px',
  }),
  input: (styles, state) => ({
    ...multiBaseSelectStyles.input!(styles, state),
    padding: '0px 10px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    flexWrap: 'nowrap',
    padding: '2px 6px',
  }),
};

export type MultiSelectProps<Value = unknown> = StateManagerProps<
  OptionType<Value>,
  true
> & {
  disabled?: boolean;
  hasErrors?: boolean;
  placeholder: ReactNode;
};

export const MultiSelect = <Value,>({
  hasErrors,
  disabled,
  ...props
}: MultiSelectProps<Value>) => {
  const numberOfCheckedOptions = useMemo(() => {
    return (props.value as MultiValue<OptionType>)?.length ?? 0;
  }, [props.value]);

  const isSmall = useMediaQuery({ query: mediaQuery.small });
  const { isSearchable = !isSmall } = props;

  return (
    <ReactSelect
      {...props}
      isSearchable={isSearchable}
      isDisabled={disabled}
      isMulti
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      styles={styles as StylesConfig<OptionType<Value>, true>}
      onChange={(newValue, meta) => {
        props.onChange?.(newValue, meta);
      }}
      components={{
        ValueContainer: MultiValueContainer,
        Option: CheckOption,
        DropdownIndicator,
        MultiValueRemove,
      }}
      {...setMenuPortalTargetProps(
        props.menuPortalTarget ?? document.getElementById('tooltip-root'),
      )}
      // Custom props
      {...{
        numberOfCheckedOptions,
        hasErrors,
      }}
    />
  );
};
