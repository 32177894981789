import { NavbarProps } from '../types';
import styled from '@emotion/styled';
import { NavDropDownMenu } from './NavDropDownMenu';
import { NotificationBadgeWrapper } from '@laasco/ui-lib/indicator/NotificationBadgeWrapper';

const AvatarContainer = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
`;

export interface DesktopUserMenuProps
  extends Pick<NavbarProps, 'avatar' | 'userMenuItems'> {
  showBadge?: boolean;
}
export const DesktopUserMenu = (props: DesktopUserMenuProps) => {
  return (
    <NavDropDownMenu
      fullHeightButton
      label={
        <NotificationBadgeWrapper showBadge={!!props.showBadge}>
          <AvatarContainer>{props.avatar}</AvatarContainer>
        </NotificationBadgeWrapper>
      }
      active={false}
      dataTest="user"
    >
      {props.userMenuItems}
    </NavDropDownMenu>
  );
};
