import React from 'react';

export const ReviewStarIconBackground = (props: React.SVGAttributes<any>) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.65012 3.90291L8.31834 5.42958L8.64212 6.16958L9.46879 6.23624L11.1703 6.37624L9.87523 7.46291L9.24834 7.98958L9.43434 8.77624L9.82012 10.3829L8.35967 9.52958L7.65012 9.10291L6.94056 9.51624L5.48012 10.3696L5.8659 8.76291L6.0519 7.97624L5.42501 7.44958L4.1299 6.36291L5.83145 6.22291L6.65812 6.15624L6.9819 5.41624L7.65012 3.90291ZM7.65012 0.48291L5.71434 4.90291L0.76123 5.30958L4.52256 8.46291L3.39279 13.1496L7.65012 10.6629L11.9075 13.1496L10.7777 8.46291L14.539 5.30958L9.5859 4.90291L7.65012 0.48291Z"
        fill="#D3D2D1"
      />
    </svg>
  );
};
