export const DragIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_6757_103688"
      style={{ maskType: 'alpha' }}
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
    </mask>
    <g mask="url(#mask0_6757_103688)">
      <path
        fill="#4D2A6F"
        d="M9 20c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 017 18c0-.55.196-1.02.588-1.413A1.926 1.926 0 019 16c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.413A1.926 1.926 0 019 20zm6 0c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0113 18c0-.55.196-1.02.588-1.413A1.926 1.926 0 0115 16c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.413A1.926 1.926 0 0115 20zm-6-6c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 017 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 019 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 019 14zm6 0c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0113 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0115 10c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0115 14zM9 8c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 017 6c0-.55.196-1.02.588-1.412A1.926 1.926 0 019 4c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 019 8zm6 0c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0113 6c0-.55.196-1.02.588-1.412A1.926 1.926 0 0115 4c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0115 8z"
      ></path>
    </g>
  </svg>
);
