export const Recovery = (props: any) => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53318 3.64353C5.72501 2.19032 7.44095 1.265 9.36564 1.03803C11.29 0.811066 13.1922 1.30972 14.7225 2.44175C16.1533 3.50066 17.1047 5.0225 17.4117 6.70185L15.6395 6.71928L17.9459 9.15266L20.1602 6.67541L18.4594 6.692C18.1408 4.71003 17.0392 2.90835 15.3594 1.66522C13.6112 0.371472 11.4375 -0.198059 9.23802 0.061253C7.03884 0.320566 5.07743 1.37807 3.71534 3.03857C3.11365 3.77235 2.65764 4.58938 2.36035 5.46716L3.34698 5.76894C3.60696 5.00085 4.00611 4.28591 4.53318 3.64353Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.81292 15.0147C6.28516 16.137 8.05854 16.7304 9.89529 16.7304C10.24 16.7304 10.587 16.7096 10.9343 16.6672C13.1338 16.4001 15.0949 15.3108 16.4564 13.6C17.0776 12.8202 17.5425 11.9503 17.8383 11.0146L16.8482 10.715C16.5897 11.5331 16.1828 12.294 15.6392 12.9767C14.4473 14.4737 12.7311 15.427 10.8067 15.6609C8.88201 15.8944 6.97982 15.3812 5.44984 14.2146C4.00899 13.1161 3.06618 11.569 2.75942 9.82339L4.51977 9.78398L2.22021 7.30469L0 9.8854L1.71298 9.84686C2.03455 11.9031 3.12511 13.7278 4.81292 15.0147Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0821 5.88645C9.70814 5.37602 9.10502 5.04297 8.42203 5.04297C6.84658 5.04297 5.83849 6.70201 6.67852 8.18665C7.51616 9.66594 10.0821 11.418 10.0821 11.418C10.0821 11.418 12.6064 9.47846 13.4532 8.23032C14.4017 6.83036 13.3477 5.04297 11.7437 5.04297C11.061 5.04297 10.4573 5.37602 10.0821 5.88645Z"
      fill="#907BA6"
    />
  </svg>
);
