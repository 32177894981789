import styled from '@emotion/styled';
import { brandColors } from '../constants';

export const PlainButton = styled.button<{ textDecoration?: 'underline' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;

  background: none;
  border: none;
  cursor: pointer;

  color: ${brandColors.laasPurple};

  text-decoration: ${(p) => p.textDecoration || 'none'};

  &:focus-visible {
    outline: none;
    &:not(:disabled) {
      box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
    }
  }

  :hover {
    text-decoration: underline;
  }
`;
