import React from 'react';

export interface UseCloseOnBackgroundClickProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => any;
}
export const useCloseOnBackgroundClick = ({
  isOpen,
  setIsOpen,
}: UseCloseOnBackgroundClickProps) => {
  React.useEffect(() => {
    const handler = () => {
      setIsOpen(false);
    };
    if (isOpen) {
      window.addEventListener('click', handler);
    }
    return () => {
      window.removeEventListener('click', handler);
    };
  }, [isOpen, setIsOpen]);
};
