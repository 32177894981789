import { SVGProps } from 'react';
import { brandColors } from '../constants';

export const AllResults = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6667 9.33333H22.6667V12H14.6667V9.33333ZM14.6667 14.6667H22.6667V17.3333H14.6667V14.6667ZM14.6667 20H22.6667V22.6667H14.6667V20ZM9.33333 9.33333H12V12H9.33333V9.33333ZM9.33333 14.6667H12V17.3333H9.33333V14.6667ZM9.33333 20H12V22.6667H9.33333V20ZM26.8 4H5.2C4.53333 4 4 4.53333 4 5.2V26.8C4 27.3333 4.53333 28 5.2 28H26.8C27.3333 28 28 27.3333 28 26.8V5.2C28 4.53333 27.3333 4 26.8 4ZM25.3333 25.3333H6.66667V6.66667H25.3333V25.3333Z"
      fill={props.fill ?? brandColors.laasPurple}
    />
  </svg>
);
