import React from 'react';

export const EmployeeExperienceSurveyIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <rect width="40" height="40" rx="20" fill="#DBD4E2" />
      <path
        d="M28.5 14C25.89 14.7 22.83 15 20 15C17.17 15 14.11 14.7 11.5 14L11 16C12.86 16.5 15 16.83 17 17V30H19V24H21V30H23V17C25 16.83 27.14 16.5 29 16L28.5 14ZM20 14C21.1 14 22 13.1 22 12C22 10.9 21.1 10 20 10C18.9 10 18 10.9 18 12C18 13.1 18.9 14 20 14Z"
        fill="#907BA6"
      />
    </g>
  </svg>
);

export const WellBeingSurveyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <rect y="0.149414" width="40" height="40" rx="20" fill="#DBD4E2" />
      <path
        d="M24.5 11.1494C22.76 11.1494 21.09 11.9594 20 13.2394C18.91 11.9594 17.24 11.1494 15.5 11.1494C12.42 11.1494 10 13.5694 10 16.6494C10 20.4294 13.4 23.5094 18.55 28.1894L20 29.4994L21.45 28.1794C26.6 23.5094 30 20.4294 30 16.6494C30 13.5694 27.58 11.1494 24.5 11.1494ZM20.1 26.6994L20 26.7994L19.9 26.6994C15.14 22.3894 12 19.5394 12 16.6494C12 14.6494 13.5 13.1494 15.5 13.1494C17.04 13.1494 18.54 14.1394 19.07 15.5094H20.94C21.46 14.1394 22.96 13.1494 24.5 13.1494C26.5 13.1494 28 14.6494 28 16.6494C28 19.5394 24.86 22.3894 20.1 26.6994Z"
        fill="#907BA6"
      />
    </g>
  </svg>
);

export const PulseSurveyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <rect width="40" height="40" rx="20" fill="#DBD4E2" />
      <path
        d="M22.06 17.94L20 17L22.06 16.06L23 14L23.94 16.06L26 17L23.94 17.94L23 20L22.06 17.94ZM12 22L12.94 19.94L15 19L12.94 18.06L12 16L11.06 18.06L9 19L11.06 19.94L12 22ZM16.5 17L17.59 14.59L20 13.5L17.59 12.41L16.5 10L15.41 12.41L13 13.5L15.41 14.59L16.5 17ZM12.5 28.5L18.5 22.49L22.5 26.49L31 16.93L29.59 15.52L22.5 23.49L18.5 19.49L11 27L12.5 28.5Z"
        fill="#907BA6"
      />
    </g>
  </svg>
);

export const CompetenceSurveyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <rect width="40" height="40" rx="20" fill="#DBD4E2" />
      <path
        d="M28.5 14C25.89 14.7 22.83 15 20 15C17.17 15 14.11 14.7 11.5 14L11 16C12.86 16.5 15 16.83 17 17V30H19V24H21V30H23V17C25 16.83 27.14 16.5 29 16L28.5 14ZM20 14C21.1 14 22 13.1 22 12C22 10.9 21.1 10 20 10C18.9 10 18 10.9 18 12C18 13.1 18.9 14 20 14Z"
        fill="#907BA6"
      />
    </g>
  </svg>
);
