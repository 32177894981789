import { brandColors } from '../constants';

export const SpeechBalloon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill={brandColors.laasPurple}
    {...props}
  >
    <path
      d="M19.99 2C19.99 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20L19.99 2ZM16 12H4V10H16V12ZM16 9H4V7H16V9ZM16 6H4V4H16V6Z"
      fill="#4D2A6F"
    />
  </svg>
);
