import React from 'react';
import styled from '@emotion/styled';
import { brandColors, zIndex } from '../constants';
import { BouncingLoader } from './BouncingLoader';

const ContainerBackdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${brandColors.laasPurple5Alpha};
  z-index: ${zIndex.content + 1};
`;

export const FullScreenLoader = () => {
  return (
    <ContainerBackdrop>
      <BouncingLoader radius={60} />
    </ContainerBackdrop>
  );
};
