import React from 'react';

export const WellbeingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5080_117101)">
        <path
          d="M21.9998 4C19.6798 4 17.4532 5.08 15.9998 6.78667C14.5465 5.08 12.3198 4 9.99984 4C5.89317 4 2.6665 7.22667 2.6665 11.3333C2.6665 16.3733 7.19984 20.48 14.0665 26.72L15.9998 28.4667L17.9332 26.7067C24.7998 20.48 29.3332 16.3733 29.3332 11.3333C29.3332 7.22667 26.1065 4 21.9998 4ZM16.1332 24.7333L15.9998 24.8667L15.8665 24.7333C9.51984 18.9867 5.33317 15.1867 5.33317 11.3333C5.33317 8.66667 7.33317 6.66667 9.99984 6.66667C12.0532 6.66667 14.0532 7.98667 14.7598 9.81333H17.2532C17.9465 7.98667 19.9465 6.66667 21.9998 6.66667C24.6665 6.66667 26.6665 8.66667 26.6665 11.3333C26.6665 15.1867 22.4798 18.9867 16.1332 24.7333Z"
          fill="#907BA6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5080_117101">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
