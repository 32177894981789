import { SVGProps } from 'react';

export const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="64615D"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 8.99902L6.41 10.409L11 5.82902V21.999H13V5.82902L17.59 10.419L19 8.99902L12 1.99902L5 8.99902Z" />
  </svg>
);
