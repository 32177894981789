import { css } from '@emotion/react';
import { subtitle2Styles } from '@laasco/ui-lib/typography';
import { brandColors } from '../../../constants';

export const hoverTextDecoration = css`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 4px;
  text-decoration-color: ${brandColors.lightPurpleBorderAA};
`;

export const activeLinkStyles = css`
  ${hoverTextDecoration};
  color: ${brandColors.coalGrey};
  font-weight: 700;
`;

export const topLinkButtonStyles = css`
  ${subtitle2Styles}
  color: ${brandColors.laasPurple};
  :hover {
    ${hoverTextDecoration};
  }
`;
