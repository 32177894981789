import React from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { usePatchProfileMutation } from '../generated';
import { useCurrentUser } from '../provider/UserProvider';

export const useCurrentUserController = () => {
  const router = useRouter();

  const [_cookie, setCookie] = useCookies();
  const [patchProfile] = usePatchProfileMutation();
  const currentUser = useCurrentUser();

  const setLanguage = React.useCallback(
    async (locale: string) => {
      setCookie('NEXT_LOCALE', locale, {
        path: '/',
        maxAge: 3600 * 24 * 365,
      });
      await patchProfile({
        variables: {
          updates: {
            email: currentUser!.email,
            preferredLanguage: locale,
          },
        },
      });
      await router.replace(`${router.asPath}`, undefined, { locale });
    },
    [setCookie, router, currentUser, patchProfile],
  );

  return {
    setLanguage,
  };
};
