import styled from '@emotion/styled';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { PropsWithChildren, ReactNode } from 'react';
import { SupplementaryButton } from '../buttons/SupplementaryButton';
import { animationDurationNumbers, brandColors } from '../constants';
import { PlusCircle } from '../icons';
import { BouncingLoader } from '../loader/BouncingLoader';
import { BodyM } from '../typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ItemContainer = styled(Container)`
  gap: 16px;
`;

const ShowMoreRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0 32px;
  align-items: center;
`;

const RowSeparator = styled.div`
  height: 1px;
  background-color: ${brandColors.laasPurple20};
`;

const ShowMoreRow = (props: PropsWithChildren<any>) => (
  <ShowMoreRowContainer>
    <RowSeparator />
    {props.children}
    <RowSeparator />
  </ShowMoreRowContainer>
);

const ShowMoreButton = styled(SupplementaryButton)`
  gap: 24px;
  padding: 0 24px;
`;

const LoaderContainer = styled.div`
  align-self: center;
`;

export interface PaginatedListProps {
  totalResultCountText: ReactNode;
  hasMoreResults?: boolean;
  isLoadingMoreResults?: boolean;
  showMoreText: ReactNode;
  onShowMoreClick: () => void;
}

export const PaginatedList = (props: PropsWithChildren<PaginatedListProps>) => {
  const [listingRef] = useAutoAnimate<HTMLDivElement>({
    duration: animationDurationNumbers.long,
  });

  return (
    <Container>
      <BodyM>{props.totalResultCountText}</BodyM>

      <ItemContainer ref={listingRef}>
        {props.children}
        {props.isLoadingMoreResults && (
          <LoaderContainer>
            <BouncingLoader radius={32} />
          </LoaderContainer>
        )}
      </ItemContainer>

      {props.hasMoreResults && (
        <ShowMoreRow>
          <ShowMoreButton
            onClick={props.onShowMoreClick}
            disabled={props.isLoadingMoreResults}
          >
            <PlusCircle
              fill={
                props.isLoadingMoreResults
                  ? brandColors.coalGrey20
                  : brandColors.laasPurple60
              }
            />
            {props.showMoreText}
          </ShowMoreButton>
        </ShowMoreRow>
      )}
    </Container>
  );
};
