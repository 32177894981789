import styled from '@emotion/styled';
import { brandColors } from '../constants';

const StyledButton = styled.button`
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  cursor: pointer;

  color: ${brandColors.laasPurple};
  border: 1px solid ${brandColors.laasPurple};

  border-radius: 50%;

  &:hover,
  &:active {
    box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
  }

  &:disabled {
    color: ${brandColors.coalGrey20};
    border: 1px solid ${brandColors.coalGrey20};
    box-shadow: none;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: none;
    &:not(:disabled) {
      box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
    }
  }
`;

export interface RadialButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'children'
  > {
  icon?: React.ReactNode;
}

export const RadialButton = (props: RadialButtonProps) => (
  <StyledButton {...props}>{props.icon}</StyledButton>
);
