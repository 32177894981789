export const NpsPositive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="16" fill="#D5E9E5" />
      <circle cx="16" cy="16" r="16" fill="#CADAB7" />
      <circle cx="16" cy="16" r="16" fill="#D8EBC2" />
      <circle cx="11.0002" cy="11" r="1.8" fill="#679236" />
      <circle cx="20.2004" cy="11" r="1.8" fill="#679236" />
      <path
        d="M6.8 16C6.35817 16 5.99667 16.3588 6.03196 16.7992C6.22106 19.1588 7.24286 21.385 8.92893 23.0711C10.8043 24.9464 13.3478 26 16 26C18.6522 26 21.1957 24.9464 23.0711 23.0711C24.7571 21.385 25.7789 19.1588 25.968 16.7992C26.0033 16.3588 25.6418 16 25.2 16L16 16L6.8 16Z"
        fill="#679236"
      />
    </svg>
  );
};
