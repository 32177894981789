import { SVGProps } from 'react';
import { brandColors } from '../constants';

export const MixedFeeling = (props: SVGProps<SVGSVGElement> = {}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill={brandColors.coalGrey70}
    {...props}
  >
    <path d="M10.3269 12C10.0554 12 9.79103 11.9737 9.53386 11.9211C9.2767 11.8686 9.0271 11.7949 8.78506 11.7C9.35718 11.1857 9.80577 10.6118 10.1308 9.97829C10.4559 9.34476 10.6182 8.68533 10.6178 8C10.6178 7.31429 10.4553 6.65467 10.1302 6.02115C9.80518 5.38762 9.3568 4.81391 8.78506 4.3C9.02749 4.20477 9.27728 4.13086 9.53444 4.07829C9.79161 4.02572 10.0558 3.99962 10.3269 4C11.4614 4 12.4239 4.38819 13.2144 5.16457C14.0049 5.94096 14.4 6.8861 14.3996 8C14.3996 9.11429 14.0044 10.0596 13.2139 10.836C12.4234 11.6124 11.461 12.0004 10.3269 12ZM7.99961 11.2857C7.46628 10.9238 7.04194 10.4571 6.72659 9.88572C6.41125 9.31429 6.25377 8.68572 6.25415 8C6.25415 7.31429 6.41183 6.68572 6.72717 6.11429C7.04252 5.54286 7.46666 5.07619 7.99961 4.71429C8.53294 5.07619 8.95728 5.54286 9.27263 6.11429C9.58797 6.68572 9.74545 7.31429 9.74506 8C9.74506 8.68572 9.58739 9.31429 9.27205 9.88572C8.9567 10.4571 8.53255 10.9238 7.99961 11.2857ZM5.67234 12C4.53779 12 3.57527 11.6118 2.78477 10.8354C1.99428 10.059 1.59922 9.11391 1.59961 8C1.59961 6.88572 1.99486 5.94038 2.78535 5.164C3.57585 4.38762 4.53818 3.99962 5.67234 4C5.94385 4 6.20819 4.02629 6.46535 4.07886C6.72252 4.13143 6.97212 4.20515 7.21415 4.3C6.64203 4.81429 6.19345 5.38819 5.86841 6.02172C5.54337 6.65524 5.38104 7.31467 5.38143 8C5.38143 8.75238 5.5391 9.4501 5.85445 10.0931C6.16979 10.7362 6.60363 11.2813 7.15597 11.7286C6.92325 11.8143 6.68315 11.881 6.43568 11.9286C6.18822 11.9762 5.93377 12 5.67234 12Z" />
  </svg>
);
