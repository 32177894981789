import styled from '@emotion/styled';
import React, { ReactNode, useState } from 'react';
import { BodyMSecondary } from '../../typography';
import { personIsNamed, PersonProps } from '../../person/common';
import { ProfilePicture } from '../../person/ProfilePicture';
import { brandColors } from '../../constants';
import { UnmountClosed } from 'react-collapse';
import { DropdownExpansionChevron } from '../../icons';
import { Container } from './common';

const TagsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export interface ExpandablePersonListItemProps extends PersonProps {
  renderContent: () => ReactNode;
  tags: string[];
}

const HiddenTags = styled.div`
  background: ${brandColors.laasPurple5Alpha};
  border-radius: 30px;
  padding: 4px 12px;
`;

const ClickableContainer = styled(Container)`
  cursor: pointer;
`;

export const ExpandablePersonListItem = (
  props: ExpandablePersonListItemProps,
) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ItemContainer>
      <ClickableContainer onClick={() => setOpen(!isOpen)}>
        <ProfilePicture {...props} />

        <BodyMSecondary>
          {personIsNamed(props)
            ? `${props.firstName} ${props.lastName} - ${props.email}`
            : props.email}
        </BodyMSecondary>
        <TagsContainer>
          {props.tags.length > 0 && (
            <>
              <BodyMSecondary>{props.tags[0]}</BodyMSecondary>
              {props.tags.length > 1 && (
                <HiddenTags>
                  <BodyMSecondary>{`+${props.tags.length - 1}`}</BodyMSecondary>
                </HiddenTags>
              )}
            </>
          )}
        </TagsContainer>
        <DropdownExpansionChevron isOpen={isOpen} />
      </ClickableContainer>
      <UnmountClosed isOpened={isOpen}>{props.renderContent()}</UnmountClosed>
    </ItemContainer>
  );
};
