import { SVGProps } from 'react';

export const ShoppingBasket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_6243_69791"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6243_69791)">
      <path
        d="M4.9999 21C4.71657 21 4.45823 20.9127 4.2249 20.738C3.99157 20.5627 3.83323 20.3333 3.7499 20.05L0.949901 9.95C0.866568 9.71667 0.903901 9.5 1.0619 9.3C1.22057 9.1 1.43323 9 1.6999 9H6.7499L11.1499 2.45C11.2332 2.31667 11.3499 2.20833 11.4999 2.125C11.6499 2.04167 11.8082 2 11.9749 2C12.1416 2 12.2999 2.04167 12.4499 2.125C12.5999 2.20833 12.7166 2.31667 12.7999 2.45L17.1999 9H22.2999C22.5666 9 22.7792 9.1 22.9379 9.3C23.0959 9.5 23.1332 9.71667 23.0499 9.95L20.2499 20.05C20.1666 20.3333 20.0082 20.5627 19.7749 20.738C19.5416 20.9127 19.2832 21 18.9999 21H4.9999ZM5.4999 19H18.4999L20.6999 11H3.2999L5.4999 19ZM11.9999 17C12.5499 17 13.0209 16.8043 13.4129 16.413C13.8042 16.021 13.9999 15.55 13.9999 15C13.9999 14.45 13.8042 13.979 13.4129 13.587C13.0209 13.1957 12.5499 13 11.9999 13C11.4499 13 10.9792 13.1957 10.5879 13.587C10.1959 13.979 9.9999 14.45 9.9999 15C9.9999 15.55 10.1959 16.021 10.5879 16.413C10.9792 16.8043 11.4499 17 11.9999 17ZM9.1749 9H14.7999L11.9749 4.8L9.1749 9Z"
        fill="#4D2A6F"
      />
    </g>
  </svg>
);
