import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { AccordionDrawer } from '../accordion/AccordionDrawer';
import { brandColors, mediaQuery } from '../constants';

const ContentContainer = styled.div`
  border: 1px solid ${brandColors.laasPurple40};
  border-radius: 4px;
  padding: 16px;

  @media screen and ${mediaQuery.widerThanSmall} {
    padding: 32px;
  }
`;

export interface FormGroupContainerProps {
  isOpen: boolean;
  className?: string;
}

export const FormGroupContainer = (
  props: PropsWithChildren<FormGroupContainerProps>,
) => (
  <AccordionDrawer
    className={props.className}
    isOpen={props.isOpen}
    Container={ContentContainer}
  >
    {props.children}
  </AccordionDrawer>
);
