import { ReactNode, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Select, { ActionMeta, components, createFilter } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import {
  basicSelectOption,
  setApply,
  TreeItemData,
  useCustomSelect,
  useTreeState,
} from './common/treeSelectUtils';
import {
  CustomSelectProps,
  MultiSelectTreeItemOption,
  SingleSelectTreeItemOption,
  TreeItemOptionComponentProps,
  TreeItemOptionData,
} from './common/TreeItemOption';
import { MultiValueContainer } from './common/MultiValueContainer';
import { DropdownIndicator } from './common/DropdownIndicator';
import { MultiValueRemove } from './common/MultiValueRemove';
import {
  singleTreeSelectStyles,
  treeSelectStyles,
} from './common/treeSelectStyles';
import { Option } from './common/Option';
import { Switch } from '../inputs';
import { BodyL } from '../typography';
import { brandColors, mediaQuery } from '../constants';
import { WarningAmber } from '../icons';
import { useMediaQuery } from 'react-responsive';
import { setMenuPortalTargetProps } from './common/setMenuPortalTargetProps';

const VALUE_TOGGLE_MODE = '___TOGGLE_MODE';

interface Components {
  ValueContainer: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  Option: (props: TreeItemOptionComponentProps) => JSX.Element;
  DropdownIndicator: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  MultiValueRemove: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  MultiValueLabel?: React.FunctionComponent<React.PropsWithChildren<unknown>>;
}

interface BaseOrganizationSelectProps
  extends Omit<
    StateManagerProps<TreeItemOptionData, true>,
    'value' | 'onChange' | 'options' | 'components'
  > {
  disabled?: boolean;
  hasErrors?: boolean;
  value?: TreeItemData[];
  onChange?: (
    newValue: TreeItemData[],
    selectsDescendants: boolean,
    actionMeta: ActionMeta<TreeItemOptionData>,
  ) => void;
  treeData: TreeItemData[];

  defaultSelectsDescendants?: boolean;
  toggleModeOptionLabel?: ReactNode;
  toggleModeWarningLabel?: ReactNode;
  isReadonly?: boolean;
  isSingleSelect?: boolean;
  hideToggleMode?: boolean;
  disableBackspaceRemove?: boolean;
  closeMenuOnSelect?: boolean;

  components: Components;
}

export type OrganizationSelectProps = Omit<
  BaseOrganizationSelectProps,
  'isSingleSelect' | 'disableBackSpaceRemove' | 'components'
>;

export interface OrganizationSingleSelectProps
  extends Omit<
    BaseOrganizationSelectProps,
    'components' | 'value' | 'onChange'
  > {
  value?: TreeItemData;
  onChange?: (
    newValue: TreeItemData,
    selectsDescendants: boolean,
    actionMeta: ActionMeta<TreeItemOptionData>,
  ) => void;
}

const StyledSwitch = styled(Switch)`
  pointer-events: none;
`;

const SwitchOption = (props: any) => (
  <Option {...props}>
    <StyledSwitch id={props.value} checked={props.isSelected}>
      {props.label}
    </StyledSwitch>
  </Option>
);

const SelectWarningWrapper = styled.div`
  display: flex;
  margin-top: 11px;
  margin-left: 32px;
  color: ${brandColors.systemError};
`;

const SelectWarningIcon = styled(WarningAmber)`
  transform: scale(120%) translate(0, 1.5px);
`;

const SelectWarningBodyL = styled(BodyL)`
  margin-left: 9px;
  color: ${brandColors.systemError};
`;

type OrganizationSelectCustomProps = CustomSelectProps & {
  selectsDescendants: boolean;
  toggleModeWarningLabel?: ReactNode;
};

const ValueAwareOption = (
  props: TreeItemOptionComponentProps & {
    component: (props: TreeItemOptionComponentProps) => JSX.Element;
  },
) => {
  const data = props.data;
  const { selectsDescendants, toggleModeWarningLabel } =
    props.selectProps as OrganizationSelectCustomProps;
  const showsWarningLabel =
    !props.selectProps.isSingleSelect &&
    !selectsDescendants &&
    data.hasChildren &&
    props.isSelected;

  if (data.value === VALUE_TOGGLE_MODE) {
    return <SwitchOption {...props} />;
  }
  return (
    <props.component
      {...props}
      data-test="select-option"
      contentAfter={
        showsWarningLabel && (
          <SelectWarningWrapper>
            <SelectWarningIcon />
            <SelectWarningBodyL>
              {toggleModeWarningLabel ||
                "Only this unit's direct members, not sub units' members."}
            </SelectWarningBodyL>
          </SelectWarningWrapper>
        )
      }
    />
  );
};

const getValueAwareOptionWithComponent = (
  component: (props: TreeItemOptionComponentProps) => JSX.Element,
) => {
  // eslint-disable-next-line react/display-name
  return (props: TreeItemOptionComponentProps) => {
    return <ValueAwareOption component={component} {...props} />;
  };
};

const toggleModeTreeItemData = (label: ReactNode): TreeItemData => ({
  id: VALUE_TOGGLE_MODE,
  label,
  parentId: null,
});

const treeDataWithSyntheticOptions = ({
  treeData,
  toggleModeOptionLabel,
}: Pick<
  OrganizationSelectProps,
  'treeData' | 'toggleModeOptionLabel'
>): TreeItemData[] =>
  treeData.length === 0
    ? []
    : [toggleModeTreeItemData(toggleModeOptionLabel), ...treeData];

const SelectContainer = (props: any) => (
  <components.SelectContainer {...props}>
    <span data-test="organization-unit-select">{props.children}</span>
  </components.SelectContainer>
);

const BaseOrganizationSelect = ({
  disabled,
  value,
  onChange,
  treeData: treeDataProp,
  defaultSelectsDescendants = true,
  toggleModeOptionLabel,
  toggleModeWarningLabel,
  ...selectProps
}: BaseOrganizationSelectProps) => {
  const { isReadonly, isSingleSelect, hideToggleMode, disableBackspaceRemove } =
    selectProps;
  const hideToggleModeOption = isReadonly || hideToggleMode;

  const treeData = useMemo(
    () =>
      hideToggleModeOption
        ? treeDataProp
        : treeDataWithSyntheticOptions({
            treeData: treeDataProp,
            toggleModeOptionLabel,
          }),
    [treeDataProp, toggleModeOptionLabel, hideToggleModeOption],
  );

  const [selectsDescendants, setSelectDescendants] = useState(
    defaultSelectsDescendants,
  );

  const {
    checkedNodeIds,
    setCheckedNodeIds,
    setCollapsed,
    options,
    optionPropsById,
    treeItems,
    numberOfCheckedOptions,
    reactSelectValue,
  } = useTreeState(treeData, !selectsDescendants, value);

  const selectWithDescendants = basicSelectOption({
    checkedNodeIds,
    optionPropsById,
    treeItems,
    options,
  });

  const selectRef = useCustomSelect({
    setCollapsed,
    selectOption: (ref, newValue) => {
      if (isReadonly) {
        return;
      }

      if (newValue.value === VALUE_TOGGLE_MODE) {
        setSelectDescendants(!selectsDescendants);
        if (!isSingleSelect) {
          ref.setValue([], 'deselect-option', newValue);
        }
        if (isSingleSelect && onChange) {
          // Manually trigger on change in single select mode to indicate change of selectsDescendants mode
          onChange(value || [], !selectsDescendants, {
            action: 'select-option',
            option: newValue,
          });
        }
        return;
      }

      if (isSingleSelect) {
        const isIncluded = !checkedNodeIds.has(newValue.value);
        ref.setValue(
          isIncluded ? [newValue] : [],
          isIncluded ? 'select-option' : 'deselect-option',
          newValue,
        );
        return;
      }

      if (selectsDescendants) {
        selectWithDescendants(ref, newValue);
      } else {
        const isIncluded = !checkedNodeIds.has(newValue.value);
        const newOptionIds = setApply(
          checkedNodeIds,
          newValue.value,
          isIncluded,
        );

        ref.setValue(
          options.filter((option) => newOptionIds.has(option.value)),
          isIncluded ? 'select-option' : 'deselect-option',
          newValue,
        );
      }
    },
  });

  const isSmall = useMediaQuery({ query: mediaQuery.small });
  const { isSearchable = !isSmall && !isReadonly } = selectProps;

  return (
    <Select
      {...selectProps}
      isDisabled={disabled}
      options={options}
      value={reactSelectValue}
      ref={selectRef}
      isSearchable={isSearchable}
      backspaceRemovesValue={
        !(isReadonly === true || disableBackspaceRemove === true)
      }
      isMulti
      hideSelectedOptions={false}
      styles={isSingleSelect ? singleTreeSelectStyles : treeSelectStyles}
      onChange={(newValue, meta) => {
        const nodeIds = new Set(newValue.map((v) => v.value));
        setCheckedNodeIds(nodeIds);
        onChange?.(
          treeData.filter(({ id }) => nodeIds.has(id)),
          selectsDescendants,
          meta,
        );
      }}
      filterOption={(option, inputValue) => {
        if (inputValue) {
          return createFilter({})(option, inputValue);
        }
        return !optionPropsById[option.value].isHidden;
      }}
      isOptionSelected={({ value }) => {
        if (value === VALUE_TOGGLE_MODE) {
          return !selectsDescendants;
        }
        return (
          checkedNodeIds.has(value) ||
          (selectsDescendants && optionPropsById[value].isParentChecked)
        );
      }}
      isOptionDisabled={(option) => option.disabled || !!isReadonly}
      components={{ ...selectProps.components, SelectContainer }}
      {...setMenuPortalTargetProps(document.getElementById('tooltip-root'))}
      // Custom props
      {...{
        optionPropsById,
        setCollapsed,
        selectsDescendants,
        toggleModeWarningLabel,
        numberOfCheckedOptions,
      }}
    />
  );
};

export const OrganizationSelect = (props: OrganizationSelectProps) => {
  const OptionComponent = getValueAwareOptionWithComponent(
    MultiSelectTreeItemOption,
  );

  return (
    <BaseOrganizationSelect
      closeMenuOnSelect={false}
      {...props}
      components={{
        ValueContainer: MultiValueContainer,
        Option: OptionComponent,
        DropdownIndicator,
        MultiValueRemove,
      }}
    />
  );
};

export const OrganizationSingleSelect = (
  props: OrganizationSingleSelectProps,
) => {
  const OptionComponent = getValueAwareOptionWithComponent(
    SingleSelectTreeItemOption,
  );

  return (
    <BaseOrganizationSelect
      isClearable={true}
      isSingleSelect={true}
      closeMenuOnSelect={true}
      {...props}
      // Wrap and unwrap multi-value select to single-value select
      value={props.value ? [props.value] : []}
      onChange={(newValue, selectsDescendats, actionMeta) => {
        if (props.onChange) {
          props.onChange(newValue?.[0], selectsDescendats, actionMeta);
        }
      }}
      components={{
        ValueContainer: MultiValueContainer,
        Option: OptionComponent,
        DropdownIndicator,
        MultiValueRemove: () => null,
      }}
    />
  );
};
