import React, { useState } from 'react';
import styled from '@emotion/styled';
import { brandColors, mediaQuery } from '../constants';
import {
  ReviewHeartIcon,
  ReviewHeartIconBackground,
  ReviewStarIcon,
  ReviewStarIconBackground,
} from '../icons';
import _ from 'lodash';
import { UnstyledLink } from '../UnstyledLink';
import { BodyS } from '../typography';

type IconColor = 'red' | 'purple';
type IconType = 'star' | 'heart';
type IconSize = 'medium' | 'small';

interface IconContainerProps {
  color?: string;
  position: string;
  opacity: string;
}

const IconContainer = styled.div<IconContainerProps>`
  display: inline-block;
  padding: 4px;
  position: ${({ position }) => position};
  top: 0px;
  left: 0px;
  opacity: ${({ opacity }) => opacity};
  & path {
    fill: ${({ color }) => color || brandColors.coalGrey20};
  }
  @media screen and ${mediaQuery.small} {
    padding: 2px;
  }
`;

const colors = {
  red: brandColors.indianSummerNotAA,
  purple: brandColors.laasPurple,
};

const getIcon = (size: IconSize, icon: IconType, type: 'bg' | 'fg') => {
  const baseSmall = 45;
  const baseMedium = 53;
  const baseSize = size === 'small' ? baseSmall : baseMedium;

  const icons = {
    heart: {
      bg: <ReviewHeartIconBackground width={baseSize} height={baseSize - 1} />,
      fg: <ReviewHeartIcon width={baseSize} height={baseSize} />,
    },
    star: {
      bg: <ReviewStarIconBackground width={baseSize} height={baseSize} />,
      fg: <ReviewStarIcon width={baseSize} height={baseSize} />,
    },
  };
  return icons[icon][type];
};

interface SingleIconProps {
  fill?: boolean;
  icon: IconType;
  color: IconColor;
  index: number;
  setPointedValue: React.Dispatch<React.SetStateAction<number>>;
  onChange: (value: number) => any;
  value: number;
  size: IconSize;
}

const AnimatedLink = styled(UnstyledLink)`
  user-select: none;
  :active {
    & > div {
      transform: scale(0.94);
    }
  }
`;

const SingleIcon = ({
  icon,
  color,
  fill = false,
  index,
  setPointedValue,
  onChange,
  value,
  size,
}: SingleIconProps) => (
  <AnimatedLink
    onClick={() => onChange(index)}
    onMouseEnter={() => setPointedValue(index)}
    onMouseLeave={() => setPointedValue(value)}
  >
    <IconContainer position="relative" opacity="100%">
      {getIcon(size, icon, 'bg')}
      <IconContainer
        position="absolute"
        color={colors[color]}
        opacity={fill ? '100%' : '0%'}
      >
        {getIcon(size, icon, 'fg')}
      </IconContainer>
    </IconContainer>
  </AnimatedLink>
);

const IconsContainer = styled.div`
  position: relative;
`;

const ErrorText = styled(BodyS)`
  color: ${brandColors.systemError};
`;

export interface IconRatingSelectProps {
  icon: IconType;
  color: IconColor;
  value: number;
  onChange: (value: any) => any;
  error?: string;
  size?: IconSize;
  className?: string;
}

export function IconRatingSelect({
  icon,
  color,
  value,
  onChange,
  error,
  size = 'medium',
  className,
}: IconRatingSelectProps) {
  const [pointedValue, setPointedValue] = useState(value);
  React.useEffect(() => {
    setPointedValue(value);
  }, [value]);

  return (
    <IconsContainer className={className} data-test="icon-rating-select">
      {error && error.length > 0 && <ErrorText>{error}</ErrorText>}
      {_.range(1, 6).map((val) => (
        <SingleIcon
          size={size}
          value={value}
          icon={icon}
          color={color}
          key={val}
          index={val}
          setPointedValue={setPointedValue}
          onChange={onChange}
          fill={pointedValue >= val}
        />
      ))}
    </IconsContainer>
  );
}
