import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { Rect } from 'react-measure';
import { animationDuration, brandColors, mediaQuery } from '../../constants';
import { Step, stepBallDiagonal } from './Step';
import { useMediaQuery } from 'react-responsive';
import { HeadingXS, headingXSStyles } from '../../typography';
import { OverflowArrowButtons } from './OverflowButtons';

const Underline = styled.div<{ width: string }>`
  position: relative;
  height: 1px;
  background: ${brandColors.laasPurple60};
  width: ${(p) => p.width};
  min-width: 100%;

  @media screen and ${mediaQuery.medium} {
    position: absolute;
    top: ${stepBallDiagonal.medium / 2 - 1}px;
  }
  @media screen and ${mediaQuery.small} {
    position: absolute;
    top: ${stepBallDiagonal.small / 2 - 1}px;
  }
`;

const Slider = styled.div<{ width: string }>`
  position: absolute;
  height: 3px;
  width: ${({ width }) => width};
  top: -1px;
  left: 0px;

  background: ${brandColors.laasPurple};
  transition: ${animationDuration.long} ease-in-out;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ScrollWrapper = styled.div<{
  hideLeft: boolean;
  hideRight: boolean;
  fadeWidth: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: 0px;
  clip-path: polygon(
    calc(0% + ${(p) => (p.hideLeft ? p.fadeWidth : '0px')}) 0%,
    calc(100% - ${(p) => (p.hideRight ? p.fadeWidth : '0px')}) 0%,
    calc(100% - ${(p) => (p.hideRight ? p.fadeWidth : '0px')}) 100%,
    calc(0% + ${(p) => (p.hideLeft ? p.fadeWidth : '0px')}) 100%
  );
  transition: clip-path ease-in ${animationDuration.short};
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 32px 0;
  width: max-content;
  @media screen and ${mediaQuery.medium} {
    margin: 0;
  }
`;

const useStepper = (activeStep: number, totalStepCount: number) => {
  const [rectsByIndex, setRectsByIndex] = useState<Record<number, Rect>>({});
  const scrollRef = useRef<HTMLDivElement>(null);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderProps, setSliderProps] = useState<{
    width: string | null;
  }>({ width: null });
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const isMedium = useMediaQuery({ query: mediaQuery.medium });
  const isSmall = useMediaQuery({ query: mediaQuery.small });

  const onMeasureStep = useCallback(
    (tabIndex: number, rect: Rect) =>
      setRectsByIndex((rects) => ({ ...rects, [tabIndex]: rect })),
    [setRectsByIndex],
  );

  const calcUnderlineWidth = useCallback(() => {
    const element = scrollRef.current;
    let width = 0;
    if (!element) return;
    const stepsWidth = Object.keys(rectsByIndex)
      .map((key) => parseInt(key, 10))
      .reduce((offset, index) => offset + rectsByIndex[index].width, 0);
    if (element.clientWidth < stepsWidth) {
      setIsOverflowing(true);
      width = stepsWidth;
    } else {
      setIsOverflowing(false);
      width = element.clientWidth;
    }
    setUnderlineWidth(width);
  }, [rectsByIndex, scrollRef]);

  const onScroll = useCallback(() => {
    const element = scrollRef.current;
    if (!element) return;
    calcUnderlineWidth();
    setScrollAmount(
      element.scrollLeft / (element.scrollWidth - element.clientWidth),
    );
  }, [calcUnderlineWidth, scrollRef]);

  const distanceToStepRightEdge = useCallback(
    (stepNumber: number) =>
      Object.keys(rectsByIndex)
        .map((key) => parseInt(key, 10))
        .filter((index) => index <= stepNumber)
        .reduce((offset, index) => offset + rectsByIndex[index].width, 0),
    [rectsByIndex],
  );

  useLayoutEffect(() => {
    calcUnderlineWidth();
    window.addEventListener('resize', calcUnderlineWidth);
    return () => {
      window.removeEventListener('resize', calcUnderlineWidth);
    };
  }, [calcUnderlineWidth]);

  const scrollToStep = useCallback(
    (step: number) => {
      const element = scrollRef.current;
      if (!element || !rectsByIndex) return;
      const ballWidth = isSmall
        ? stepBallDiagonal.small
        : stepBallDiagonal.medium;
      const stepPosition = distanceToStepRightEdge(step);
      const activeStepWidth = rectsByIndex[step]?.width || 0;
      const scrollPosition =
        stepPosition -
        activeStepWidth -
        element.clientWidth / 2 +
        (isMedium ? ballWidth / 2 : activeStepWidth / 2);
      element.scrollTo({
        behavior: 'smooth',
        top: 0,
        left: scrollPosition,
      });
    },
    [scrollRef, isMedium, isSmall, rectsByIndex, distanceToStepRightEdge],
  );

  useEffect(() => {
    if (!isMedium && activeStep === totalStepCount - 1) {
      setSliderProps({ width: '100%' });
    } else {
      setSliderProps({ width: sliderWidth + 'px' });
    }
  }, [sliderWidth, isMedium, activeStep, totalStepCount]);

  useEffect(() => {
    const activeTabRect = rectsByIndex[activeStep];
    if (activeTabRect) {
      setSliderWidth(distanceToStepRightEdge(activeStep - (isMedium ? 1 : 0)));
    }
  }, [
    rectsByIndex,
    activeStep,
    totalStepCount,
    isMedium,
    scrollRef,
    distanceToStepRightEdge,
  ]);

  return {
    sliderProps,
    onMeasureTab: onMeasureStep,
    scrollRef,
    underlineWidth,
    onScroll,
    isOverflowing,
    scrollAmount,
    isMedium,
    scrollToStep,
  };
};

export interface StepProps {
  disabled?: boolean;
  error?: boolean;
  label: React.ReactNode;
  onClick?: () => any;
  dataTest?: string;
}

export interface StepperProps {
  activeStep: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  steps: StepProps[];
  onStepClick?: (idx: number) => any;
}

const MobileStepLabel = styled(HeadingXS)`
  margin-top: 32px;
  @media screen and ${mediaQuery.widerThanMedium} {
    display: none;
  }
  @media screen and ${mediaQuery.small} {
    ${headingXSStyles}
  }
`;

export const Stepper = (props: StepperProps) => {
  const totalStepCount = props.steps.length;
  const {
    sliderProps,
    onMeasureTab: onMeasureStep,
    scrollRef,
    underlineWidth,
    onScroll,
    isOverflowing,
    scrollAmount,
    isMedium,
    scrollToStep,
  } = useStepper(props.activeStep, totalStepCount);

  const showLeftArrow = scrollAmount > 0 && isOverflowing;
  const showRightArrow = scrollAmount < 1 && isOverflowing;

  const increaseStep = (num: number) => {
    let nextStep = props.activeStep + num;
    while (props.steps[nextStep] && props.setActiveStep) {
      if (!props.steps[nextStep].disabled) {
        props.setActiveStep(nextStep);
        scrollToStep(nextStep);
        return;
      }
      nextStep += num;
    }
    scrollToStep(props.activeStep);
  };

  return (
    <>
      <Wrapper role="tablist">
        <ScrollWrapper
          fadeWidth={isMedium ? '32px' : '40px'}
          ref={scrollRef}
          onScroll={onScroll}
          hideLeft={showLeftArrow}
          hideRight={showRightArrow}
        >
          <Underline width={`${underlineWidth}px`}>
            {sliderProps.width && <Slider width={sliderProps.width} />}
          </Underline>
          <StepsWrapper>
            {props.steps.map((step, idx) => (
              <Step
                key={idx}
                step={idx}
                error={step.error}
                onClick={() => {
                  scrollToStep(idx);
                  if (props.onStepClick) {
                    props.onStepClick(idx);
                  }
                  if (step.onClick) {
                    step.onClick();
                  }
                }}
                state={
                  step.disabled
                    ? 'disabled'
                    : idx === props.activeStep
                    ? 'active'
                    : 'default'
                }
                onMeasure={onMeasureStep}
                data-test={step.dataTest}
              >
                {step.label}
              </Step>
            ))}
          </StepsWrapper>
        </ScrollWrapper>
        <OverflowArrowButtons
          showLeft={showLeftArrow}
          showRight={showRightArrow}
          onLeftClick={() => increaseStep(-1)}
          onRightClick={() => increaseStep(1)}
        />
      </Wrapper>
      <MobileStepLabel>{props.steps[props.activeStep].label}</MobileStepLabel>
    </>
  );
};
