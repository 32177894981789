import React from 'react';

export const PulseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5080_117258)">
        <path
          d="M18.7468 13.2527L16.0002 11.9993L18.7468 10.746L20.0002 7.99935L21.2535 10.746L24.0002 11.9993L21.2535 13.2527L20.0002 15.9993L18.7468 13.2527ZM5.3335 18.666L6.58683 15.9193L9.3335 14.666L6.58683 13.4127L5.3335 10.666L4.08016 13.4127L1.3335 14.666L4.08016 15.9193L5.3335 18.666ZM11.3335 11.9993L12.7868 8.78602L16.0002 7.33268L12.7868 5.87935L11.3335 2.66602L9.88016 5.87935L6.66683 7.33268L9.88016 8.78602L11.3335 11.9993ZM6.00016 27.3327L14.0002 19.3193L19.3335 24.6527L30.6668 11.906L28.7868 10.026L19.3335 20.6527L14.0002 15.3193L4.00016 25.3327L6.00016 27.3327Z"
          fill="#907BA6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5080_117258">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
