import { SVGProps } from 'react';

export const PersonWeaknesses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.2667 13.9335V11.9335H30V13.9335H22.2667ZM12.6667 15.9668C11.2 15.9668 10 15.5001 9.06667 14.5668C8.13333 13.6335 7.66667 12.4335 7.66667 10.9668C7.66667 9.50013 8.13333 8.30013 9.06667 7.3668C10 6.43346 11.2 5.9668 12.6667 5.9668C14.1333 5.9668 15.3333 6.43346 16.2667 7.3668C17.2 8.30013 17.6667 9.50013 17.6667 10.9668C17.6667 12.4335 17.2 13.6335 16.2667 14.5668C15.3333 15.5001 14.1333 15.9668 12.6667 15.9668ZM2 26.6668V23.5335C2 22.7557 2.19444 22.0501 2.58333 21.4168C2.97222 20.7835 3.53333 20.3112 4.26667 20.0001C5.93333 19.2668 7.41667 18.7501 8.71667 18.4501C10.0167 18.1501 11.3333 18.0001 12.6667 18.0001C14 18.0001 15.3111 18.1501 16.6 18.4501C17.8889 18.7501 19.3667 19.2668 21.0333 20.0001C21.7667 20.3335 22.3333 20.8112 22.7333 21.4335C23.1333 22.0557 23.3333 22.7557 23.3333 23.5335V26.6668H2ZM4 24.6668H21.3333V23.5335C21.3333 23.1779 21.2333 22.839 21.0333 22.5168C20.8333 22.1946 20.5667 21.9557 20.2333 21.8001C18.6556 21.0668 17.3222 20.5835 16.2333 20.3501C15.1444 20.1168 13.9556 20.0001 12.6667 20.0001C11.3778 20.0001 10.1833 20.1168 9.08333 20.3501C7.98333 20.5835 6.64444 21.0668 5.06667 21.8001C4.73333 21.9557 4.47222 22.1946 4.28333 22.5168C4.09444 22.839 4 23.1779 4 23.5335V24.6668ZM12.6667 13.9668C13.5333 13.9668 14.25 13.6835 14.8167 13.1168C15.3833 12.5501 15.6667 11.8335 15.6667 10.9668C15.6667 10.1001 15.3833 9.38346 14.8167 8.8168C14.25 8.25013 13.5333 7.9668 12.6667 7.9668C11.8 7.9668 11.0833 8.25013 10.5167 8.8168C9.95 9.38346 9.66667 10.1001 9.66667 10.9668C9.66667 11.8335 9.95 12.5501 10.5167 13.1168C11.0833 13.6835 11.8 13.9668 12.6667 13.9668Z"
      fill="currentColor"
    />
  </svg>
);
