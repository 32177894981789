import React from 'react';
import { NavbarProps } from './types';
import { DesktopNavbar } from './components/DesktopNavbar';
import _ from 'lodash';
import { MobileNavbar } from './components/MobileNavbar';

const inheritActiveStatus = (items: any[]): any[] => {
  return items.map((item) => {
    if (item.children) {
      item.children = inheritActiveStatus(item.children);
      item.active = _.some(item.children, (i) => i.active);
    }
    return item;
  });
};

const removeMobileHiddenMenus = (items: any[]) => {
  return items
    .filter((item) => !item.hideOnMobile)
    .map((item) => {
      const copy = { ...item };
      if (copy.children) {
        copy.children = removeMobileHiddenMenus(copy.children);
      }
      return copy;
    });
};

export const Navbar = (props: NavbarProps) => {
  const menuItems = inheritActiveStatus(props.menuItems);
  const userMenuItems = inheritActiveStatus(props.userMenuItems);
  return (
    <>
      <DesktopNavbar
        {...props}
        menuItems={menuItems}
        userMenuItems={userMenuItems}
      />
      <MobileNavbar
        {...props}
        menuItems={removeMobileHiddenMenus([...menuItems])}
        userMenuItems={removeMobileHiddenMenus([...userMenuItems])}
      />
    </>
  );
};
