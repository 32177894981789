import styled from '@emotion/styled';
import React from 'react';
import { brandColors } from '../constants';
import { CloseIcon, SearchIcon } from '../icons';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
`;

const Input = styled.input`
  background: #ffffff;
  outline: none;
  /* Coal Grey / Coal Grey 20 */

  border: 1px solid rgba(33, 29, 23, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  border-radius: 40px;
  padding: 16px 24px;
  height: 100%;
  color: ${brandColors.coalGrey};
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.05em;
  width: 100%;
  position: absolute;

  &:focus {
    border: 2px solid ${brandColors.laasPurple60};
    padding: 16px 23px;
  }
`;

const ClearButton = styled.button`
  color: ${brandColors.laasPurple};
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export interface ServiceSearchFilterProps {
  value?: string;
  placeholder?: string;
  onChange: (v: string) => any;
  className?: string;
}

export const ServiceSearchFilter = (props: ServiceSearchFilterProps) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <Container className={props.className}>
      <Input
        ref={inputRef}
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <ClearButton
        onClick={() => {
          props.onChange('');
        }}
      >
        {!props.value ? <SearchIcon /> : <CloseIcon />}
      </ClearButton>
    </Container>
  );
};
