import React from 'react';

export const ReviewHeartIconBackground = (props: React.SVGAttributes<any>) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4166 0.149414C9.21796 0.149414 8.06751 0.689414 7.31662 1.54275C6.56573 0.689414 5.41529 0.149414 4.21662 0.149414C2.09485 0.149414 0.427734 1.76275 0.427734 3.81608C0.427734 6.33608 2.76996 8.38941 6.31773 11.5094L7.31662 12.3827L8.31551 11.5027C11.8633 8.38941 14.2055 6.33608 14.2055 3.81608C14.2055 1.76275 12.5384 0.149414 10.4166 0.149414ZM7.38551 10.5161L7.31662 10.5827L7.24773 10.5161C3.96862 7.64275 1.80551 5.74275 1.80551 3.81608C1.80551 2.48275 2.83885 1.48275 4.21662 1.48275C5.27751 1.48275 6.31084 2.14275 6.67596 3.05608H7.96418C8.3224 2.14275 9.35573 1.48275 10.4166 1.48275C11.7944 1.48275 12.8277 2.48275 12.8277 3.81608C12.8277 5.74275 10.6646 7.64275 7.38551 10.5161Z"
        fill="#D3D2D1"
      />
    </svg>
  );
};
