import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { brandColors } from '../constants';

export const ProgressBar = (props: { progressPercentage: number }) => {
  const styles = useSpring({
    to: {
      transform: `scale3d(${props.progressPercentage}%,100%,100%)`,
    },
    delay: 0,
    config: {
      frequency: 1,
      damping: 0.7,
    },
  });

  return (
    <animated.div
      style={{
        ...styles,
        backgroundColor: brandColors.laasPurple,
        height: '100%',
        width: '100%',
        transformOrigin: 'left',
      }}
      role="progressbar"
    />
  );
};
