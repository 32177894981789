import React, { useMemo } from 'react';
import { NavbarProps, TopMenuItemProps } from '../types';
import styled from '@emotion/styled';
import Link from 'next/link';
import { UnstyledLink } from '../../../UnstyledLink';
import { activeLinkStyles, topLinkButtonStyles } from './styles';
import { assertNever } from '../../../assertNever';
import { NavDropDownMenu } from './NavDropDownMenu';
import { NavGridMenu } from './NavGridMenu';
import { DesktopUserMenu } from './DesktopUserMenu';
import { NavbarBar } from './NavbarBar';
import { mediaQuery } from '../../../constants';
import { NavChildrenContextProvider } from '../context/NavChildrenContext';
import { NavItemContextProvider } from '../context/NavItemContext';

const LinkContainer = styled(UnstyledLink)<{ active?: boolean }>`
  ${topLinkButtonStyles};
  ${(p) => (p.active ? activeLinkStyles : null)};
  height: 100%;
  display: flex;
  align-items: center;
`;

const MenuItem = (props: TopMenuItemProps) => {
  switch (props.type) {
    case 'LinkMenuItem':
      return (
        <Link href={props.href || '#'} passHref legacyBehavior>
          <LinkContainer
            active={props.active}
            onClick={props.onClick}
            data-test={props.dataTest && `navbar-${props.dataTest}`}
          >
            {props.label}
          </LinkContainer>
        </Link>
      );
    case 'DropDownMenu':
      return <NavDropDownMenu {...props} fullHeightButton />;
    case 'NavGridMenu':
      return <NavGridMenu {...props} />;
    default:
      assertNever(props);
  }
};

const StyledBar = styled(NavbarBar)`
  @media screen and ${mediaQuery.large} {
    display: none;
  }
`;

export const DesktopNavbar = (props: NavbarProps) => {
  const showUserMenuBadge = useMemo(() => {
    const items = props.userMenuItems.filter(
      (item) => item.type === 'LinkMenuItem',
    );
    return items.some((item) => item.showBadge === true);
  }, [props.userMenuItems]);
  return (
    <StyledBar {...props}>
      <NavChildrenContextProvider>
        {props.menuItems.map((menuItem, i) => (
          <NavItemContextProvider id={'menuitem-' + i} key={'menuitem-' + i}>
            <MenuItem {...menuItem} />
          </NavItemContextProvider>
        ))}

        <NavItemContextProvider id={'userMenu'}>
          <DesktopUserMenu
            userMenuItems={props.userMenuItems}
            avatar={props.avatar}
            showBadge={showUserMenuBadge}
          />
        </NavItemContextProvider>
      </NavChildrenContextProvider>
    </StyledBar>
  );
};
