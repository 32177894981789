import { StylesConfig } from 'react-select';
import { brandColors, zIndex } from '../../constants';

const showsInputError = (state: any) =>
  !!state.selectProps?.hasErrors && !state.isDisabled && !state.isFocused;

const isReadonly = (state: any) => !!state.selectProps?.isReadonly;

export const body1StylesConfig = {
  fontFamily: 'Mulish',
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '24px',
  letterSpacing: '0.002em',
};

export const baseSelectStyles: StylesConfig = {
  control: (styles, state) => ({
    ...styles,
    minHeight: '48px',
    borderRadius: '2px',

    borderColor: state.isDisabled
      ? brandColors.coalGrey20
      : state.isFocused
      ? brandColors.laasPurple60
      : showsInputError(state)
      ? brandColors.systemError
      : brandColors.lightPurpleBorderAA,

    boxShadow: state.isFocused
      ? `0 0 0 1px ${brandColors.laasPurple60}`
      : showsInputError(state)
      ? `0 0 0 1px ${brandColors.systemError}`
      : 'none',
    background: 'white',

    '&:hover': {},
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 16px',
  }),
  placeholder: (styles, state) => ({
    ...styles,
    ...body1StylesConfig,
    color: state.isDisabled ? brandColors.coalGrey50 : brandColors.coalGrey70,
  }),
  dropdownIndicator: (styles, state) => ({
    display: 'flex',
    padding: '8px',
    paddingRight: '16px',
    cursor: 'pointer',
    color: state.isDisabled ? brandColors.coalGrey20 : brandColors.laasPurple,
  }),
  clearIndicator: (styles, state) => ({
    display: isReadonly(state) ? 'none' : 'flex',
    padding: '8px',
    color: brandColors.laasPurple,
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '0',
    borderRadius: '0',
    border: `1px solid ${brandColors.lightPurpleBorderAA}`,
    borderTop: '0',
    boxShadow: '5px 4px 12px rgba(77, 42, 111, 0.1)',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0',
  }),
  menuPortal: (base) => ({ ...base, zIndex: zIndex.tooltip }),
  option: (styles, state) => ({
    ...styles,
    ...body1StylesConfig,
    fontWeight: state.isSelected ? 600 : 'normal',
    color: brandColors.laasPurple,
    cursor: 'pointer',
    background: 'white',
    padding: 0,

    ':not(:last-child)': {
      borderBottom: `1px solid ${brandColors.laasPurple60}`,
    },

    ':active': {
      backgroundColor: 'white',
    },
  }),
  input: (styles) => ({
    ...styles,
    ...body1StylesConfig,
    fontWeight: 600,
    color: brandColors.laasPurple,
  }),
  multiValueLabel: (styles, state) => ({
    ...styles,
    ...body1StylesConfig,
    padding: '3px 0px',
    paddingLeft: '16px',
    paddingRight: isReadonly(state) ? '16px' : 0,
    fontWeight: isReadonly(state) ? 'normal' : 'bold',
    color: isReadonly(state) ? brandColors.coalGrey : brandColors.laasPurple,
  }),
  multiValueRemove: (styles, state) => ({
    display: isReadonly(state) ? 'none' : 'flex',
    alignItems: 'center',
    padding: '3px 8px',
    cursor: 'pointer',
  }),
  multiValue: (styles, state) => ({
    ...styles,
    background: isReadonly(state)
      ? brandColors.coalGrey8
      : brandColors.laasPurple20,
    borderRadius: '50vh',
    overflow: 'hidden',
  }),
  noOptionsMessage: () => ({
    ...body1StylesConfig,
    color: brandColors.coalGrey50,
    padding: '16px',
    textAlign: 'center',
  }),
};
