import styled from '@emotion/styled';
import _ from 'lodash';
import { ReactNode } from 'react';

const Container = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.gap}px;
`;

export interface SkeletonListLoaderProps {
  gap: number;
  children: (index: number) => ReactNode;
  count: number;
}

export const SkeletonListLoader: React.FC<SkeletonListLoaderProps> = (
  props,
) => {
  return (
    <Container gap={props.gap}>
      {_.range(0, props.count).map((i) => (
        <div key={i}>{props.children(i)}</div>
      ))}
    </Container>
  );
};
