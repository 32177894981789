import { components } from 'react-select';
import styled from '@emotion/styled';
import { CloseIcon } from '../../icons';

const RemoveIcon = styled(CloseIcon)`
  transform: scale(${16 / 24});
`;

export const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <RemoveIcon />
  </components.MultiValueRemove>
);
