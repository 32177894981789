export const NpsNegative = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3845_73106)">
        <circle cx="16" cy="16" r="16" fill="#F3B9BD" />
        <circle cx="16" cy="16" r="16" fill="#DCC5C6" />
        <circle cx="16" cy="16" r="16" fill="#F1DADB" />
        <circle cx="11.0002" cy="11" r="1.8" fill="#AA4044" />
        <circle cx="20.2004" cy="11" r="1.8" fill="#AA4044" />
        <path
          d="M22.7433 21.0029C23.0864 20.7245 23.1411 20.2181 22.8361 19.8984C21.9991 19.0208 21.0078 18.3011 19.9095 17.7765C18.5701 17.1367 17.105 16.8032 15.6206 16.8001C14.1362 16.797 12.6697 17.1243 11.3276 17.7585C10.2271 18.2785 9.23279 18.994 8.39212 19.8681C8.08585 20.1865 8.13837 20.6931 8.48027 20.973L9.65315 21.9329C9.99506 22.2128 10.4961 22.1585 10.8137 21.8514C11.3535 21.3293 11.9764 20.8979 12.6586 20.5755C13.5826 20.139 14.5922 19.9136 15.6141 19.9157C16.636 19.9179 17.6446 20.1475 18.5667 20.5879C19.2475 20.9131 19.8686 21.3472 20.4062 21.8715C20.7225 22.18 21.2234 22.2363 21.5664 21.9579L22.7433 21.0029Z"
          fill="#AA4044"
        />
      </g>
      <defs>
        <clipPath id="clip0_3845_73106">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
