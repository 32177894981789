import React, {
  PropsWithChildren,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Rect } from 'react-measure';
import styled from '@emotion/styled';
import {
  animationDuration,
  animationDurationNumbers,
  brandColors,
  mediaQuery,
} from '../../constants';
import { useMediaQuery } from 'react-responsive';
import { OverFlowArrowButtons } from './OverflowButtons';

const SPACE_BETWEEN_TABS_PX = 16;
const MOBILE_SPACE_BETWEEN_TABS_PX = 12;

const Underline = styled.div<{ width: number }>`
  position: relative;
  width: ${(p) => p.width}px;
  min-width: 100%;
  height: 1px;
  background: ${brandColors.laasPurple20};
`;

const Slider = styled.div<{ left: number; width: number }>`
  position: absolute;
  height: 3px;
  width: ${({ width }) => width}px;
  top: -2px;
  left: 0px;
  background: ${brandColors.lightPurpleBorderAA};
  transform: translateX(${({ left }) => left}px);
  transition: ${animationDuration.long} ease-in-out;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const ScrollWrapper = styled.div<{ hideLeft: boolean; hideRight: boolean }>`
  width: 100%;
  overflow-x: visible;
  @media screen and ${mediaQuery.medium} {
    overflow-x: scroll;
    scroll-behavior: smooth;
    mask-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) ${(p) => (p.hideLeft ? '24px' : '0%')},
      rgba(0, 0, 0, 1) ${(p) => (p.hideLeft ? '32px' : '0%')},
      rgba(0, 0, 0, 1) calc(100% - ${(p) => (p.hideRight ? '32px' : '0px')}),
      rgba(255, 255, 255, 0)
        calc(100% - ${(p) => (p.hideRight ? '24px' : '0px')}),
      rgba(255, 255, 255, 0) 100%
    );
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACE_BETWEEN_TABS_PX}px;
  overflow-x: hidden;
  width: max-content;
  @media screen and ${mediaQuery.medium} {
    gap: ${MOBILE_SPACE_BETWEEN_TABS_PX}px;
  }
`;

const useTabs = (activeTab: number) => {
  const [rectsByIndex, setRectsByIndex] = useState<Record<number, Rect>>({});
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const isMedium = useMediaQuery({ query: mediaQuery.medium });
  const [scrollAmount, setScrollAmount] = useState(0);
  const [sliderProps, setSliderProps] = useState<{
    left: number;
    width: number;
    fullWidth: number;
  }>({ left: 0, width: 0, fullWidth: 0 });

  const onScroll = useCallback(() => {
    const element = scrollRef.current;
    if (!element) return;
    setScrollAmount(
      element.scrollLeft / (element.scrollWidth - element.clientWidth),
    );
  }, [scrollRef]);

  const onResize = useCallback(() => {
    if (scrollRef.current) {
      onScroll();
      setIsOverflowing(
        scrollRef.current.clientWidth < scrollRef.current.scrollWidth,
      );
    }
  }, [scrollRef, onScroll]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [scrollRef, onResize]);

  const onMeasureTab = useCallback(
    (tabIndex: number, rect: Rect) =>
      setRectsByIndex((rects) => ({ ...rects, [tabIndex]: rect })),
    [setRectsByIndex],
  );

  const getTabLeftDistance = useCallback(
    (tab?: number) => {
      const gap = isMedium
        ? MOBILE_SPACE_BETWEEN_TABS_PX
        : SPACE_BETWEEN_TABS_PX;
      return Object.keys(rectsByIndex)
        .map((key) => parseInt(key, 10))
        .filter((index) => (tab !== undefined ? index < tab : true))
        .reduce((offset, index) => offset + rectsByIndex[index].width + gap, 0);
    },
    [rectsByIndex, isMedium],
  );

  const scrollToTab = useCallback(
    (tab: number) => {
      const element = scrollRef.current;
      if (!element) return;
      const leftDistance = getTabLeftDistance(tab);
      const tabWidth = rectsByIndex[tab]?.width;
      const wrapperWidth = scrollRef.current.clientWidth;
      const scrollPosition = leftDistance - wrapperWidth / 2 + tabWidth / 2;
      window.setTimeout(() => {
        if (!scrollRef.current) return;
        scrollRef.current.scrollTo({
          behavior: 'smooth',
          top: 0,
          left: scrollPosition,
        });
      }, animationDurationNumbers.short);
    },
    [getTabLeftDistance, rectsByIndex],
  );

  useEffect(() => {
    const activeTabRect = rectsByIndex[activeTab];
    const gap = isMedium ? MOBILE_SPACE_BETWEEN_TABS_PX : SPACE_BETWEEN_TABS_PX;
    scrollToTab(activeTab);
    if (activeTabRect) {
      setSliderProps({
        width: activeTabRect.width,
        left: getTabLeftDistance(activeTab),
        fullWidth: getTabLeftDistance() - gap,
      });
    }
  }, [
    rectsByIndex,
    activeTab,
    setSliderProps,
    isMedium,
    getTabLeftDistance,
    scrollToTab,
  ]);

  return {
    sliderProps,
    onMeasureTab,
    scrollRef,
    isOverflowing,
    scrollAmount,
    isMedium,
    onScroll,
    scrollToTab,
  };
};

export interface TabsProps {
  ariaLabel?: string;
  activeTab: number;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
}

export const Tabs = (props: PropsWithChildren<TabsProps>) => {
  const {
    sliderProps,
    onMeasureTab,
    scrollRef,
    isOverflowing,
    scrollAmount,
    isMedium,
    onScroll,
    scrollToTab,
  } = useTabs(props.activeTab);

  const showLeftArrow =
    isMedium && Math.round(scrollAmount * 100) > 0 && isOverflowing;
  const showRightArrow =
    isMedium && Math.round(scrollAmount * 100) < 100 && isOverflowing;
  const childCount = React.Children.count(props.children);

  const increaseTab = (num: number) => {
    const nextTab = props.activeTab + num;
    if (!props.setActiveTab || nextTab < 0 || nextTab > childCount - 1) {
      scrollToTab(props.activeTab);
      return;
    }
    props.setActiveTab(nextTab);
    scrollToTab(nextTab);
  };

  return (
    <Wrapper role="tablist" aria-label={props.ariaLabel}>
      <ScrollWrapper
        ref={scrollRef}
        hideLeft={showLeftArrow}
        hideRight={showRightArrow}
        onScroll={onScroll}
      >
        <TabsWrapper>
          {React.Children.map(props.children, (element, index) => {
            if (React.isValidElement(element)) {
              return React.cloneElement(element, {
                isActive: index === props.activeTab && childCount > 1,
                tabIndex: index,
                onMeasure: onMeasureTab,
                isClickable: childCount > 1,
              } as any);
            }
            return element;
          })}
        </TabsWrapper>
        <Underline width={sliderProps.fullWidth}>
          {sliderProps.width > 0 && childCount > 1 && (
            <Slider {...sliderProps} />
          )}
        </Underline>
      </ScrollWrapper>
      <OverFlowArrowButtons
        showLeft={showLeftArrow}
        showRight={showRightArrow}
        onLeftClick={() => increaseTab(-1)}
        onRightClick={() => increaseTab(1)}
      />
    </Wrapper>
  );
};
