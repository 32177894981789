import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { ArrowForward } from '../icons';
import { bodyMStyles, LaasLink } from '../typography';

const LinkContainer = styled(LaasLink)`
  ${bodyMStyles}

  display: flex;
  gap: 8px;
  align-items: center;
`;

const LinkArrow = styled(ArrowForward)`
  width: 16px;
  height: 16px;
`;

export const NavigationLink = (props: {
  text: ReactNode;
  onClick?: () => void;
}) => (
  <LinkContainer onClick={props.onClick}>
    {props.text} <LinkArrow />
  </LinkContainer>
);
