import { ProfilePicturePlaceholder } from './ProfilePicturePlaceholder';
import React from 'react';
import {
  defaultProfilePictureSize,
  personIsNamed,
  PersonProps,
  ProfilePictureDimensionsProps,
} from './common';
import styled from '@emotion/styled';
import Image from 'next/image';
import { brandColors } from '../constants';
import { getImageUrl } from '../../common/getImageUrl';

const CircleImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'pictureSize',
})<{ pictureSize: number }>`
  background: ${brandColors.laasPurple10};
  object-fit: cover;
  height: ${(p) => p.pictureSize}px;
  width: ${(p) => p.pictureSize}px;
  border-radius: ${(p) => p.pictureSize}px;
  border: ${(p) => (p.pictureSize > 48 ? 1 : 2)}px solid
    ${brandColors.laasPurple20};
`;

export interface ProfilePictureProps
  extends PersonProps,
    ProfilePictureDimensionsProps {
  ProfilePicturePlaceholderComponent?: typeof ProfilePicturePlaceholder;
}

export const ProfilePicture = ({
  pictureSize = defaultProfilePictureSize,
  ProfilePicturePlaceholderComponent = ProfilePicturePlaceholder,
  ...props
}: ProfilePictureProps) => {
  return props.profilePictureSrc ? (
    <CircleImage
      alt="Profile image"
      src={props.profilePictureSrc}
      width={pictureSize}
      height={pictureSize}
      pictureSize={pictureSize}
      loader={({ width, src }) =>
        getImageUrl(`unsafe/${width}x${width}/smart/${src}`)
      }
    />
  ) : (
    <ProfilePicturePlaceholderComponent
      {...props}
      pictureSize={pictureSize}
      initials={
        personIsNamed(props)
          ? `${props.firstName[0]}${props.lastName[0]}`.toLocaleUpperCase()
          : undefined
      }
    />
  );
};
