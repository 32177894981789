import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Spacer } from '@laasco/ui-lib/Spacer';
import { textColors } from '@laasco/ui-lib/typography/semantic/textColors';
import Link from 'next/link';
import React, { useContext } from 'react';
import { UnstyledButton } from '../../../UnstyledButton';
import { UnstyledLink } from '../../../UnstyledLink';
import { ExpandButton } from '../../../buttons';
import {
  animationDurationNumbers,
  brandColors,
  mediaQuery,
  menuHeight,
} from '../../../constants';
import { LargeCloseIcon } from '../../../icons';
import { HamburgerMenu } from '../../../icons/HamburgerMenu';
import { Drawer } from '../../../modal/Drawer';
import { Subtitle2, body1Styles } from '../../../typography';
import {
  DropDownMenuProps,
  LinkMenuItemProps,
  NavGridMenuCategoryProps,
  NavGridMenuItemProps,
  NavGridMenuProps,
  NavbarProps,
  TopMenuItemProps,
} from '../types';
import { NavbarBar } from './NavbarBar';

const StyledBar = styled(NavbarBar)`
  @media screen and ${mediaQuery.widerThanLarge} {
    display: none;
  }
`;

const ToggleMenuButton = styled(UnstyledButton)`
  position: relative;
`;

const ExtendedClickArea = styled.div`
  width: 48px;
  height: 48px;
  background: none;
  position: absolute;
  top: -12px;
  left: -12px;
`;

const activeStyles = css`
  font-weight: 700;
  color: ${brandColors.coalGrey};
`;

const openStyles = css`
  font-weight: 700;
  color: ${brandColors.laasPurple};
`;

interface MobileMenuContextProps {
  leftPadPx: number;
  hasBorders: boolean;
  itemBackgroundColor: string;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
}

const MobileMenuContext = React.createContext<MobileMenuContextProps>(
  null as never,
);

const MenuItemContainer = styled.div<{
  depth: number;
  isOpen?: boolean;
  isActive: boolean;
  leftPadPx: number;
  background: string;
}>`
  ${body1Styles}
  height: 44px;
  background: ${(p) => p.background};
  font-style: normal;
  font-weight: 400;
  color: ${brandColors.laasPurple};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 ${(p) => p.depth * p.leftPadPx}px;
  ${(props) => props.isActive && activeStyles};
  ${(props) => props.isOpen && openStyles};
  cursor: pointer;
`;

type PropsWithDepth<T> = T & { depth: number };

const LabelWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const LinkMenuItem = ({
  depth,
  ...props
}: PropsWithDepth<LinkMenuItemProps>) => {
  const { leftPadPx, itemBackgroundColor, setIsBurgerMenuOpen } =
    useContext(MobileMenuContext);
  return (
    <Link href={props.href || '#'} passHref legacyBehavior>
      <UnstyledLink
        onClick={() => {
          props.onClick?.();
          setIsBurgerMenuOpen(false);
        }}
        data-test={props.dataTest}
      >
        <MenuItemContainer
          background={itemBackgroundColor}
          leftPadPx={leftPadPx}
          depth={depth}
          isActive={!!props.active}
        >
          <LabelWithIcon>
            {props.icon}
            {props.label}
          </LabelWithIcon>
        </MenuItemContainer>
      </UnstyledLink>
    </Link>
  );
};

const DropDownMenu = ({
  depth,
  ...props
}: PropsWithDepth<
  DropDownMenuProps | NavGridMenuCategoryProps | NavGridMenuItemProps
>) => {
  const [isOpen, setIsOpen] = React.useState(!!props.active);
  const { leftPadPx, hasBorders, itemBackgroundColor } =
    useContext(MobileMenuContext);
  const [collapseRef] = useAutoAnimate<HTMLDivElement>({
    duration: animationDurationNumbers.short,
    easing: 'linear',
  });

  return (
    <>
      <MenuItemContainer
        data-test={props.dataTest}
        background={itemBackgroundColor}
        depth={depth}
        isOpen={isOpen}
        leftPadPx={leftPadPx}
        isActive={!!props.active}
        onClick={() => {
          props.onClick && props.onClick();
          setIsOpen(!isOpen);
        }}
      >
        <LabelWithIcon>
          {'icon' in props ? props.icon : null}
          {props.label}
        </LabelWithIcon>
        <ExpandButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </MenuItemContainer>
      <LinkMenuItemsContainer
        hasBorders={hasBorders}
        ref={collapseRef}
        style={{ borderBottom: 'none' }}
      >
        {isOpen &&
          props.children.map((item, i) => (
            <MenuItem
              {...item}
              key={`${item.type}-${i}`}
              depth={depth + 1}
              dataTest={[props.dataTest, item.dataTest].join('-')}
            />
          ))}
      </LinkMenuItemsContainer>
    </>
  );
};

const NavGridMenu = ({ depth, ...props }: PropsWithDepth<NavGridMenuProps>) => {
  return (
    <>
      {props.children.length > 1
        ? props.children.map((category, i) => (
            <DropDownMenu
              {...category}
              depth={depth}
              dataTest={[props.dataTest, category.dataTest].join('-')}
              key={`${category.type}-${i}`}
            />
          ))
        : props.children.length === 1
        ? props.children[0].children.map((item, i) => (
            <DropDownMenu
              {...item}
              depth={depth}
              key={`${item.type}-${i}`}
              dataTest={[props.dataTest, item.dataTest].join('-')}
            />
          ))
        : null}
    </>
  );
};

const MenuItem = ({
  depth,
  ...props
}: PropsWithDepth<TopMenuItemProps | NavGridMenuItemProps>) => {
  switch (props.type) {
    case 'LinkMenuItem':
      return <LinkMenuItem {...props} depth={depth} />;
    case 'DropDownMenu':
      return <DropDownMenu {...props} depth={depth} />;
    case 'NavGridMenu':
      return <NavGridMenu {...props} depth={depth} />;
    case 'NavGridMenuItem':
      return <DropDownMenu {...props} depth={depth} />;
  }
};

const StyledDrawer = styled(Drawer)`
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - ${menuHeight.mobile}px);
  overflow-y: scroll;
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  border-top: 8px solid ${brandColors.lightPurpleBorderAA};
`;

const linkMenuItemsBorderStyles = css`
  > div {
    border-bottom: 1px solid ${brandColors.laasPurple10};
  }
  > a > * {
    border-bottom: 1px solid ${brandColors.laasPurple10};
  }
`;

const LinkMenuItemsContainer = styled.div<{ hasBorders: boolean }>`
  ${(p) => p.hasBorders && linkMenuItemsBorderStyles};
  position: relative;
`;

const UserMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${brandColors.laasPurple10};
  > *:nth-child(1) {
    padding: 0 0 0 24px;
    margin-top: 16px;
  }
  padding-bottom: 200px;
`;

const MobileUserMenuLabel = styled(Subtitle2)`
  color: ${textColors.primary};
`;

export const MobileNavbar = (props: NavbarProps) => {
  return (
    <>
      <StyledBar
        {...props}
        alwaysOpaque={props.isBurgerMenuOpen ? true : props.alwaysOpaque}
      >
        <ToggleMenuButton
          data-test="mobile-nav-burger"
          onClick={() => props.setIsBurgerMenuOpen(!props.isBurgerMenuOpen)}
        >
          {props.isBurgerMenuOpen ? <LargeCloseIcon /> : <HamburgerMenu />}
          <ExtendedClickArea />
        </ToggleMenuButton>
      </StyledBar>
      <StyledDrawer
        backdropStyles={{ top: menuHeight.mobile }}
        isOpen={props.isBurgerMenuOpen}
        onClose={() => props.setIsBurgerMenuOpen(false)}
      >
        <MobileMenuContext.Provider
          value={{
            leftPadPx: 16,
            hasBorders: true,
            itemBackgroundColor: 'white',
            setIsBurgerMenuOpen: props.setIsBurgerMenuOpen,
          }}
        >
          <LinkMenuItemsContainer hasBorders>
            {props.menuItems.map((item, i) => (
              <MenuItem
                {...item}
                depth={1}
                key={`${item.type}-${i}`}
                dataTest={`navbar-${item.dataTest}`}
              />
            ))}
          </LinkMenuItemsContainer>
        </MobileMenuContext.Provider>
        <MobileMenuContext.Provider
          value={{
            leftPadPx: 24,
            hasBorders: false,
            itemBackgroundColor: brandColors.laasPurple10,
            setIsBurgerMenuOpen: props.setIsBurgerMenuOpen,
          }}
        >
          <UserMenuContainer>
            <MobileUserMenuLabel>
              {props.mobileUserMenuLabel}
            </MobileUserMenuLabel>
            <Spacer height={8} />
            {props.userMenuItems.map((item, i) => (
              <MenuItem
                {...item}
                depth={1}
                key={`${item.type}-${i}`}
                dataTest={`navbar-user-${item.dataTest}`}
              />
            ))}
          </UserMenuContainer>
        </MobileMenuContext.Provider>
      </StyledDrawer>
    </>
  );
};
