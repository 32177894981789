import React from 'react';

export const useRenderClientSide = () => {
  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    setRender(true);
  }, []);
  return render;
};
