import React from 'react';

export const ReviewHeartIcon = (props: React.SVGAttributes<any>) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 16.3307L8.23125 15.1757C3.725 11.0894 0.75 8.39441 0.75 5.08691C0.75 2.39191 2.8675 0.274414 5.5625 0.274414C7.085 0.274414 8.54625 0.983164 9.5 2.10316C10.4537 0.983164 11.915 0.274414 13.4375 0.274414C16.1325 0.274414 18.25 2.39191 18.25 5.08691C18.25 8.39441 15.275 11.0894 10.7688 15.1844L9.5 16.3307Z"
      fill="currentColor"
    />
  </svg>
);
