import React, { createContext } from 'react';

export const NavChildrenContext = createContext<{
  openId: string | null;
  setOpenId: (id: null | string) => any;
}>({
  openId: null,
  setOpenId: () => {},
});

export const NavChildrenContextProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [openId, setOpenId] = React.useState<string | null>(null);

  return (
    <NavChildrenContext.Provider
      value={{
        openId,
        setOpenId,
      }}
    >
      {children}
    </NavChildrenContext.Provider>
  );
};
