import Select, { ActionMeta, createFilter } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { DropdownIndicator } from './common/DropdownIndicator';
import { MultiValueContainer } from './common/MultiValueContainer';
import { MultiValueRemove } from './common/MultiValueRemove';
import {
  basicSelectOption,
  TreeItemData,
  useCustomSelect,
  useTreeState,
} from './common/treeSelectUtils';
import {
  MultiSelectTreeItemOption,
  TreeItemOptionData,
} from './common/TreeItemOption';
import { treeSelectStyles } from './common/treeSelectStyles';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from '../constants';
import { setMenuPortalTargetProps } from './common/setMenuPortalTargetProps';

export interface TreeSelectProps
  extends Omit<
    StateManagerProps<TreeItemOptionData, true>,
    'value' | 'onChange' | 'options'
  > {
  disabled?: boolean;
  hasErrors?: boolean;
  value?: TreeItemData[];
  onChange?: (
    newValue: TreeItemData[],
    actionMeta: ActionMeta<TreeItemOptionData>,
  ) => void;
  treeData: TreeItemData[];
  singleSelectMode?: boolean;
  placeholder: ReactNode;
}

export const TreeSelect = ({
  disabled,
  value,
  onChange,
  treeData,
  singleSelectMode = false,
  ...selectProps
}: TreeSelectProps) => {
  const {
    checkedNodeIds,
    setCheckedNodeIds,
    setCollapsed,
    options,
    optionPropsById,
    treeItems,
    numberOfCheckedOptions,
    reactSelectValue,
  } = useTreeState(treeData, false, value);

  const selectRef = useCustomSelect({
    setCollapsed,
    selectOption: basicSelectOption({
      checkedNodeIds,
      optionPropsById,
      treeItems,
      options,
    }),
  });

  const isSmall = useMediaQuery({ query: mediaQuery.small });
  const { isSearchable = !isSmall } = selectProps;

  return (
    <Select
      {...selectProps}
      isSearchable={isSearchable}
      value={reactSelectValue}
      isDisabled={disabled}
      options={options}
      ref={selectRef}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      styles={treeSelectStyles}
      onChange={(newValue, meta) => {
        if (singleSelectMode) {
          const nodeId = meta.option?.value;
          setCheckedNodeIds(
            new Set(newValue.length === 0 ? [] : [nodeId as string]),
          );
          const nodeIds = treeData.filter((n) => n.id === nodeId);
          onChange?.(nodeIds, meta);
        } else {
          const nodeIds = new Set(newValue.map((v) => v.value));
          setCheckedNodeIds(nodeIds);
          onChange?.(
            treeData.filter(({ id }) => nodeIds.has(id)),
            meta,
          );
        }
      }}
      filterOption={(option, inputValue) => {
        if (inputValue) {
          return createFilter({})(option, inputValue);
        }
        return !optionPropsById[option.value].isHidden;
      }}
      isOptionSelected={({ value }) =>
        checkedNodeIds.has(value) || optionPropsById[value].isParentChecked
      }
      components={{
        ValueContainer: MultiValueContainer,
        Option: MultiSelectTreeItemOption,
        DropdownIndicator,
        MultiValueRemove,
      }}
      {...setMenuPortalTargetProps(selectProps.menuPortalTarget)}
      // Custom props
      {...{
        optionPropsById,
        setCollapsed,
        numberOfCheckedOptions,
      }}
    />
  );
};
