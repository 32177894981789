import { SVGAttributes } from 'react';

export const PersonBlack = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#907BA6"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99935 3.99996C8.73268 3.99996 9.33268 4.59996 9.33268 5.33329C9.33268 6.06663 8.73268 6.66663 7.99935 6.66663C7.26602 6.66663 6.66602 6.06663 6.66602 5.33329C6.66602 4.59996 7.26602 3.99996 7.99935 3.99996ZM7.99935 10.6666C9.79935 10.6666 11.866 11.5266 11.9993 12H3.99935C4.15268 11.52 6.20602 10.6666 7.99935 10.6666ZM7.99935 2.66663C6.52602 2.66663 5.33268 3.85996 5.33268 5.33329C5.33268 6.80663 6.52602 7.99996 7.99935 7.99996C9.47268 7.99996 10.666 6.80663 10.666 5.33329C10.666 3.85996 9.47268 2.66663 7.99935 2.66663ZM7.99935 9.33329C6.21935 9.33329 2.66602 10.2266 2.66602 12V13.3333H13.3327V12C13.3327 10.2266 9.77935 9.33329 7.99935 9.33329Z"
      fill="current-color"
    />
  </svg>
);
