import { brandColors } from '../constants';

export const Gear = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill={brandColors.laasPurple}
    {...props}
  >
    <path
      d="M17.1401 10.936C17.1761 10.636 17.2001 10.324 17.2001 10C17.2001 9.67602 17.1761 9.36403 17.1281 9.06403L19.1561 7.48002C19.3361 7.33602 19.3841 7.07202 19.2761 6.86802L17.3561 3.54402C17.2361 3.32802 16.9841 3.25602 16.7681 3.32802L14.3801 4.28802C13.8761 3.90402 13.3481 3.59202 12.7601 3.35202L12.4001 0.808024C12.3641 0.568024 12.1601 0.400024 11.9201 0.400024H8.08011C7.84011 0.400024 7.64811 0.568024 7.61211 0.808024L7.25211 3.35202C6.66411 3.59202 6.12411 3.91602 5.63211 4.28802L3.24411 3.32802C3.02811 3.24402 2.77611 3.32802 2.65611 3.54402L0.736107 6.86802C0.616107 7.08402 0.664107 7.33602 0.856107 7.48002L2.88411 9.06403C2.83611 9.36403 2.80011 9.68802 2.80011 10C2.80011 10.312 2.82411 10.636 2.87211 10.936L0.844107 12.52C0.664106 12.664 0.616107 12.928 0.724107 13.132L2.64411 16.456C2.76411 16.672 3.01611 16.744 3.23211 16.672L5.62011 15.712C6.12411 16.096 6.65211 16.408 7.24011 16.648L7.60011 19.192C7.64811 19.432 7.84011 19.6 8.08011 19.6H11.9201C12.1601 19.6 12.3641 19.432 12.3881 19.192L12.7481 16.648C13.3361 16.408 13.8761 16.084 14.3681 15.712L16.7561 16.672C16.9721 16.756 17.2241 16.672 17.3441 16.456L19.2641 13.132C19.3841 12.916 19.3361 12.664 19.1441 12.52L17.1401 10.936V10.936ZM10.0001 13.6C8.02011 13.6 6.40011 11.98 6.40011 10C6.40011 8.02002 8.02011 6.40002 10.0001 6.40002C11.9801 6.40002 13.6001 8.02002 13.6001 10C13.6001 11.98 11.9801 13.6 10.0001 13.6Z"
      fill="#4D2A6F"
    />
  </svg>
);
