import styled from '@emotion/styled';
import { Subtitle2 } from '@laasco/ui-lib/typography';
import { textColors } from '@laasco/ui-lib/typography/semantic/textColors';
import Link from 'next/link';
import { UnstyledLink } from '../../../UnstyledLink';
import { brandColors } from '../../../constants';
import { BodyXS, bodyLStyles } from '../../../typography/Typography';
import { NavChildrenContextProvider } from '../context/NavChildrenContext';
import { NavItemContextProvider } from '../context/NavItemContext';
import {
  LinkMenuItemProps,
  NavGridMenuCategoryProps,
  NavGridMenuItemProps,
  NavGridMenuProps,
} from '../types';
import { DesktopInnerDropDownMenu } from './DesktopInnerDropDownMenu';
import { DesktopNavDropdownButton } from './DesktopNavDropdownButton';
import { activeLinkStyles, hoverTextDecoration } from './styles';

const LinkMenuItemContainer = styled(UnstyledLink)``;

const LinkMenuItemLabel = styled.div<{ active: boolean }>`
  ${bodyLStyles};
  color: ${brandColors.laasPurple};
  :hover {
    ${hoverTextDecoration};
  }
  ${(p) => p.active && activeLinkStyles};
`;

const LinkMenuItem = (props: LinkMenuItemProps) => (
  <Link href={props.href || '#'} passHref legacyBehavior>
    <LinkMenuItemContainer
      onClick={props.onClick}
      data-test={props.dataTest && `navbar-${props.dataTest}`}
    >
      <LinkMenuItemLabel active={!!props.active}>
        {props.label}
      </LinkMenuItemLabel>
    </LinkMenuItemContainer>
  </Link>
);

const MenuItemLabel = styled(Subtitle2)`
  color: ${textColors.primary};
  margin-bottom: 4px;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 8px;
  width: 270px;
`;

export const MenuItem = (props: NavGridMenuItemProps) => {
  return (
    <MenuItemContainer>
      <MenuItemLabel>{props.label}</MenuItemLabel>
      {props.children.map((child, i) => {
        switch (child.type) {
          case 'DropDownMenu':
            return (
              <DesktopInnerDropDownMenu
                {...child}
                key={`${child.type}-${i}`}
                dataTest={
                  child.dataTest && `${props.dataTest}-${child.dataTest}`
                }
              />
            );
          case 'LinkMenuItem':
            return (
              <LinkMenuItem
                {...child}
                key={`${child.type}-${i}`}
                dataTest={
                  child.dataTest && `${props.dataTest}-${child.dataTest}`
                }
              />
            );
        }
      })}
    </MenuItemContainer>
  );
};

const CategoryLabel = styled(BodyXS)`
  text-transform: uppercase;
  color: ${brandColors.laasPurple};
`;

const MenuCategoryContainer = styled.div<{ columns: number }>`
  text-align: left;
  display: grid;

  grid-row-gap: 40px;
  grid-column-gap: 24px;
  grid-template-columns: repeat(${(p) => p.columns}, auto);
`;

const MenuCategorySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const MenuCategory = (
  props: NavGridMenuCategoryProps & { showLabel?: boolean },
) => {
  return (
    <MenuCategorySectionContainer>
      {props.showLabel && <CategoryLabel>{props.label}</CategoryLabel>}
      <MenuCategoryContainer columns={props.columns}>
        <NavChildrenContextProvider>
          {props.children.map((item, i) => (
            <NavItemContextProvider
              id={`${item.type}-${i}`}
              key={`${item.type}-${i}`}
            >
              <MenuItem {...item} dataTest={props.dataTest} />
            </NavItemContextProvider>
          ))}
        </NavChildrenContextProvider>
      </MenuCategoryContainer>
    </MenuCategorySectionContainer>
  );
};

const MenuContainer = styled.div`
  padding: 40px 32px 44px 72px;
  background: white;
  display: flex;
  flex-direction: row;
  border-bottom: ${brandColors.lightPurpleBorderAA} 8px solid;
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.1);
`;

export const NavGridMenu = (props: NavGridMenuProps) => {
  return (
    <DesktopNavDropdownButton
      onClick={props.onClick}
      label={props.label}
      active={!!props.active}
      fullHeightButton
      dataTest={props.dataTest}
    >
      <MenuContainer>
        {props.children.map((c, i) => (
          <MenuCategory
            {...c}
            key={`${props.type}-${i}`}
            showLabel={props.children.length > 1}
            dataTest={props.dataTest}
          />
        ))}
      </MenuContainer>
    </DesktopNavDropdownButton>
  );
};
