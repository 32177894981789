import React from 'react';

export const ServiceBackgroundCorner = (props: React.SVGAttributes<any>) => {
  return (
    <svg
      width="99"
      height="126"
      viewBox="0 0 99 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.1"
        d="M44.1568 -28.4816C55.9574 -27.0952 84.5339 -22.1533 95.4644 -11.1533C109.495 2.96662 111.556 22.9139 106.638 42.2457C101.721 61.5774 86.75 75.5674 58.7498 77.6496C30.7495 79.7318 1.66984 53.2271 0.249686 17.6496C-0.174055 7.03411 0.249373 6.6495 0.249771 2.14958C4.2947 -19.2226 25.2492 -30.7029 44.1568 -28.4816Z"
        fill="#9F8BB1"
      />
      <path
        opacity="0.1"
        d="M269.269 -42.3806C275.286 -11.1243 286.321 65.7258 270.219 101.785C249.548 148.073 206.636 167.948 159.71 169.488C112.784 171.028 121.777 103.895 45.9873 56.4409C-29.803 8.98674 19.1886 -82.2431 97.467 -111.911C120.823 -120.763 134.301 -118.553 155.907 -120.794C206.712 -126.064 259.628 -92.4612 269.269 -42.3806Z"
        fill="#D56048"
      />
    </svg>
  );
};
