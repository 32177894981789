import styled from '@emotion/styled';
import React, { useState } from 'react';
import { brandColors } from '../constants';
import { KeyboardArrowDown } from '../icons/KeyboardArrowDown';
import { Spacer } from '../Spacer';
import { body1Styles, HeadingM } from '../typography';
import { AccordionDrawer } from './AccordionDrawer';

const AccordionChevron = styled(KeyboardArrowDown, {
  shouldForwardProp: () => false,
})<{
  isOpen: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.1s ease-in-out;
  transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
  fill: ${(props) =>
    props.disabled ? brandColors.coalGrey20 : brandColors.laasPurple};
`;

const AccordionIconContainer = styled.div`
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
`;

const AccordionIcon = ({
  isOpen,
  disabled,
  className,
}: {
  isOpen: boolean;
  disabled?: boolean;
  className?: string;
}) => (
  <AccordionIconContainer className={className}>
    <AccordionChevron isOpen={isOpen} disabled={disabled} />
  </AccordionIconContainer>
);

export const AccordionMIcon = styled(AccordionIcon)`
  width: 32px;
  height: 32px;
`;

const CustomLabelContainer = styled.div<{
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  width: 100%;
`;

export const AccordionMLabelContainer = styled.div<{
  isOpen: boolean;
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 12px;

  border-bottom: 1px solid
    ${(props) => {
      if (props.disabled) {
        return brandColors.coalGrey20;
      }
      return props.isOpen ? brandColors.laasPurple40 : brandColors.laasPurple20;
    }};

  outline: none;
  ${(props) =>
    !props.disabled &&
    ` &:focus {
      & > div:last-child {
        border: 2px solid ${brandColors.laasPurple60};
      }
    };`}
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

export const AccordionMItemCountBubble = styled.span<{
  disabled?: boolean;
}>`
  display: inline-block;
  border-radius: 50px;
  background-color: ${brandColors.coalGrey8};

  color: ${({ disabled }) =>
    disabled ? brandColors.coalGrey50 : brandColors.coalGrey};

  ${body1Styles};
  padding: 4px 16px;
`;

export const AccordionMHeading = styled(HeadingM)``;

export interface AccordionProps {
  label: React.ReactNode;
  defaultOpen?: boolean;
  disabled?: boolean;
  itemCount?: number;
  className?: string;
  showBubbleOnDisabled?: boolean;
  dataTest?: string;
  renderCustomLabel?: (
    isOpen: boolean,
    props: AccordionProps,
  ) => React.ReactNode;
}

export const AccordionM: React.FunctionComponent<
  React.PropsWithChildren<AccordionProps>
> = (props) => {
  const [isOpen, setOpen] = useState(props.defaultOpen || false);
  const id = React.useId();

  return (
    <div className={props.className} data-test={props.dataTest}>
      {props.renderCustomLabel ? (
        <CustomLabelContainer
          id={id}
          aria-expanded={isOpen}
          aria-controls={id}
          disabled={props.disabled}
          tabIndex={0}
          onClick={() => !props.disabled && setOpen(isOpen ? false : true)}
          onKeyDown={(e) => {
            !props.disabled &&
              (e.key === 'Enter' || e.key === ' ') &&
              setOpen(isOpen ? false : true);
          }}
        >
          {props.renderCustomLabel(isOpen, props)}
        </CustomLabelContainer>
      ) : (
        <AccordionMLabelContainer
          id={id}
          isOpen={isOpen}
          aria-expanded={isOpen}
          aria-controls={id}
          tabIndex={0}
          disabled={props.disabled}
          onClick={() => !props.disabled && setOpen(isOpen ? false : true)}
          onKeyDown={(e) => {
            !props.disabled &&
              (e.key === 'Enter' || e.key === ' ') &&
              setOpen(isOpen ? false : true);
          }}
        >
          <AccordionHeaderContainer>
            <AccordionMHeading
              style={{
                color: props.disabled
                  ? brandColors.coalGrey20
                  : brandColors.laasPurple,
              }}
            >
              {props.label}
            </AccordionMHeading>
            {(!props.disabled || props.showBubbleOnDisabled) &&
              props.itemCount != null && (
                <AccordionMItemCountBubble disabled={props.disabled}>
                  {props.itemCount}
                </AccordionMItemCountBubble>
              )}
          </AccordionHeaderContainer>
          <Spacer width={8} />
          <AccordionMIcon isOpen={isOpen} disabled={props.disabled} />
        </AccordionMLabelContainer>
      )}
      <AccordionDrawer aria-labelledby={id} role="region" isOpen={isOpen}>
        {props.children}
      </AccordionDrawer>
    </div>
  );
};
