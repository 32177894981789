import { brandColors } from '../constants';

export const Help = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.83301 11.0002H8.16634V9.66683H6.83301V11.0002ZM7.49967 0.333496C3.81967 0.333496 0.833008 3.32016 0.833008 7.00016C0.833008 10.6802 3.81967 13.6668 7.49967 13.6668C11.1797 13.6668 14.1663 10.6802 14.1663 7.00016C14.1663 3.32016 11.1797 0.333496 7.49967 0.333496ZM7.49967 12.3335C4.55967 12.3335 2.16634 9.94016 2.16634 7.00016C2.16634 4.06016 4.55967 1.66683 7.49967 1.66683C10.4397 1.66683 12.833 4.06016 12.833 7.00016C12.833 9.94016 10.4397 12.3335 7.49967 12.3335ZM7.49967 3.00016C6.02634 3.00016 4.83301 4.1935 4.83301 5.66683H6.16634C6.16634 4.9335 6.76634 4.3335 7.49967 4.3335C8.23301 4.3335 8.83301 4.9335 8.83301 5.66683C8.83301 7.00016 6.83301 6.8335 6.83301 9.00016H8.16634C8.16634 7.50016 10.1663 7.3335 10.1663 5.66683C10.1663 4.1935 8.97301 3.00016 7.49967 3.00016Z"
        fill={props.fill || brandColors.laasPurple60}
      />
    </svg>
  );
};
