import styled from '@emotion/styled';
import { ChevronDown } from '../icons/ChevronDown';
import { animationDuration } from '../constants';
import { SupplementaryButton } from './SupplementaryButton';
import { PropsWithChildren } from 'react';

const Arrow = styled(ChevronDown)<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform ${animationDuration.short} ease-in-out;
`;

export interface ExpandButtonProps {
  isOpen: boolean;
  onClick: () => any;
  className?: string;
}
export const ExpandButton = ({
  children,
  isOpen,
  onClick,
  className,
}: PropsWithChildren<ExpandButtonProps>) => {
  return (
    <SupplementaryButton
      onClick={onClick}
      className={className}
      rightIcon={<Arrow isOpen={isOpen} />}
    >
      {children}
    </SupplementaryButton>
  );
};
