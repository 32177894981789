import styled from '@emotion/styled';
import color from 'color';
import _ from 'lodash';
import { StaticImageData } from 'next/image';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import useMeasure from 'react-use-measure';
import Truncate from '../Truncate';
import { animationDuration, brandColors, mediaQuery } from '../constants';
import { ServiceImage } from '../services/ServiceImage';
import { BodyS, HeadingS, bodyMStyles, headingXSStyles } from '../typography';
import { ServiceCardReviews } from './ServiceCardReviews';

const CardContainer = styled.article`
  background: #ffffff;
  border: 1px solid ${brandColors.coalGrey8};
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  border-radius: 4px;
  @media screen and ${mediaQuery.widerThanLarge} {
    max-width: 384px;
    width: 100%;
  }
  @media screen and ${mediaQuery.large} {
    width: 100%;
  }
`;

const GridContainer = styled.div<{ statusOffset: string }>`
  position: relative;
  display: grid;

  @media screen and ${mediaQuery.widerThanLarge} {
    height: 575px;
    max-height: 575px;
    grid-template-rows: auto 1fr;
    padding-bottom: 32px;
    width: 100%;
  }

  @media screen and ${mediaQuery.large} {
    padding: 24px;
    grid-template-columns: 144px 1fr;
    grid-template-rows: 1fr;
    gap: 0 32px;
    height: calc(192px + ${(p) => p.statusOffset});
    max-height: calc(192px + ${(p) => p.statusOffset});
  }
  @media screen and ${mediaQuery.medium} {
    grid-template-columns: 64px 1fr;
    grid-template-rows: auto;
    height: auto;
    max-height: initial;
  }
`;

const ImageContainer = styled.div`
  padding: 37px 56px;
  @media screen and ${mediaQuery.widerThanLarge} {
    grid-row: 1;
    padding: 37px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media screen and ${mediaQuery.large} {
    padding: 0;
    grid-column: 1;
    grid-row: 1-2;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and ${mediaQuery.widerThanLarge} {
    grid-row: 2;
    padding: 0 20px;
  }
  @media screen and ${mediaQuery.large} {
    grid-column: 2;
    grid-row: 1 / 2;
  }
  @media screen and ${mediaQuery.large} {
    grid-column: 2;
    grid-row: 1;
    justify-content: space-between;
  }
`;

const Title = styled(HeadingS)`
  max-height: 64px;
  color: currentColor;
  @media screen and ${mediaQuery.large} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media screen and ${mediaQuery.medium} {
    white-space: normal;
    ${headingXSStyles}
  }
`;

const Summary = styled.div`
  width: 100%;
  word-break: break-all;
  hyphens: auto;
  @media screen and ${mediaQuery.medium} {
    display: none;
  }
  ${bodyMStyles}
  color: currentColor;
`;

const contentHorizontalPaddingDesktop = 20;

const Review = styled(ServiceCardReviews)`
  justify-self: flex-end;
  margin-top: auto;
  @media screen and ${mediaQuery.widerThanLarge} {
    grid-row: 3;
  }
  @media screen and ${mediaQuery.large} {
    grid-column: 2;
    grid-row: 2;
  }
  @media screen and ${mediaQuery.medium} {
    grid-column: initial;
    grid-row: initial;
  }
`;

export interface ServiceCardStatusProps {
  indicatorColor: string;
  statusText: React.ReactNode;
}

const ServiceCardWideStatusText = styled(BodyS)`
  line-height: 18px;
`;

const ServiceCardWideStatusContainer = styled.div`
  display: none;
  align-items: center;
  padding: 4px 12px;

  position: absolute;
  top: 24px;
  left: 20px;

  border-radius: 13px;

  @media screen and ${mediaQuery.widerThanLarge} {
    display: flex;
  }
`;

const ServiceCardWideStatus = (props: ServiceCardStatusProps) => (
  <ServiceCardWideStatusContainer
    style={{ backgroundColor: props.indicatorColor }}
  >
    <ServiceCardWideStatusText
      style={{
        color: color(props.indicatorColor).isDark()
          ? brandColors.white
          : brandColors.coalGrey,
      }}
    >
      {props.statusText}
    </ServiceCardWideStatusText>
  </ServiceCardWideStatusContainer>
);

const ServiceCardNarrowStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and ${mediaQuery.widerThanLarge} {
    display: none;
  }
`;

const ServiceCardNarrowStatusIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

const ServiceCardNarrowStatusText = styled(BodyS)`
  color: ${brandColors.coalGrey};
`;

const ServiceCardNarrowStatus = (props: ServiceCardStatusProps) => (
  <ServiceCardNarrowStatusContainer>
    <ServiceCardNarrowStatusIndicator
      style={{ backgroundColor: props.indicatorColor }}
    />
    <ServiceCardNarrowStatusText>
      {props.statusText}
    </ServiceCardNarrowStatusText>
  </ServiceCardNarrowStatusContainer>
);

export const ServiceCardStatus = (props: ServiceCardStatusProps) => (
  <>
    <ServiceCardWideStatus {...props} />
    <ServiceCardNarrowStatus {...props} />
  </>
);

export interface ServiceCardProps {
  title: string;
  summary: string;
  starScore?: number;
  heartScore?: number;
  imageSrc: string | StaticImageData;
  className?: string;
  hideHeart?: boolean;
  status?: React.ReactNode;
  summaryLines?: number;
  extraContent?: React.ReactNode;
  isGreyedOut?: boolean;
  renderCardContent?: (content: React.ReactNode) => React.ReactNode;
}

export const ServiceCard = (props: ServiceCardProps) => {
  const isWiderThanLarge = useMediaQuery({ query: mediaQuery.widerThanLarge });
  const isLarge = useMediaQuery({ query: mediaQuery.large });
  const isMedium = useMediaQuery({ query: mediaQuery.medium });
  const isGreyedOut = props.isGreyedOut ?? false;

  const summaryLines = props.summaryLines || isLarge ? 2 : 3;
  const [contentContainerRef, contentContainerBounds] = useMeasure();
  const truncateWidth =
    contentContainerBounds.width -
    2 * (isWiderThanLarge ? contentHorizontalPaddingDesktop : 0) -
    24;

  const { renderCardContent = _.identity } = props;

  return (
    <CardContainer className={props.className}>
      {renderCardContent(
        <GridContainer statusOffset={props.status ? '14px' : '0px'}>
          <ImageContainer
            style={{
              opacity: isGreyedOut ? 0.5 : 1,
            }}
          >
            <ServiceImage
              src={props.imageSrc}
              width={270}
              large={144}
              medium={64}
            />
          </ImageContainer>
          <ContentContainer
            ref={contentContainerRef}
            style={{
              color: isGreyedOut
                ? brandColors.coalGrey50
                : brandColors.laasPurple,
            }}
          >
            <Title>
              <Truncate
                lines={isLarge && !isMedium ? 1 : 2}
                trimWhitespace
                width={truncateWidth}
              >
                {props.title}
              </Truncate>
            </Title>
            {props.status}
            <Summary>
              <Truncate lines={summaryLines} width={truncateWidth}>
                <div dangerouslySetInnerHTML={{ __html: props.summary }} />
              </Truncate>
            </Summary>
            <Review {...props} />
          </ContentContainer>
        </GridContainer>,
      )}
      {props.extraContent}
    </CardContainer>
  );
};

export const LinkedServiceCard = styled(ServiceCard)`
  @media (hover: hover) {
    transition: all ${animationDuration.medium} linear;
    :hover {
      transform: translateY(-2px);
      box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.1);
    }
  }
`;
