import { ButtonHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import Measure, { Rect } from 'react-measure';
import styled from '@emotion/styled';
import { BodyL } from '../../typography';
import { animationDuration } from '../../constants';
import { NotificationBadgeWrapper } from '@laasco/ui-lib/indicator/NotificationBadgeWrapper';

const TabButton = styled.button<{ isClickable: boolean }>`
  padding: 8px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: ${(p) => (p.isClickable ? 'pointer' : 'default')};
  position: relative;
`;

const TabText = styled(BodyL)<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  letter-spacing: ${({ isActive }) => (isActive ? '0.294737px;' : '-0.22px')};
  transition: font-weight ${animationDuration.short} linear;
  white-space: nowrap;
`;

const NotificationWrapper = styled(NotificationBadgeWrapper)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  tabIndex?: number;
  onMeasure?: (tabIndex: number, rect: Rect) => void;
  showBadge?: boolean;
  childCountBubble?: ReactNode;
  isClickable?: boolean;
}

export const Tab = ({
  isActive,
  tabIndex,
  onMeasure,
  children,
  showBadge,
  childCountBubble,
  isClickable = true,
  ...buttonProps
}: PropsWithChildren<TabProps>) => (
  <Measure
    bounds
    onResize={(rect) => rect.bounds && onMeasure?.(tabIndex!, rect.bounds)}
  >
    {({ measureRef }) => (
      <NotificationWrapper
        showBadge={!!showBadge}
        right={!childCountBubble ? -8 : 0}
        top={4}
        sizePx={8}
        ref={measureRef}
      >
        <TabButton
          {...buttonProps}
          role="tab"
          as={isClickable ? 'button' : 'div'}
          aria-selected={isActive!}
          tabIndex={-1}
          disabled={!isClickable}
          isClickable={isClickable}
        >
          {/* Bold text is given layout priority to avoid layout shift when selecting a tab */}
          <TabText isActive style={{ opacity: 0 }}>
            {children}
          </TabText>
          <TabText isActive={isActive!} style={{ position: 'absolute' }}>
            {children}
          </TabText>
        </TabButton>
        {childCountBubble}
      </NotificationWrapper>
    )}
  </Measure>
);
