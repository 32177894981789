import styled from '@emotion/styled';
import { brandColors } from '../constants';

export const NotificationBadgeWrapper = styled.div<{
  showBadge: boolean;
  right?: number;
  top?: number;
  sizePx?: number;
}>`
  position: relative;
  &::after {
    content: '';
    display: ${(p) => (p.showBadge ? 'block' : 'none')};
    position: absolute;
    max-width: ${(p) => p.sizePx ?? 10}px;
    max-height: ${(p) => p.sizePx ?? 10}px;
    min-width: ${(p) => p.sizePx ?? 10}px;
    min-height: ${(p) => p.sizePx ?? 10}px;
    background-color: ${brandColors.laasPurple60};
    border-radius: ${(p) => p.sizePx ?? 10}px;
    right: ${(p) => p.right || 0}px;
    top: ${(p) => p.top || 0}px;
  }
`;
