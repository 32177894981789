import styled from '@emotion/styled';
import { animated, useTransition } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import {
  animationDuration,
  animationDurationNumbers,
  zIndex,
} from '../constants';
import { useScrollLock } from '../useScrollLock';
import { ModalPortal } from './ModalPortal';

const BackDrop = styled(animated.div)`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.modal};
`;

export type BaseModalDialogProps = React.PropsWithChildren<{
  isOpen: boolean;
  onBackdropClick?: React.EventHandler<any>;
  dataTest?: string;
}>;

export const BaseModalDialog: React.FunctionComponent<BaseModalDialogProps> = ({
  isOpen,
  children,
  onBackdropClick,
  dataTest,
}) => {
  const [isScrollLockActive, setIsScrollLockActive] = useState(isOpen);

  // Fixes janky closing animation when scrollbars appear
  useEffect(() => {
    if (isOpen) {
      setIsScrollLockActive(true);
      return () => {};
    } else {
      const timer = setTimeout(
        () => setIsScrollLockActive(false),
        animationDurationNumbers.long,
      );
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const childRef = useScrollLock({ isActive: isScrollLockActive });

  const transition = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: isOpen,
    duration: animationDuration.long,
  });

  return (
    <>
      {transition(
        (styles, isOpen) =>
          isOpen && (
            <ModalPortal>
              <BackDrop
                data-test={dataTest}
                onClick={(e) => {
                  e.stopPropagation();
                  onBackdropClick?.(e);
                }}
                style={styles}
              >
                {React.cloneElement(children as any, { ref: childRef })}
              </BackDrop>
            </ModalPortal>
          ),
      )}
    </>
  );
};
