import { SVGProps } from 'react';

export const SentimentDissatisfied48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5 22C33.1569 22 34.5 20.6569 34.5 19C34.5 17.3431 33.1569 16 31.5 16C29.8431 16 28.5 17.3431 28.5 19C28.5 20.6569 29.8431 22 31.5 22Z"
      fill="currentColor"
    />
    <path
      d="M17.5 22C19.1569 22 20.5 20.6569 20.5 19C20.5 17.3431 19.1569 16 17.5 16C15.8431 16 14.5 17.3431 14.5 19C14.5 20.6569 15.8431 22 17.5 22Z"
      fill="currentColor"
    />
    <path
      d="M24.5 28C19.84 28 15.86 30.9 14.26 35H17.6C18.98 32.62 21.54 31 24.5 31C27.46 31 30 32.62 31.4 35H34.74C33.14 30.9 29.16 28 24.5 28V28ZM24.48 4C13.44 4 4.5 12.96 4.5 24C4.5 35.04 13.44 44 24.48 44C35.54 44 44.5 35.04 44.5 24C44.5 12.96 35.54 4 24.48 4ZM24.5 40C15.66 40 8.5 32.84 8.5 24C8.5 15.16 15.66 8 24.5 8C33.34 8 40.5 15.16 40.5 24C40.5 32.84 33.34 40 24.5 40Z"
      fill="currentColor"
    />
  </svg>
);
