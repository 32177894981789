import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { KeyboardArrowRight } from '../icons';
import { brandColors } from '../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: ${brandColors.laasPurple};
  flex-wrap: wrap;

  a {
    color: ${brandColors.laasPurple};
    text-decoration-color: ${brandColors.laasPurple};
  }
`;

const Arrow = styled(KeyboardArrowRight)`
  fill: ${brandColors.lightPurpleBorderAA};
`;

export const Breadcrumbs = (
  props: PropsWithChildren<{ className?: string }>,
) => {
  const childCount = React.Children.count(props.children);

  return (
    <Wrapper className={props.className}>
      {React.Children.map(props.children, (child, index) => {
        if (index < childCount - 1) {
          return (
            <>
              {child}
              <Arrow key={`after-${index}`} width={16} height={16} />
            </>
          );
        }
        return child;
      })}
    </Wrapper>
  );
};
