import React from 'react';
import styled from '@emotion/styled';
import { animationDuration, brandColors } from '../../constants';
import { ChevronLeft, ChevronRight } from '../../icons';
import { PlainButton } from '../../buttons';

const ArrowButton = styled(PlainButton)<{
  left?: string;
  right?: string;
  opacity: number;
  visibility: string;
}>`
  opacity: ${(p) => p.opacity}%;
  visibility: ${(p) => p.visibility};
  transition: opacity linear ${animationDuration.short},
    visibility linear ${animationDuration.short};
  margin: auto;
  width: 32px;
  height: 46px;
  border: none;
  background-color: unset;
  position: absolute;
  left: ${(p) => p.left || 'none'};
  right: ${(p) => p.right || 'none'};
  & svg,
  path {
    fill: ${brandColors.laasPurple};
  }
`;

const NextTabButton = (
  props: React.PropsWithChildren<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      left?: string;
      right?: string;
      visible: boolean;
    }
  >,
) => {
  return (
    <ArrowButton
      {...props}
      opacity={props.visible ? 100 : 0}
      visibility={props.visible ? 'visible' : 'hidden'}
    >
      {props.children}
    </ArrowButton>
  );
};

interface OverFlowArrowButtonsProps {
  showLeft: boolean;
  showRight: boolean;
  onLeftClick: () => any;
  onRightClick: () => any;
}

export const OverFlowArrowButtons = (props: OverFlowArrowButtonsProps) => {
  const positionOffset = '-8px';
  return (
    <>
      <NextTabButton
        left={positionOffset}
        onClick={props.onLeftClick}
        visible={props.showLeft}
      >
        <ChevronLeft />
      </NextTabButton>
      <NextTabButton
        right={positionOffset}
        onClick={props.onRightClick}
        visible={props.showRight}
      >
        <ChevronRight />
      </NextTabButton>
    </>
  );
};
