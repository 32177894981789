import { useMediaQuery } from 'react-responsive';
import ReactSelect, { components, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { brandColors, mediaQuery } from '../constants';
import { baseSelectStyles, body1StylesConfig } from './common/baseSelectStyles';
import { DropdownIndicator } from './common/DropdownIndicator';
import { Option } from './common/Option';
import { OptionType } from './common/OptionType';
import { setMenuPortalTargetProps } from './common/setMenuPortalTargetProps';

const styles: StylesConfig<OptionType, false> = {
  ...(baseSelectStyles as StylesConfig<OptionType, false>),
  singleValue: (styles) => ({
    ...styles,
    ...body1StylesConfig,
    fontWeight: 600,
    color: brandColors.laasPurple,
  }),
};

export type SelectProps<Value> = StateManagerProps<OptionType<Value>, false> & {
  disabled?: boolean;
  hasErrors?: boolean;
  dataTest?: string;
};

const SelectContainer = (props: any) => {
  return (
    <components.SelectContainer {...props}>
      <span data-test={props.selectProps.dataTest}>{props.children}</span>
    </components.SelectContainer>
  );
};

export const Select = <Value,>({
  hasErrors,
  disabled,
  ...props
}: SelectProps<Value>) => {
  const isSmall = useMediaQuery({ query: mediaQuery.small });
  const { isSearchable = !isSmall } = props;

  return (
    <ReactSelect
      {...props}
      isSearchable={isSearchable}
      isDisabled={disabled}
      styles={styles as StylesConfig<OptionType<Value>, false>}
      isMulti={false}
      components={{
        DropdownIndicator,
        Option,
        IndicatorSeparator: null,
        SelectContainer,
      }}
      {...setMenuPortalTargetProps(props.menuPortalTarget)}
      // Custom props
      {...{
        hasErrors,
      }}
    />
  );
};
