import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { SupplementaryButton } from '../buttons';
import { mediaQuery } from '../constants';
import { CloseIcon } from '../icons';
import { BaseModalDialog, BaseModalDialogProps } from './BaseModalDialog';
import { AnimationPlaceholder } from './assets/AnimationPlaceholder';

type ModalSize = 'medium' | 'large' | 'full-screen';

const sizeToWidth: { [k in ModalSize]: string } = {
  medium: `552px`,
  large: `960px`,
  'full-screen': '100vw',
};
const fullScreenStyles = css`
  max-height: 100vh;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
`;

const DialogContainer = styled.div<{
  size: ModalSize;
  takeFullAvailableWidth?: boolean;
}>`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  /* Varjo */

  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  border-radius: 4px;
  margin: 9vh 0 16vh 0;

  max-width: ${(props) => sizeToWidth[props.size]};
  width: ${(props) =>
    props.takeFullAvailableWidth ? sizeToWidth[props.size] : 'auto'};
  max-height: calc(100vh - 9vh - 16vh);

  position: relative;
  -webkit-overflow-scrolling: touch;

  overflow-x: hidden;
  overflow-y: auto;
  padding: 64px 24px 24px 24px;

  @media screen and ${mediaQuery.medium} {
    ${fullScreenStyles};
  }

  ${(props) => (props.size === 'full-screen' ? fullScreenStyles : '')}
`;

const StyledAnimationPlaceholder = styled(AnimationPlaceholder)`
  position: absolute;
  left: -2px;
  top: 0;
  pointer-events: none;
`;

export const LegacyModalDialogLayout = React.forwardRef<
  HTMLDivElement,
  Pick<
    LegacyModalDialogProps,
    'children' | 'size' | 'className' | 'takeFullAvailableWidth'
  >
>((props, ref) => (
  <DialogContainer
    size={props.size!}
    onClick={(e) => e.stopPropagation()}
    takeFullAvailableWidth={props.takeFullAvailableWidth}
    className={props.className}
    ref={ref}
  >
    <StyledAnimationPlaceholder />
    {props.children}
  </DialogContainer>
));

LegacyModalDialogLayout.displayName = 'BasicModalDialogLayout';

const CloseButtonLabelText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.05em;
`;

const CloseButton = styled(SupplementaryButton)`
  position: absolute;
  top: 16px;
  right: 28px;
  margin-left: auto;
`;

export const LegacyCloseModalButton = (props: {
  onClick: React.EventHandler<any>;
  label: React.ReactNode;
}) => {
  const isSmall = useMediaQuery({ query: mediaQuery.small });
  return (
    <CloseButton onClick={props.onClick} icon={<CloseIcon />}>
      {isSmall ? (
        <CloseButtonLabelText>{props.label}</CloseButtonLabelText>
      ) : null}
    </CloseButton>
  );
};

export interface LegacyModalDialogProps extends BaseModalDialogProps {
  onCloseClick: React.EventHandler<any>;
  closeButtonLabel: React.ReactNode;
  size?: ModalSize;
  className?: string;
  dataTest?: string;
  takeFullAvailableWidth?: boolean;
}

export const LegacyModalDialog: React.FunctionComponent<
  LegacyModalDialogProps
> = (props) => {
  const {
    children,
    isOpen,
    onCloseClick,
    onBackdropClick,
    closeButtonLabel,
    size = 'medium',
    className,
    dataTest,
    takeFullAvailableWidth,
  } = props;
  return (
    <BaseModalDialog
      isOpen={isOpen}
      onBackdropClick={onBackdropClick}
      dataTest={dataTest}
    >
      <LegacyModalDialogLayout
        size={size}
        className={className}
        takeFullAvailableWidth={takeFullAvailableWidth}
      >
        <LegacyCloseModalButton
          onClick={onCloseClick}
          label={closeButtonLabel}
        />
        {children}
      </LegacyModalDialogLayout>
    </BaseModalDialog>
  );
};
