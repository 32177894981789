import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { body1Styles } from '../../typography';
import { brandColors, mediaQuery, zIndex } from '../../constants';

export const defaultModalPageHeadingHeightPx = 80;

const Container = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${zIndex.nav};

  display: flex;
  background-color: ${brandColors.laasPurple15};

  flex-direction: column;
  padding: 16px;
  gap: 16px;

  @media screen and ${mediaQuery.widerThanMedium} {
    flex-direction: row;
    height: ${defaultModalPageHeadingHeightPx}px;
    align-items: center;
    padding: 16px 32px 16px 32px;
    gap: 24px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 8px;

  flex-direction: column;

  @media screen and ${mediaQuery.widerThanSmall} {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;

  ${body1Styles}
  color: ${brandColors.laasPurple};
`;

export interface ModalPageHeadingProps {
  returnAction?: ReactNode;
  title?: ReactNode;
  actions?: ReactNode;
  className?: string;
}

export const ModalPageHeading = (props: ModalPageHeadingProps) => (
  <Container className={props.className}>
    {props.returnAction}
    <TitleContainer>{props.title}</TitleContainer>
    {props.actions && <ActionContainer>{props.actions}</ActionContainer>}
  </Container>
);
