import styled from '@emotion/styled';
import { PropsWithChildren, ReactNode } from 'react';
import {
  DefaultContentContainer,
  DefaultPageLayout,
} from './DefaultPageLayout';
import { HeadingL } from '../typography';
import { Spacer } from '../Spacer';

interface ResultPageLayoutProps {
  header: ReactNode;
  breadcrumbs: ReactNode;
  navbar: ReactNode;
  footer?: ReactNode;
}

const BreadCrumbsContainer = styled.div`
  padding-left: 40px;
`;

// Group results page is an exception in regards to the maximum width of the content
const ExtraWideContentContainer = styled(DefaultContentContainer)`
  @media screen and (min-width: 1441px) {
    max-width: 100%;
    padding: 0px 120px;
  }
`;

export const SurveyGroupResultPageLayout = (
  props: PropsWithChildren<ResultPageLayoutProps>,
) => {
  return (
    <DefaultPageLayout navbar={props.navbar} footer={props.footer}>
      <Spacer height={24} />
      <BreadCrumbsContainer>{props.breadcrumbs}</BreadCrumbsContainer>
      <Spacer height={72} />
      <ExtraWideContentContainer>
        <HeadingL>{props.header}</HeadingL>
        <Spacer height={24} />
        {props.children}
      </ExtraWideContentContainer>
      <Spacer height={240} />
    </DefaultPageLayout>
  );
};
