import styled from '@emotion/styled';
import { InputHTMLAttributes } from 'react';
import { brandColors, interactionColors } from '../constants';
import { Body2 } from '../typography';

const FilterButtonInput = styled.input`
  display: none;
`;

const FilterButtonLabel = styled(Body2.withComponent('label'))`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Container = styled.div<{ checked: boolean }>`
  display: flex;

  ${FilterButtonInput}:checked+${FilterButtonLabel} {
    background: ${brandColors.laasPurple20};
  }

  ${FilterButtonInput}:focus+${FilterButtonLabel} {
    background: ${interactionColors.elementHover};
  }

  &:hover {
    ${FilterButtonLabel} {
      ${(p) =>
        !p.checked &&
        `background: ${interactionColors.elementHover};
      `};
    }
  }
`;

export const FilterButton = ({
  children,
  className,
  ...inputProps
}: InputHTMLAttributes<HTMLInputElement>) => (
  <Container className={className} checked={!!inputProps.checked}>
    <FilterButtonInput type="radio" {...inputProps} />
    <FilterButtonLabel htmlFor={inputProps.id}>{children}</FilterButtonLabel>
  </Container>
);
