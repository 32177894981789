import { MenuPosition } from 'react-select';

export const setMenuPortalTargetProps = (
  menuPortalTarget?: HTMLElement | null,
) => {
  if (menuPortalTarget) {
    return {
      menuPortalTarget,
      menuPosition: 'fixed' as MenuPosition,
      menuShouldScrollIntoView: true,
      // TODO: Not ideal, but when false with menuPortalTarget, the menu is not positioned correctly on scroll
      menuShouldBlockScroll: true,
    };
  }
  return {};
};
