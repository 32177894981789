import { DefaultContentContainer, DefaultPageLayout } from '@laasco/ui-lib';
import { ErrorScreen } from '@laasco/ui-lib/error/ErrorScreen';
import { FormattedMessage } from 'react-intl';
import { useCurrentUser } from '../provider/UserProvider';
import { NavbarView } from './navbar/NavbarView';
import styled from '@emotion/styled';
import { brandColors, menuHeight } from '@laasco/ui-lib/constants';
import React from 'react';
import * as sentry from '@sentry/nextjs';
import { NavbarOnlyLogo } from '@laasco/ui-lib/navigation/Navbar/NavbarOnlyLogo';

const FullHeightContainer = styled(DefaultContentContainer)`
  height: calc(100vh - ${menuHeight.desktop}px);
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const MarginContainer = styled(DefaultContentContainer)`
  margin-top: 80px;
`;

export interface ErrorPageViewProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  error?: Error;
  forceHideNav?: true | false;
}

export const ErrorPageView = ({
  title,
  description,
  error,
  forceHideNav,
}: ErrorPageViewProps) => {
  let user = null;
  //This is fine because forceHideNav is restricted to a literal
  if (!forceHideNav) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    user = useCurrentUser();
  }
  const navbar = user && !forceHideNav ? <NavbarView /> : <NavbarOnlyLogo />;
  const Container = navbar ? MarginContainer : FullHeightContainer;

  React.useEffect(() => {
    if (error) {
      sentry.captureException(error);
    }
  }, [error]);

  return (
    <DefaultPageLayout navbar={navbar} background={brandColors.laasPurple5}>
      <Container>
        <ErrorScreen
          title={
            title || (
              <FormattedMessage
                defaultMessage={'Something went wrong'}
                description={'Shown when the application crashed, title'}
              />
            )
          }
          description={
            description || (
              <FormattedMessage
                description={'Shown when the application crashed, body'}
                defaultMessage={
                  'We have received a notification of this error and we will do our best to get it fixed as quick as possible.'
                }
              />
            )
          }
        />
      </Container>
    </DefaultPageLayout>
  );
};
