import styled from '@emotion/styled';
import { brandColors } from '../constants';

const Styled = styled.svg`
  position: relative;
  top: 2px;
`;

export const HamburgerMenu = (props: any) => (
  <Styled
    width="30"
    height="20"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 20H30V16.6667H0V20ZM0 11.6667H30V8.33333H0V11.6667ZM0 0V3.33333H30V0H0Z"
      fill={props.fill || brandColors.laasPurple}
    />
  </Styled>
);
