import styled from '@emotion/styled';
import Image, { StaticImageData } from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from '../constants';
import { useRenderClientSide } from '../useRenderClientSide';

const StyledImage = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

export interface ServiceImageProps {
  src: string | StaticImageData;
  width: number;
  large?: number;
  medium?: number;
  small?: number;
  className?: string;
}

const useImageSize = ({ width, large, medium, small }: ServiceImageProps) => {
  const isWiderThanLarge = useMediaQuery({
    query: mediaQuery.widerThanLarge,
  });
  const isWiderThanMedium = useMediaQuery({
    query: mediaQuery.widerThanMedium,
  });
  const isWiderThanSmall = useMediaQuery({
    query: mediaQuery.widerThanSmall,
  });
  const isSmall = useMediaQuery({
    query: mediaQuery.small,
  });

  if (isWiderThanLarge) {
    return width;
  } else if (isWiderThanMedium) {
    return large ?? width;
  } else if (isWiderThanSmall) {
    return medium ?? large ?? width;
  } else if (isSmall) {
    return small ?? medium ?? large ?? width;
  }
  return width;
};

/** @deprecated Don't use, causes content height change on client hydration. */
export const ServiceImage: React.FC<
  React.PropsWithChildren<ServiceImageProps>
> = (props) => {
  const size = useImageSize(props);

  const isClientSide = useRenderClientSide();
  if (!isClientSide) {
    return null;
  }

  return (
    <StyledImage
      className={props.className}
      alt="Service image"
      src={props.src}
      width={size}
      height={size}
    />
  );
};
