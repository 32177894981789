export const Knowledge = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M9.44377 36.6693C8.69377 36.6693 8.04331 36.3938 7.49239 35.8429C6.94147 35.292 6.66602 34.6415 6.66602 33.8915V6.11369C6.66602 5.36369 6.94147 4.71323 7.49239 4.16231C8.04331 3.6114 8.69377 3.33594 9.44377 3.33594H30.5549C31.3049 3.33594 31.9554 3.6114 32.5063 4.16231C33.0572 4.71323 33.3327 5.36369 33.3327 6.11369V33.8915C33.3327 34.6415 33.0572 35.292 32.5063 35.8429C31.9554 36.3938 31.3049 36.6693 30.5549 36.6693H9.44377ZM9.44377 33.8915H30.5549V6.11369H27.7771V17.3915L23.6939 15.0026L19.6105 17.3915V6.11369H9.44377V33.8915Z"
        fill="#A694B7"
      />
    </g>
  </svg>
);
