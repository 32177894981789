export const ArrowDown = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#64615D"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.667 10.0013L11.727 9.0613L8.66699 12.1146L8.66699 1.33464L7.33366 1.33464L7.33366 12.1146L4.27366 9.05464L3.33366 10.0013L8.00033 14.668L12.667 10.0013Z" />
  </svg>
);
