export const SentimentNeutral = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1405_5015)">
        <path d="M18.5635 28H30.4255V31H18.5635V28Z" fill="#4D2A6F" />
        <path
          d="M31.4138 22C33.0516 22 34.3793 20.6569 34.3793 19C34.3793 17.3431 33.0516 16 31.4138 16C29.7759 16 28.4482 17.3431 28.4482 19C28.4482 20.6569 29.7759 22 31.4138 22Z"
          fill="#4D2A6F"
        />
        <path
          d="M17.5749 22C19.2127 22 20.5404 20.6569 20.5404 19C20.5404 17.3431 19.2127 16 17.5749 16C15.9371 16 14.6094 17.3431 14.6094 19C14.6094 20.6569 15.9371 22 17.5749 22Z"
          fill="#4D2A6F"
        />
        <path
          d="M24.475 4C13.5618 4 4.72461 12.96 4.72461 24C4.72461 35.04 13.5618 44 24.475 44C35.4078 44 44.2648 35.04 44.2648 24C44.2648 12.96 35.4078 4 24.475 4ZM24.4947 40C15.7563 40 8.67863 32.84 8.67863 24C8.67863 15.16 15.7563 8 24.4947 8C33.2331 8 40.3108 15.16 40.3108 24C40.3108 32.84 33.2331 40 24.4947 40Z"
          fill="#4D2A6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1405_5015">
          <rect
            width="47.4483"
            height="48"
            fill="white"
            transform="translate(0.770508)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
