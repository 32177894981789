export const Food = (props: any) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75012 14.9835C3.63433 15.0781 3.44861 15.0548 3.35952 14.9638C2.09051 13.6643 1.22168 11.5668 1.22168 9.7179C1.22168 8.49904 1.60444 7.40229 2.33021 6.54573C2.42626 6.43289 2.59138 6.40997 2.71472 6.49264C2.83718 6.57502 2.87809 6.73659 2.81048 6.86799C2.40131 7.65378 2.19382 8.5469 2.19382 9.52182C2.19382 11.2007 2.79684 13.0981 3.80671 14.5971C3.89086 14.7216 3.8659 14.8886 3.75012 14.9835ZM4.75479 4.98742C4.09547 4.95522 3.40685 4.62454 3.12072 4.39017C1.23941 5.36247 0 7.12231 0 9.55191C0 11.86 1.09896 14.5918 2.84185 16.1216C4.17411 17.2906 5.44312 16.9295 7.00029 16.9295C8.55688 16.9289 9.85665 17.3544 11.1579 16.1216C12.8416 14.5274 14 11.8603 14 9.55191C14 6.16712 11.5952 4.08183 8.40046 3.61627C8.92774 2.98711 9.72548 2.43105 10.8955 2.41017C10.9739 2.53693 11.0824 2.61583 11.202 2.61583C11.4393 2.61583 11.632 2.30487 11.632 1.92112C11.632 1.53707 11.4393 1.22582 11.202 1.22582C11.0813 1.22582 10.9724 1.30675 10.8938 1.43583C9.24231 1.41002 7.95067 2.39973 7.17411 3.65021C6.8993 0.781731 5.56819 0 4.19907 0C2.56152 0 0.869704 1.11908 0.869704 1.11908C1.55049 0.969695 2.30615 1.29051 2.72316 2.64048C3.05078 3.70155 3.87667 4.7629 6.30238 4.20191C6.16135 3.9191 5.40308 2.17115 3.93297 1.44018L4.17934 1.22408C4.17934 1.22408 5.9083 1.80335 6.97446 4.00322C6.75044 4.43426 6.60302 4.85108 6.52525 5.1893C6.46431 5.45181 6.63088 5.70185 6.8906 5.77379C7.21909 5.86487 7.41991 5.59772 7.47504 5.4083C7.47795 5.39786 7.57023 5.01526 7.81196 4.52708C8.83894 4.65964 9.5798 5.03644 9.5798 5.48169C9.5798 6.03775 8.42484 6.48793 7.00029 6.48793C5.57516 6.48793 4.42049 6.03775 4.42049 5.48169C4.42049 5.30156 4.54237 5.13332 4.75479 4.98742Z"
      fill="#907BA6"
    />
  </svg>
);
