import styled from '@emotion/styled';
import { brandColors } from '../../constants';

export const CheckedCount = styled.span`
  display: flex;
  justify-content: center;
  padding: 0 7px;
  min-width: 34px;
  box-sizing: border-box;

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: ${brandColors.coalGrey};

  background: ${brandColors.coalGrey8};
  border-radius: 50vh;
`;
