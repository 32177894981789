import styled from '@emotion/styled';
import { shift } from '@floating-ui/react-dom';
import { zIndex } from '@laasco/ui-lib/constants';
import React, { PropsWithChildren } from 'react';
import { ExpandChevron } from '../../../ExpandChevron';
import { UnstyledButton } from '../../../UnstyledButton';
import { useFixedFloatingTooltip } from '../../../useFixedFloatingTooltip';
import { useRenderClientSide } from '../../../useRenderClientSide';
import {
  activeLinkStyles,
  hoverTextDecoration,
  topLinkButtonStyles,
} from './styles';
import { useIsOpen } from './useIsOpen';

const Container = styled(UnstyledButton)<{
  isOpen: boolean;
  active: boolean;
  fullHeight?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: left;
  ${topLinkButtonStyles};
  ${(p) => p.isOpen && hoverTextDecoration};
  ${(p) => p.active && activeLinkStyles};
  height: ${(p) => (p.fullHeight ? '100%' : 'auto')};
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

export interface DesktopNavDropdownButtonProps {
  onClick?: () => any;
  label: React.ReactNode;
  className?: string;
  active: boolean;
  icon?: React.ReactNode;
  position?: 'container' | 'label';
  fullHeightButton?: boolean;
  dataTest?: string;
}

export const DesktopNavDropdownButton = (
  props: PropsWithChildren<DesktopNavDropdownButtonProps>,
) => {
  const { isOpen, onOpenButtonClick } = useIsOpen();

  const render = useRenderClientSide();

  const position = props.position || 'container';

  const { reference, floating, floatingStyles } = useFixedFloatingTooltip({
    value: isOpen,
    options: {
      placement: 'bottom-start',
      middleware: [shift()],
    },
  });

  return (
    <>
      <Container
        ref={position === 'container' ? reference : null}
        active={props.active}
        isOpen={isOpen}
        className={props.className}
        fullHeight={props.fullHeightButton}
        onClick={(e) => {
          onOpenButtonClick(e);
          props.onClick && props.onClick();
        }}
        data-test={props.dataTest && `navbar-${props.dataTest}`}
      >
        <Label>
          {props.icon && <IconContainer>{props.icon}</IconContainer>}
          <div ref={position === 'label' ? reference : null}>{props.label}</div>
          <ExpandChevron isOpen={isOpen} />
        </Label>
      </Container>
      {render && isOpen && (
        <div
          ref={floating}
          style={{
            ...floatingStyles,
            zIndex: zIndex.nav,
            top:
              position === 'label'
                ? floatingStyles.top + 4
                : floatingStyles.top,
          }}
        >
          {props.children}
        </div>
      )}
    </>
  );
};
