export const ChangePulseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1022_6819)">
        <mask
          id="mask0_1022_6819"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="96"
          height="96"
        >
          <circle cx="48" cy="48" r="48" fill="#EDEAF1" />
        </mask>
        <g mask="url(#mask0_1022_6819)">
          <path
            d="M96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48Z"
            fill="url(#pattern0)"
          />
          <path
            d="M96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48Z"
            fill="#E2DDE8"
          />
          <path
            d="M28.9259 132.407C60.9533 132.407 86.9163 106.476 86.9163 74.5115C86.9163 42.5474 60.9533 16.616 28.9259 16.616C-3.10144 16.616 -29.0645 42.5474 -29.0645 74.5115C-29.0645 106.476 -3.10144 132.407 28.9259 132.407Z"
            fill="#E9C9C3"
          />
          <path
            d="M26.1733 38.7654C17.3743 39.8335 6.72132 48.8186 -4 38.7654V106L114 106L98 24.2079C74.5334 20.3511 65.08 66.0005 51 66.0005C36.92 66.0005 34.9724 37.6973 26.1733 38.7654Z"
            fill="#B8AAC5"
            stroke="#4D2A6F"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};
