import { SVGProps } from 'react';

export const ExpandContent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 18.5004V13.0004H7V17.0004H11V18.5004H5.5ZM17.275 11.0004V7.19482H13V5.77541L18.5 5.77549V11.0004H17.275Z"
      fill="currentColor"
    />
  </svg>
);
