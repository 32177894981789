import React from 'react';
import { brandColors, mediaQuery } from '../constants';
import styled from '@emotion/styled';
import { body1Styles, Heading5 } from '../typography';

export type NotificationType = 'neutral' | 'error';
export type NotificationSize = 'small' | 'medium';

export interface NotificationProps {
  notificationType: NotificationType;
  size: NotificationSize;
  title?: React.ReactNode;
  text?: React.ReactNode;
  icon?: React.ReactElement;
  actionButton?: React.ReactNode;
  className?: string;
  dataTest?: string;
}

const textColor = (props: { notificationType: NotificationType }) => {
  return props.notificationType === 'error'
    ? brandColors.systemError
    : brandColors.laasPurple;
};

const StyledNotification = styled.div<{
  notificationType: NotificationType;
  size: NotificationSize;
  hasIcon: boolean;
}>`
  background: ${(props) =>
    props.notificationType === 'error'
      ? brandColors.systemError30
      : brandColors.laasPurple5Alpha};
  border-radius: 4px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  color: ${textColor};
  padding: ${(props) => (props.size === 'small' ? '8px 16px' : '24px')};

  @media screen and ${mediaQuery.medium} {
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
  }
`;

const StyledNotificationTitle = styled(Heading5)<{
  notificationType: NotificationType;
}>`
  color: ${textColor};
`;

const StyledNotificationBody = styled.div<{
  notificationType: NotificationType;
}>`
  ${body1Styles};
  color: ${textColor};
  max-width: 900px;
`;

const IconContainer = styled.div<{ firstTextLineHeight: number }>`
  min-height: ${(props) => props.firstTextLineHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-start;
`;

const TextContainer = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Notification = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<NotificationProps>
>((props, ref) => (
  <StyledNotification
    className={props.className}
    ref={ref}
    notificationType={props.notificationType}
    size={props.size}
    hasIcon={!!props.icon}
    data-test={props.dataTest}
  >
    {props.icon ? (
      <IconContainer firstTextLineHeight={props.title ? 24 : 30}>
        {props.icon}
      </IconContainer>
    ) : (
      <div />
    )}
    <TextContainer>
      {props.title ? (
        <StyledNotificationTitle notificationType={props.notificationType}>
          {props.title}
        </StyledNotificationTitle>
      ) : null}
      <StyledNotificationBody notificationType={props.notificationType}>
        {props.text}
        {props.children}
      </StyledNotificationBody>
    </TextContainer>
    {props.actionButton && (
      <ActionButtonContainer>{props.actionButton}</ActionButtonContainer>
    )}
  </StyledNotification>
));
Notification.displayName = 'Notification';
