import { getImageId } from '../../common/getImageId';
import Image from 'next/image';
import React from 'react';
import { getImageUrl } from '../../common/getImageUrl';

export interface ProfileImageProps {
  cloudinaryImageUrl: string;
  width: number;
}

export const ProfileImage = ({
  cloudinaryImageUrl,
  width,
}: ProfileImageProps) => (
  <Image
    src={getImageId(cloudinaryImageUrl)!}
    loader={({ width, src }) =>
      getImageUrl(`unsafe/${width}x${width}/smart/${src}`)
    }
    width={width}
    height={width}
    alt={'profile picture'}
  />
);
