import styled from '@emotion/styled';
import React from 'react';
import { Spacer } from '../Spacer';
import { animationDuration, brandColors } from '../constants';
import { buttonTextStyles } from '../typography';

const StyledPrimaryButton = styled.button<{ hasRightIcon: boolean }>`
  background: ${brandColors.laasPurple};
  border: 1px solid ${brandColors.laasPurple60};
  box-sizing: border-box;
  box-shadow: 5px 4px 12px rgba(77, 42, 111, 0.1);
  border-radius: 29px;

  ${buttonTextStyles}
  text-align: center;

  cursor: pointer;

  white-space: nowrap;

  height: 48px;
  padding: ${(props) => (props.hasRightIcon ? '0 16px 0 24px' : '0 24px')};

  &:hover {
    background: #5c3a77;
  }

  transition: background-color ${animationDuration.short} ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: #c4c4c4;
    border-color: #c4c4c4;
    cursor: not-allowed;
  }
`;

export interface PrimaryButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export const PrimaryButton: React.FunctionComponent<
  React.PropsWithChildren<PrimaryButtonProps>
> = ({ children, rightIcon, leftIcon, ...rest }) => (
  <StyledPrimaryButton hasRightIcon={!!rightIcon} {...rest}>
    {leftIcon ? (
      <>
        {leftIcon}
        <Spacer width={8} />
      </>
    ) : null}
    {children}
    {rightIcon ? (
      <>
        <Spacer width={8} />
        {rightIcon}
      </>
    ) : null}
  </StyledPrimaryButton>
);
