import { SVGProps } from 'react';

export const PeerReviewCompletion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="301"
    height="260"
    viewBox="0 0 301 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5305_50723)">
      <path
        d="M265.74 81.0004C265.735 79.042 264.955 77.1654 263.57 75.7807C262.185 74.3959 260.308 73.6156 258.35 73.6104H199.24C197.284 73.6183 195.409 74.3997 194.027 75.7842C192.644 77.1686 191.865 79.0438 191.86 81.0004V120.4C191.863 122.358 192.641 124.234 194.024 125.619C195.407 127.004 197.283 127.785 199.24 127.79H234.36L234.04 137.64L252.49 127.79H258.35C260.309 127.788 262.187 127.008 263.573 125.623C264.958 124.238 265.737 122.359 265.74 120.4V81.0004Z"
        stroke="#9F8BB1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.27 233.17V219.68C30.27 213.06 33.8334 207.726 40.96 203.68C48.0867 199.633 57.25 197.6 68.45 197.58C70.65 197.58 72.6867 197.62 74.56 197.7C76.4387 197.79 78.3094 198.004 80.16 198.34C77.4144 204.053 76.0185 210.321 76.08 216.66V233.21L30.27 233.17ZM228.78 233.17V216.62C228.796 213.418 228.498 210.223 227.89 207.08C227.29 204.046 226.319 201.097 225 198.3C226.851 197.964 228.721 197.75 230.6 197.66C232.47 197.58 234.42 197.54 236.46 197.54C247.987 197.54 257.233 199.573 264.2 203.64C271.167 207.706 274.643 213.04 274.63 219.64V233.13L228.78 233.17ZM68.45 189.9C65.5184 189.902 62.6316 189.18 60.0455 187.799C57.4594 186.419 55.2539 184.421 53.6246 181.984C51.9952 179.547 50.9923 176.745 50.7047 173.828C50.4171 170.91 50.8538 167.967 51.9759 165.259C53.0981 162.55 54.8711 160.161 57.1379 158.301C59.4046 156.442 62.095 155.171 64.9705 154.601C67.8461 154.03 70.818 154.178 73.6229 155.031C76.4277 155.883 78.9788 157.415 81.05 159.49C83.5385 161.982 85.2325 165.157 85.918 168.612C86.6035 172.066 86.2498 175.647 84.9016 178.901C83.5533 182.155 81.271 184.936 78.3429 186.894C75.4148 188.852 71.9722 189.898 68.45 189.9V189.9ZM236.45 189.9C232.325 189.912 228.324 188.492 225.129 185.883C221.933 183.275 219.742 179.638 218.929 175.594C218.115 171.55 218.73 167.35 220.668 163.708C222.605 160.067 225.746 157.21 229.555 155.626C233.364 154.042 237.604 153.828 241.552 155.021C245.501 156.214 248.914 158.74 251.208 162.168C253.502 165.596 254.536 169.714 254.134 173.819C253.731 177.924 251.917 181.763 249 184.68C247.37 186.362 245.413 187.693 243.25 188.59C241.086 189.488 238.762 189.934 236.42 189.9H236.45Z"
        stroke="#9F8BB1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.53 233.34V216.79C92.53 205.77 98.1734 196.863 109.46 190.07C120.747 183.276 135.463 179.883 153.61 179.89C171.937 179.89 186.697 183.283 197.89 190.07C209.083 196.856 214.683 205.763 214.69 216.79V233.34H92.53Z"
        stroke="#4D2A6F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.61 172.26C149.595 172.276 145.616 171.497 141.904 169.968C138.192 168.439 134.819 166.19 131.979 163.351C129.14 160.512 126.891 157.139 125.362 153.426C123.833 149.714 123.054 145.735 123.07 141.72C122.981 137.68 123.728 133.665 125.264 129.927C126.801 126.189 129.094 122.809 132 120C137.818 114.368 145.598 111.219 153.695 111.219C161.792 111.219 169.572 114.368 175.39 120C181.022 125.818 184.171 133.598 184.171 141.695C184.171 149.793 181.022 157.573 175.39 163.39C172.569 166.292 169.177 168.579 165.429 170.105C161.68 171.632 157.656 172.366 153.61 172.26V172.26Z"
        stroke="#4D2A6F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.7901 104.77H26.5701V105H96.7901V104.77Z"
        stroke="#9F8BB1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.7901 88.4697H26.5701V88.6997H96.7901V88.4697Z"
        stroke="#9F8BB1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.7901 72.1699H26.5701V72.3999H96.7901V72.1699Z"
        stroke="#9F8BB1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.82 58.9998C115.812 56.1538 114.678 53.4266 112.666 51.4142C110.653 49.4018 107.926 48.2677 105.08 48.2598H19.18C16.3357 48.2703 13.6111 49.4056 11.6008 51.4177C9.59055 53.4298 8.45785 56.1555 8.44995 58.9998V116.26C8.45785 119.104 9.59055 121.83 11.6008 123.842C13.6111 125.854 16.3357 126.989 19.18 127H74.37L94.02 143V127H105.08C107.926 126.992 110.653 125.858 112.666 123.845C114.678 121.833 115.812 119.106 115.82 116.26V58.9998Z"
        stroke="#9F8BB1"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5305_50723">
        <rect width="301" height="260" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
