import { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Hierarchy, KeyboardArrowDown } from '../../icons';
import { Option } from './Option';
import { Checkbox } from '../../inputs';
import { CheckedCount } from './CheckedCount';
import { brandColors } from '../../constants';

export interface TreeItemOptionData<T = unknown> {
  value: string;
  label: ReactNode;
  depth: number;
  disabled?: boolean;
  hasChildren: boolean;
  data?: T;
  optionLabel?: ReactNode;
}

export interface TreeItemOptionProps {
  numberOfCheckedDescendants: number;
  isParentChecked: boolean;
  isCollapsed: boolean;
  isHidden: boolean;
}

export interface CustomSelectProps {
  optionPropsById: { [key: string]: TreeItemOptionProps };
  setCollapsed: (id: string, isCollapsed: boolean) => void;
}

const Wrapper = styled.div<{ depth: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${(props) => props.depth * 24}px;
`;

const TreeNode = styled.div`
  display: flex;
`;

const TreeNodeIndicators = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  span:nth-of-type(1) {
    margin: 0 16px;
  }
`;

const ArrowDown = ({ isCollapsed, ...rest }: any) => (
  <KeyboardArrowDown {...rest} />
);

const CollapseIndicator = styled(ArrowDown)`
  transform: rotate(${(props) => (props.isCollapsed ? '0' : '180deg')});
`;

const CollapseContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TouchArea = styled.div`
  position: absolute;
  z-index: 1;
  width: 44px;
  height: 44px;
`;

const SingleSelectTreeItemOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const SingleSelectTreeItemIcon = styled(Hierarchy)`
  fill: ${brandColors.laasPurple60};
`;

export interface TreeItemOptionComponentProps
  extends PropsWithChildren<Record<string, any>> {
  data: TreeItemOptionData;
  contentAfter?: ReactNode;
  onOptionClick?: (e: MouseEvent) => void;
  indicateSelection?: boolean;
}

const BaseTreeItemOption = (props: TreeItemOptionComponentProps) => {
  const data = props.data;
  const { optionPropsById, setCollapsed } =
    props.selectProps as CustomSelectProps;
  const optionProps = optionPropsById[data.value];
  const setCollapseState = (e: any) => {
    setCollapsed(data.value, !optionProps.isCollapsed);
    e.stopPropagation();
  };

  return (
    <Option {...props} onClick={props.onOptionClick || setCollapseState}>
      <Wrapper depth={data.depth}>
        <TreeNode>
          {props.children}
          {data.hasChildren && (
            <TreeNodeIndicators>
              {optionProps.numberOfCheckedDescendants > 0 &&
                optionProps.isCollapsed && (
                  <CheckedCount>
                    {optionProps.numberOfCheckedDescendants}
                  </CheckedCount>
                )}
              <CollapseContainer>
                <TouchArea onClick={setCollapseState}></TouchArea>
                <CollapseIndicator isCollapsed={optionProps.isCollapsed} />
              </CollapseContainer>
            </TreeNodeIndicators>
          )}
        </TreeNode>
        {props.contentAfter}
      </Wrapper>
    </Option>
  );
};

export const MultiSelectTreeItemOption = (
  props: TreeItemOptionComponentProps,
) => (
  <BaseTreeItemOption {...props}>
    <Checkbox
      checked={props.isSelected}
      disabled={props.isDisabled}
      onChange={() => {}}
      onClick={(e) => {
        props.innerProps.onClick?.(e);
        e.stopPropagation();
      }}
    >
      {props.data.optionLabel || props.label}
    </Checkbox>
  </BaseTreeItemOption>
);

export const SingleSelectTreeItemOption = (
  props: TreeItemOptionComponentProps,
) => (
  <BaseTreeItemOption
    {...props}
    onOptionClick={(e) => {
      props.innerProps.onClick?.(e);
      e.stopPropagation();
    }}
    indicateSelection={true}
  >
    <SingleSelectTreeItemOptionContainer>
      <SingleSelectTreeItemIcon />
      {props.data.optionLabel || props.label}
    </SingleSelectTreeItemOptionContainer>
  </BaseTreeItemOption>
);
