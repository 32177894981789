import React, { useEffect } from 'react';
import {
  AppCustomizations,
  AppConfigurationDataFragment,
  CurrentUserDataFragment,
} from '../generated';
import { useMarkerIOWidget } from './MarkerIOWidgetProvider';

export type User = CurrentUserDataFragment &
  Omit<AppConfigurationDataFragment, '__typename'> & {
    customizations: AppCustomizations;
  };

export interface UserContext {
  user?: User;
  setUser?: (user?: User) => void;
}

const userContext = React.createContext<UserContext>({});

export const useCurrentUser = () => {
  const ctx = React.useContext(userContext);
  return ctx.user;
};

export const useSetUser = () => {
  const ctx = React.useContext(userContext);
  return (user: User) => ctx.setUser!(user);
};

export const UserProvider: React.FunctionComponent<
  React.PropsWithChildren<{ user?: User }>
> = (props) => {
  const [user, setUser] = React.useState(props.user);
  const { widget } = useMarkerIOWidget();
  useEffect(() => setUser(props.user), [props.user]);
  useEffect(() => {
    if (widget && props.user) {
      widget.setReporter({
        email: props.user.email,
        fullName: `${props.user.firstName} ${props.user.lastName}`,
      });
    }
  }, [props.user, widget]);
  return (
    <userContext.Provider value={{ user, setUser }}>
      {props.children}
    </userContext.Provider>
  );
};
