import { SVGProps } from 'react';

export const Archive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V6.5C3 6.25 3.04167 6.025 3.125 5.825C3.20833 5.625 3.31667 5.43333 3.45 5.25L4.85 3.55C4.98333 3.36667 5.15 3.229 5.35 3.137C5.55 3.04567 5.76667 3 6 3H18C18.2333 3 18.45 3.04567 18.65 3.137C18.85 3.229 19.0167 3.36667 19.15 3.55L20.55 5.25C20.6833 5.43333 20.7917 5.625 20.875 5.825C20.9583 6.025 21 6.25 21 6.5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM5.4 6H18.6L17.75 5H6.25L5.4 6ZM5 8V19H19V8H5ZM12 18L16 14L14.6 12.6L13 14.2V10H11V14.2L9.4 12.6L8 14L12 18ZM5 19H19H5Z"
      fill="currentColor"
    />
  </svg>
);
