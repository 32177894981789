export const ControlsIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.1494V19.1494H9V17.1494H3ZM3 5.14941V7.14941H13V5.14941H3ZM13 21.1494V19.1494H21V17.1494H13V15.1494H11V21.1494H13ZM7 9.14941V11.1494H3V13.1494H7V15.1494H9V9.14941H7ZM21 13.1494V11.1494H11V13.1494H21ZM15 9.14941H17V7.14941H21V5.14941H17V3.14941H15V9.14941Z"
      fill="currentColor"
    />
  </svg>
);
