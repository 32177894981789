import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { brandColors } from '../constants';
import { textColors } from './semantic/textColors';
import {
  body1Styles,
  body2Styles,
  caption1Styles,
  caption2Styles,
} from './semantic/typography';

export const headingXLStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.005em;
`;

export const HeadingXL = styled.h1`
  ${headingXLStyles}
`;

export const headingLStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.005em;
`;

export const HeadingL = styled.h1`
  ${headingLStyles}
`;

export const headingMStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};

  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.005em;
`;

export const HeadingM = styled.h2`
  ${headingMStyles}
`;

export const headingSStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};

  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
`;

export const HeadingS = styled.h3`
  ${headingSStyles}
`;

export const headingXSStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};

  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
`;

export const HeadingXS = styled.h4`
  ${headingXSStyles}
`;

export const headingXXSStyles = css`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  color: ${textColors.primary};

  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
`;

export const HeadingXXS = styled.h5`
  ${headingXXSStyles}
`;

export const bodyXLStyles = css`
  ${body1Styles}
`;

export const BodyXL = styled.p`
  ${bodyXLStyles}
  color: ${brandColors.laasPurple};
`;

export const bodyMStyles = css`
  ${body1Styles}
`;

export const BodyM = styled.p`
  ${bodyMStyles}
`;

export const BodyMSecondary = styled(BodyM)`
  color: ${brandColors.coalGrey};
`;

export const bodyLStyles = css`
  ${body1Styles}
`;

export const BodyL = styled.p`
  ${bodyLStyles}
`;

export const BodyLSecondary = styled(BodyL)`
  color: ${brandColors.coalGrey};
`;

export const bodySStyles = css`
  ${body2Styles}
`;

export const BodyS = styled.p`
  ${bodySStyles}
`;

export const BodyXS = styled.p`
  ${caption1Styles}
`;

export const BodyXXS = styled.p`
  ${caption2Styles}
`;

export const LaasLink = styled.a`
  text-decoration: underline;
  color: ${brandColors.laasPurple};
  cursor: pointer;
  &:hover {
    color: ${brandColors.indianSummer};
  }
`;

export const BoldLink = styled(LaasLink)`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.05em;

  cursor: pointer;
`;
