import React from 'react';
import Lottie from 'react-lottie-player';
import animationJson from './loginPageAnimation.json';
import styled from '@emotion/styled';
import { mediaQuery } from '../constants';

export interface LoginPageAnimationArgs {}

const StyledLottie: typeof Lottie = styled(Lottie)`
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;

  & svg {
    position: fixed;
    width: auto !important;
    height: 80vh !important;
    right: 0;
    top: 0;

    @media screen and ${mediaQuery.medium} {
      height: 20vh !important;
      transform: matrix(1.5, 0, 0, 1, -20, 1) !important;
    }
    @media screen and ${mediaQuery.small} {
      display: none;
    }
  }
`;

export default function LoginPageAnimation() {
  return <StyledLottie loop animationData={animationJson} play />;
}
