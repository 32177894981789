import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { brandColors } from '../constants';

export const textSkeletonParameters = css`
  background-color: ${brandColors.laasPurple10};

  animation-name: skeletonAnimation;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes skeletonAnimation {
    from {
      opacity: 100%;
    }
    to {
      opacity: 50%;
    }
  }
`;

export const TextSkeleton = styled.div<{
  width: number;
  height: number;
  animationDelay: number;
}>`
  max-width: ${(p) => p.width}px;
  width: 100%;
  height: ${(p) => p.height}px;
  border-radius: ${(p) => p.height}px;
  ${textSkeletonParameters}
  animation-delay: ${(p) => p.animationDelay}ms;
`;
