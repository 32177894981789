import { brandColors } from '../constants';

export const CloseIcon = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={brandColors.laasPurple}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
  </svg>
);

export const LargeCloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6663 2.68331L21.3163 0.333313L11.9997 9.64998L2.68301 0.333313L0.333008 2.68331L9.64968 12L0.333008 21.3166L2.68301 23.6666L11.9997 14.35L21.3163 23.6666L23.6663 21.3166L14.3497 12L23.6663 2.68331Z"
      fill="#4D2A6F"
    />
  </svg>
);
