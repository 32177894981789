import styled from '@emotion/styled';
import { mediaQuery, menuHeight } from '../constants';
import { ServiceBackgroundCorner } from '../icons';

const Background = styled.div<{ hasNavBar: boolean }>`
  position: absolute;
  right: 0px;
  top: ${({ hasNavBar }) => (hasNavBar ? menuHeight.desktop : 0)}px;
  @media screen and ${mediaQuery.large} {
    top: ${({ hasNavBar }) => (hasNavBar ? menuHeight.mobile : 0)}px;
  }
`;

export const BackgroundCornerIcon = ({
  hasNavBar = true,
  className,
}: {
  hasNavBar?: boolean;
  className?: string;
}) => {
  return (
    <Background hasNavBar={hasNavBar} className={className}>
      <ServiceBackgroundCorner />
    </Background>
  );
};
