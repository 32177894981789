import { SVGProps } from 'react';
import { brandColors } from '../constants';

export const Hierarchy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="9"
      y1="7"
      x2="9"
      y2="15"
      stroke={brandColors.laasPurple60}
      strokeWidth="2"
    />
    <line
      x1="8"
      y1="15"
      x2="16"
      y2="15"
      stroke={brandColors.laasPurple60}
      strokeWidth="2"
    />
  </svg>
);
