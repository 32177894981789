import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import React, { PropsWithChildren } from 'react';
import { UnstyledLink } from '../../../UnstyledLink';
import { animationDuration, menuHeight, zIndex } from '../../../constants';
import { NavbarProps } from '../types';
import { NavGuidinLogo, NavGuidinLogoStrong } from './NavGuidinLogo';

const scrolledStyles = css`
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.05);
  background: white;
`;

const Container = styled.div<{ scrolled: boolean }>`
  background: transparent;
  padding: 0 24px 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: ${menuHeight.desktop}px;
  position: sticky;
  top: 0;
  z-index: ${zIndex.nav};
  transition: all ${animationDuration.short} linear;
  ${(p) => p.scrolled && scrolledStyles};
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 16px);
  margin-top: 8px;
  align-items: center;
  gap: 16px;
  // Sum of logo widths + gap
  // Reserves fixed amount of space from navbar enabling responsive customer logo
  width: calc(16px + 128px + 84px);
`;

export const NavbarBar = ({
  className,
  ...props
}: PropsWithChildren<NavbarProps> & { className?: string }) => {
  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    const handler = () => {
      if (window.scrollY > 0) {
        if (!scrolled) {
          setScrolled(true);
        }
      } else {
        if (scrolled) {
          setScrolled(false);
        }
      }
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [scrolled, setScrolled]);

  return (
    <Container
      scrolled={props.alwaysOpaque ? true : scrolled}
      className={className}
      data-test={'navbar'}
    >
      <Link passHref href={'/'} legacyBehavior>
        <UnstyledLink>
          <LogosContainer>
            {props.logoUrl && (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                style={{ maxWidth: 128, maxHeight: 60 }}
                src={props.logoUrl}
                alt={'logo'}
              />
            )}
            {props.logoUrl ? <NavGuidinLogo /> : <NavGuidinLogoStrong />}
          </LogosContainer>
        </UnstyledLink>
      </Link>
      <LinksContainer data-test="navbar-links">{props.children}</LinksContainer>
    </Container>
  );
};
