import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { body2Styles, headingXSStyles } from '../typography';
import {
  AccordionHeaderContainer,
  AccordionM,
  AccordionMHeading,
  AccordionMIcon,
  AccordionMItemCountBubble,
  AccordionMLabelContainer,
} from './AccordionM';

export const accordionXSStyles = css`
  ${AccordionMLabelContainer} {
    padding-bottom: 8px;
  }

  ${AccordionHeaderContainer} {
    gap: 8px;
  }

  ${AccordionMItemCountBubble} {
    ${body2Styles};
    padding: 4px 12px;
    line-height: 14px;
    font-size: 12px;
  }

  ${AccordionMIcon} {
    width: 20px;
    height: 20px;
  }

  ${AccordionMHeading} {
    ${headingXSStyles}
  }
`;

export const AccordionXS = styled(AccordionM)`
  ${accordionXSStyles}
`;
