import styled from '@emotion/styled';
import { mediaQuery } from './constants';

export interface SpacerProps {
  width?: number;
  height?: number;
  smallHeight?: number;
  mediumHeight?: number;
}

const StyledSpacer = styled.div<SpacerProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  @media screen and ${mediaQuery.medium} {
    height: ${(props) => props.mediumHeight}px;
  }

  @media screen and ${mediaQuery.small} {
    height: ${(props) => props.smallHeight}px;
  }
`;

const firstNonNull = (...args: (number | undefined)[]) =>
  args.find((a) => a != null);

export const Spacer = ({
  width = 0,
  height = 0,
  mediumHeight = height,
  smallHeight = firstNonNull(mediumHeight, height),
}: SpacerProps) => (
  <StyledSpacer
    width={width}
    height={height}
    smallHeight={smallHeight}
    mediumHeight={mediumHeight}
  />
);
