import { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { brandColors } from '../constants';

const StyledLabel = styled.label<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  position: relative;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: ${(props) =>
    props.disabled ? brandColors.coalGrey50 : brandColors.coalGrey};
`;

const StyledInput = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  outline: none;
  background: transparent;

  border: 1px solid ${brandColors.laasPurple};
  box-sizing: border-box;
  border-radius: 24px;

  :disabled {
    border: 1px solid ${brandColors.coalGrey20};
  }

  :enabled {
    cursor: pointer;
  }

  :checked:disabled {
    border: 2px solid ${brandColors.coalGrey20};
  }

  :checked:enabled {
    border: 2px solid ${brandColors.laasPurple};
  }

  :focus,
  :active {
    box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
  }
`;

const InputContainer = styled.div<{ disabled: boolean }>`
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 16px;

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    width: 14px;
    height: 14px;
    left: 5px;
    top: 5px;

    background: ${(props) =>
      props.disabled ? brandColors.coalGrey20 : brandColors.laasPurple};
    border-radius: 14px;
  }
`;

const TouchArea = styled.div`
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  //background-color: rgba(127, 127, 127, 0.5);
  border-radius: 50%;
`;

export type RadioProps = InputHTMLAttributes<HTMLInputElement>;

export const Radio = ({ children, className, ...inputProps }: RadioProps) => (
  <StyledLabel
    htmlFor={inputProps.id}
    disabled={!!inputProps.disabled}
    className={className}
  >
    <InputContainer disabled={!!inputProps.disabled}>
      <StyledInput {...inputProps} type="radio" />
      <span className="checkmark" />
      <TouchArea />
    </InputContainer>
    {children}
  </StyledLabel>
);
