export const NpsNeutral = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="16" fill="#FFD18D" />
      <circle cx="11.0002" cy="11" r="1.8" fill="#D4850F" />
      <circle cx="20.2004" cy="11" r="1.8" fill="#D4850F" />
      <rect
        x="5.59961"
        y="17.2"
        width="20.4"
        height="3.2"
        rx="0.8"
        fill="#D4850F"
      />
    </svg>
  );
};
