import { SVGProps } from 'react';

export const AutoAwesome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M31.9993 14.0295L30.1105 9.72396L25.6938 7.75175L30.1105 5.80729L31.9993 1.64062L33.8882 5.80729L38.3049 7.75175L33.8882 9.72396L31.9993 14.0295ZM31.9993 38.3351L30.1105 34.1407L25.6938 32.1962L30.1105 30.2518L31.9993 25.9185L33.8882 30.2518L38.3049 32.1962L33.8882 34.1407L31.9993 38.3351ZM14.2493 32.3906L10.3049 23.8628L1.66602 19.974L10.3049 16.0851L14.2493 7.58513L18.2216 16.0851L26.8326 19.974L18.2216 23.8628L14.2493 32.3906ZM14.2493 25.5851L16.1382 21.724L20.0549 19.974L16.1382 18.224L14.2493 14.3628L12.3882 18.224L8.44377 19.974L12.3882 21.724L14.2493 25.5851Z"
        fill="#A694B7"
      />
    </g>
  </svg>
);
