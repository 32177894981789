export const Sport = (props: any) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.634384 12.3497L5.32432 12.2598C5.5295 12.2622 5.7756 12.1279 5.87156 11.9607L6.20773 11.4869L4.58721 9.45312L4.11946 10.436L0.583458 10.7921C0.583458 10.7921 -0.0341355 10.9025 0.00148354 11.5785C0.0338645 12.2014 0.483665 12.3247 0.634384 12.3497Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75696 6.11105C3.09438 6.35906 3.44844 6.30522 3.62646 6.13911L5.55589 4.34206L7.23875 4.72786L5.36123 6.97143C5.10168 7.36947 4.59329 8.14794 5.01111 8.7968L7.19022 11.5027L6.26853 14.5827C6.26853 14.5827 6.18982 15.0861 6.86409 15.2877C7.23875 15.3992 7.69183 15.3537 7.84841 15.0407L9.44663 11.085C9.5197 10.9197 9.47682 10.6722 9.35268 10.5349L7.7728 8.45999L9.20485 6.93979L9.27228 7.67846C9.28864 7.8017 9.35268 7.96525 9.48528 8.03899C10.3709 8.52914 12.8081 9.66764 12.8081 9.66764C13.0742 9.76485 13.4186 9.66177 13.6344 9.36528C13.9194 8.97209 13.6381 8.59854 13.6381 8.59854L11.1546 6.84844C11.1546 6.84844 11.1467 5.14503 11.1227 4.47576C11.0928 3.65391 10.0075 2.86447 9.27792 2.85554C8.22533 2.84329 5.06951 2.75781 5.06951 2.75781C4.75043 2.75781 4.55746 2.94841 4.36816 3.15279L2.51518 5.25654C2.51518 5.25654 2.20598 5.70714 2.75696 6.11105Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7589 3.40685C12.7878 3.40685 13.6227 2.6441 13.6227 1.70293C13.6227 0.762506 12.7878 0 11.7589 0C10.7314 0 9.89648 0.762506 9.89648 1.70293C9.89648 2.6441 10.7314 3.40685 11.7589 3.40685Z"
      fill="#907BA6"
    />
  </svg>
);
