import { brandColors } from '../constants';

export const Undo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M7.20078 18.7501V17.2501H14.0508C15.1674 17.2501 16.1381 16.8961 16.9628 16.1881C17.7881 15.4794 18.2008 14.5834 18.2008 13.5001C18.2008 12.4168 17.7881 11.5208 16.9628 10.8121C16.1381 10.1041 15.1674 9.7501 14.0508 9.7501H7.15078L10.0008 12.6001L8.95078 13.6501L4.30078 9.0001L8.95078 4.3501L10.0008 5.4001L7.15078 8.2501H14.0508C15.5841 8.2501 16.9091 8.7501 18.0258 9.7501C19.1424 10.7501 19.7008 12.0001 19.7008 13.5001C19.7008 15.0001 19.1424 16.2501 18.0258 17.2501C16.9091 18.2501 15.5841 18.7501 14.0508 18.7501H7.20078Z"
        fill={brandColors.laasPurple}
      />
    </g>
  </svg>
);
