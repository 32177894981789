import styled from '@emotion/styled';
import {
  animationDuration,
  mediaQuery,
  menuHeight,
  zIndex,
} from '../../constants';
import { GuidinLogo } from '../../icons';

const Container = styled.nav`
  background: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  padding-left: 40px;

  height: ${menuHeight.desktop}px;
  @media screen and ${mediaQuery.large} {
    height: ${menuHeight.mobile}px;
    padding-left: 24px;
  }
  width: 100%;
  z-index: ${zIndex.nav};
  transition: box-shadow ${animationDuration.medium} ease-in-out,
    background ${animationDuration.medium} ease-in-out;
`;

export const NavbarOnlyLogo = () => {
  return (
    <Container>
      <GuidinLogo />
    </Container>
  );
};
