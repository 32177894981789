import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { UnstyledLink } from '../../../UnstyledLink';
import { animationDuration, brandColors } from '../../../constants';
import { body1Styles, bodyLStyles } from '../../../typography';
import { DropDownMenuProps } from '../types';
import { DesktopNavDropdownButton } from './DesktopNavDropdownButton';

const DropdownMenuContainer = styled.div`
  border: 2px solid ${brandColors.lightPurpleBorderAA};
  border-radius: 2px;
  box-shadow: 5px 4px 12px rgba(77, 42, 111, 0.1);
  > * {
    > div {
      padding: 16px;
      border-top: 1px solid ${brandColors.laasPurple60};
    }
  }
  > *:nth-child(1) {
    > div {
      border-top: none;
    }
  }
  background: white;
  box-sizing: content-box;
`;

const StyledDesktopNavDropdownButton = styled(DesktopNavDropdownButton)`
  ${bodyLStyles};
`;

const dropdownActiveStyles = css`
  font-weight: 700;
`;

const DropdownMenuLabel = styled.div<{ active: boolean }>`
  ${body1Styles}
  ${(p) => p.active && dropdownActiveStyles};
  color: ${brandColors.laasPurple};
  :hover {
    background: ${brandColors.laasPurple20};
  }
  transition: background-color ${animationDuration.medium} ease-in-out;
`;

export const DesktopInnerDropDownMenu = (
  props: DropDownMenuProps & { className?: string },
) => {
  return (
    <StyledDesktopNavDropdownButton
      position="label"
      className={props.className}
      label={props.label}
      active={!!props.active}
      icon={props.icon}
      dataTest={props.dataTest && `${props.dataTest}`}
    >
      <DropdownMenuContainer>
        {props.children.map((item, i) => {
          if (item.type === 'DropDownMenu') {
            throw new Error('Nested dropdown menu not supported here');
          }
          return (
            <Link
              href={item.href || '#'}
              passHref
              key={`${item.type}-${i}`}
              legacyBehavior
            >
              <UnstyledLink
                onClick={item.onClick}
                data-test={
                  item.dataTest && `navbar-${props.dataTest}-${item.dataTest}`
                }
              >
                <DropdownMenuLabel active={!!item.active}>
                  {item.label}
                </DropdownMenuLabel>
              </UnstyledLink>
            </Link>
          );
        })}
      </DropdownMenuContainer>
    </StyledDesktopNavDropdownButton>
  );
};
