import { SVGProps } from 'react';

export const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44 18.48L29.62 17.24L24 4L18.38 17.26L4 18.48L14.92 27.94L11.64 42L24 34.54L36.36 42L33.1 27.94L44 18.48ZM24 30.8L16.48 35.34L18.48 26.78L11.84 21.02L20.6 20.26L24 12.2L27.42 20.28L36.18 21.04L29.54 26.8L31.54 35.36L24 30.8Z"
      fill="white"
    />
  </svg>
);
