import usePortal from 'react-useportal';
import { ToastClassName, ToastContainer } from 'react-toastify';
import { css } from '@emotion/css';
import { animationDuration, brandColors } from '../constants';
import classnames from 'classnames';
import { useRenderClientSide } from '../useRenderClientSide';

const defaultStyles = css`
  box-shadow: 5px 4px 12px rgba(77, 42, 111, 0.1);

  transition: color ${animationDuration.long} ease-in-out,
    background-color ${animationDuration.long} ease-in-out;

  .Toastify__toast-body {
    padding: 16px;
    position: relative;
    margin: 0;
  }

  .Toastify__toast-theme--light {
    color: #fff;
  }

  .Toastify__close-button {
    opacity: 1;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .Toastify__progress-bar {
    height: 8px;
  }

  .Toastify__spinner {
    display: none;
  }
`;

const success = css`
  background-color: ${brandColors.systemOk};
  color: #ffffff;
  .Toastify__close-button {
    color: #fff;
  }
`;

const info = css`
  background-color: white;
  color: ${brandColors.laasPurple};
  border: 1px solid ${brandColors.laasPurple60};
  .Toastify__close-button {
    color: ${brandColors.laasPurple};
  }
`;

const error = css`
  background-color: ${brandColors.systemError};
  color: #ffffff;
  .Toastify__close-button {
    color: #fff;
  }
`;

const classesByType = {
  success,
  info,
  warning: '',
  error,
  default: '',
};

const getToastClassName: ToastClassName = (context) => {
  return classnames(
    classesByType[context?.type || 'default'] || '',
    context?.defaultClassName,
    defaultStyles,
  );
};

export const PortalToastContainer = () => {
  const render = useRenderClientSide();

  const { Portal } = usePortal({
    bindTo: global['document']
      ? document.getElementById('toast-root')!
      : undefined,
  });

  if (!render) return null;
  return (
    <Portal>
      <ToastContainer toastClassName={getToastClassName} />
    </Portal>
  );
};
