import React from 'react';

export const EmployeeExperienceIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5080_117206)">
        <path
          d="M27.3333 7.99935C23.8533 8.93268 19.7733 9.33268 16 9.33268C12.2267 9.33268 8.14667 8.93268 4.66667 7.99935L4 10.666C6.48 11.3327 9.33333 11.7727 12 11.9993V29.3327H14.6667V21.3327H17.3333V29.3327H20V11.9993C22.6667 11.7727 25.52 11.3327 28 10.666L27.3333 7.99935ZM16 7.99935C17.4667 7.99935 18.6667 6.79935 18.6667 5.33268C18.6667 3.86602 17.4667 2.66602 16 2.66602C14.5333 2.66602 13.3333 3.86602 13.3333 5.33268C13.3333 6.79935 14.5333 7.99935 16 7.99935Z"
          fill="#907BA6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5080_117206">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
