import React, { useContext } from 'react';
import { useCloseOnBackgroundClick } from '../../../useCloseOnBackgroundClick';
import { NavItemContext } from '../context/NavItemContext';

export const useIsOpen = () => {
  const { isOpen, setIsOpen } = useContext(NavItemContext);
  useCloseOnBackgroundClick({ isOpen, setIsOpen });

  return {
    isOpen,
    onOpenButtonClick: (e: React.MouseEvent<any, any>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
  };
};
