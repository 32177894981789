import { setLocale } from 'yup';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

/** Translates Yup error messages for Formik.
 *
 * Only needs to be called once.
 *
 * Note: FormattedMessage components can not be used as error messages, as Formik
 * treats them as nested objects.
 */
export const useYupLocale = () => {
  const intl = useIntl();

  useEffect(() => {
    const fieldRequiredMessage = intl.formatMessage({
      defaultMessage: 'Please fill out this field.',
      description: 'Form field validation error',
    });

    const defaultRequiredMessages = {
      required: () => fieldRequiredMessage,

      // This may shadow some error messages, but it's convenient when eg. a string type is required, but the field
      // may be 'null' instead of an empty string.
      notType: () => fieldRequiredMessage,
    };

    setLocale({
      string: {
        /* TODO: This is probably as bug */
        ...(defaultRequiredMessages as any),
      },
      mixed: {
        ...defaultRequiredMessages,
      },
      date: {
        min: ({ min }) =>
          intl.formatMessage(
            {
              defaultMessage: 'Date must be after {date}.',
              description: 'Form field validation error',
            },
            { date: intl.formatDate(min) },
          ),
      },
    });
  }, [intl]);
};
