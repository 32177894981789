import React from 'react';

export const ReviewStarIcon = (props: React.SVGAttributes<any>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 13.7607L14.9075 17.0244L13.4725 10.8732L18.25 6.73441L11.9588 6.20066L9.5 0.399414L7.04125 6.20066L0.75 6.73441L5.5275 10.8732L4.0925 17.0244L9.5 13.7607Z"
      fill="currentColor"
    />
  </svg>
);
