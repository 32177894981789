import { Option } from './Option';
import { Checkbox } from '../../inputs';

export const CheckOption = (props: any) => (
  <Option {...props}>
    <Checkbox
      checked={props.isSelected}
      disabled={props.isDisabled}
      onChange={() => {}}
      onClick={(e) => {
        props.innerProps.onClick?.(e);
        e.stopPropagation();
      }}
    >
      {props.label}
    </Checkbox>
  </Option>
);
