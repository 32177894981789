import React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useScrollLock = <ElementType extends HTMLElement>({
  isActive,
}: {
  isActive: boolean;
}) => {
  const elRef = React.useRef<ElementType>(null);
  const [refCurrent, setRefCurrent] = React.useState<ElementType | null>(null);

  React.useEffect(() => {
    if (refCurrent && isActive) {
      disableBodyScroll(refCurrent, {
        reserveScrollBarGap: true,
        allowTouchMove: () => true,
      });
      return () => enableBodyScroll(refCurrent);
    }
  }, [refCurrent, isActive]);

  // This is a hack to make scroll lock work in portals
  React.useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setRefCurrent(elRef.current);
      }, 0);
    }
  }, [isActive]);

  return elRef;
};
