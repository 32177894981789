import { brandColors } from '../constants';

export const WarningAmber = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={brandColors.systemError}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2ZM13 16H11V18H13V16ZM13 10H11V14H13V10Z" />
  </svg>
);

export const WarningAmber24CurrentColor = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2ZM13 16H11V18H13V16ZM13 10H11V14H13V10Z"
      fill="currentColor"
    />
  </svg>
);

export const WarningAmber40CurrentColor = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9993 9.98301L32.5493 31.6663H7.44935L19.9993 9.98301ZM19.9993 3.33301L1.66602 34.9997H38.3327L19.9993 3.33301ZM21.666 26.6663H18.3327V29.9997H21.666V26.6663ZM21.666 16.6663H18.3327V23.333H21.666V16.6663Z"
      fill="currentColor"
    />
  </svg>
);
