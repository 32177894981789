import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { css } from '@emotion/css';
import { brandColors } from '../../constants';
import { body1Styles } from '../../typography';

export const defaultStyles = css`
  .DateInput_input {
    height: 48px;

    padding: 0;
    padding-left: 17px;

    ${body1Styles}
    font-weight: 600;
    color: ${brandColors.laasPurple};

    border: 1px solid ${brandColors.laasPurple60};
    border-radius: 2px;

    ::placeholder {
      color: ${brandColors.coalGrey70};
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .DateInput_input__focused {
    border-bottom: inherit;
    border: 2px solid ${brandColors.laasPurple};

    padding-left: 16px;
  }

  .DateInput_input__disabled {
    border: 1px solid ${brandColors.coalGrey20};
    background: white;

    ::placeholder {
      color: ${brandColors.coalGrey50};
    }
  }

  .DateInput_fang {
    transform: translateY(1px);
  }

  .DayPicker {
    font-family: Mulish;
  }

  .CalendarDay__selected {
    background: ${brandColors.laasPurple};
    border: 1px double ${brandColors.laasPurple};

    &:hover {
      background: ${brandColors.laasPurple60};
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    &::before {
      border-right: 33px solid ${brandColors.laasPurple};
    }

    &:hover::before {
      border-right: 33px solid ${brandColors.laasPurple60};
    }
  }

  .DayPickerKeyboardShortcuts_show__topLeft {
    &::before {
      border-left: 33px solid ${brandColors.laasPurple};
    }

    &:hover::before {
      border-left: 33px solid ${brandColors.laasPurple60};
    }
  }
`;

export const dateInputErrorStyles = css`
  .DateInput_input {
    border: 2px solid ${brandColors.systemError};
    padding-left: 16px;
  }

  .DateInput_input__focused {
    border: 2px solid ${brandColors.laasPurple};
  }
`;

export const singleDatePickerStyles = css`
  .SingleDatePickerInput__withBorder {
    border: none;
  }

  .DateInput {
    width: 145px;
  }
`;
