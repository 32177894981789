import { toast, ToastOptions } from 'react-toastify';
import { ThumbsUpIcon, ThumbsUpIcon24 } from '../icons/ThumbsUpIcon';
import styled from '@emotion/styled';
import { InfoIcon34 } from '../icons/InfoIcon34';
import { BouncingLoader } from '../loader/BouncingLoader';
import {
  Info,
  WarningAmber24CurrentColor,
  WarningAmber40CurrentColor,
} from '../icons';

export type ToastType = 'success' | 'info' | 'error';

export interface ShowToastOptions {
  type: ToastType;
  title: React.ReactNode;
  content?: React.ReactNode;
}

type IconSize = 'small' | 'large';

const iconByType: { [t in ToastType]: { [i in IconSize]: React.ReactNode } } = {
  success: {
    small: <ThumbsUpIcon24 />,
    large: <ThumbsUpIcon />,
  },
  info: {
    small: <Info />,
    large: <InfoIcon34 />,
  },
  error: {
    small: <WarningAmber24CurrentColor />,
    large: <WarningAmber40CurrentColor />,
  },
};

const TitleText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.294737px;
`;

const TitleTextNoContent = styled.div`
  /* Body L */

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  /* identical to box height, or 167% */

  letter-spacing: -0.22px;
`;

const ToastContentContainer = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.22px;
  justify-content: center;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  grid-template-rows: auto 1fr;
`;

const TitleContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  color: white;
`;

const ContentContainer = styled.div`
  grid-column: 2;
  grid-row: 2;
`;

export const renderToastContent = (
  options: ShowToastOptions,
  toastOptions: ToastOptions = {},
) => {
  const hasContent = !!options.content;
  const iconKey = hasContent ? 'large' : 'small';
  return (
    <ToastContentContainer>
      <IconContainer>
        {toastOptions.isLoading ? (
          <BouncingLoader radius={32} />
        ) : (
          <>{iconByType[options.type][iconKey]}</>
        )}
      </IconContainer>
      <TitleContainer>
        {options.content ? (
          <TitleText>{options.title}</TitleText>
        ) : (
          <TitleTextNoContent>{options.title}</TitleTextNoContent>
        )}
      </TitleContainer>
      {options.content ? (
        <ContentContainer>{options.content}</ContentContainer>
      ) : null}
    </ToastContentContainer>
  );
};

export const getToastOptions = (
  options: ShowToastOptions,
  toastOptions: ToastOptions = {},
) => ({
  icon: false,
  type: options.type,
  ...toastOptions,
});

export const showToast = (
  options: ShowToastOptions,
  toastOptions: ToastOptions = {},
) => {
  return toast(
    renderToastContent(options, toastOptions),
    getToastOptions(options, toastOptions),
  );
};

export const updateToast = (
  toastId: string,
  options: ShowToastOptions,
  toastOptions: ToastOptions = {},
) => {
  return toast.update(toastId, {
    ...getToastOptions(options, toastOptions),
    render: renderToastContent(options, toastOptions),
  });
};

let currentId = 0;
export const getToastId = () => {
  currentId += 1;
  return `toast-${currentId}`;
};
