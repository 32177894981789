import { SVGProps } from 'react';

export const QueryStats = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M26.1534 24.7286C26.7299 23.817 27.0706 22.7621 27.0706 21.616C27.0706 18.3733 24.4368 15.7556 21.1741 15.7556C17.9113 15.7556 15.2775 18.3733 15.2775 21.616C15.2775 24.8588 17.9113 27.4765 21.161 27.4765C22.3141 27.4765 23.3885 27.1379 24.2927 26.5649L28.381 30.6281L30.2416 28.7788L26.1534 24.7286ZM21.1741 24.8719C19.3658 24.8719 17.8982 23.4132 17.8982 21.616C17.8982 19.8188 19.3658 18.3602 21.1741 18.3602C22.9823 18.3602 24.4499 19.8188 24.4499 21.616C24.4499 23.4132 22.9823 24.8719 21.1741 24.8719ZM20.7023 13.8021C19.7327 13.8281 18.8023 14.0365 17.9506 14.3881L17.2299 13.3072L12.2506 21.3556L8.30648 16.7714L3.54992 24.3379L1.41406 22.8142L7.96579 12.3956L11.8968 16.9537L17.1382 8.4886L20.7023 13.8021ZM24.0961 14.4533C23.2575 14.0886 22.3534 13.8672 21.4099 13.8151L28.1189 3.2793L30.2416 4.81604L24.0961 14.4533Z"
        fill="#907BA6"
      />
    </g>
    <defs>
      <clipPath id="clip0_4850_36280">
        <rect
          width="31.4483"
          height="31.2558"
          fill="white"
          transform="translate(0.103516 0.673828)"
        />
      </clipPath>
    </defs>
  </svg>
);
