import {
  InputHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { animationDuration, brandColors } from '../constants';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;

  input,
  label {
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  width: 40px;
  height: 24px;
  position: relative;
  flex-shrink: 0;
`;

const Input = styled.input<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  appearance: none;
  outline: none;

  background: ${(props) => (props.disabled ? 'white' : css`#ebe9e9`)};

  border: 1px solid
    ${(props) =>
      props.disabled ? brandColors.coalGrey20 : brandColors.lightGreyBorderAA};
  box-sizing: border-box;
  border-radius: 50vh;

  :checked:enabled {
    background: white;
    border: 1px solid ${brandColors.laasPurple};
  }

  :active,
  :focus {
    box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
  }
`;

const Slider = styled.div<{ disabled?: boolean; checked?: boolean }>`
  position: absolute;
  pointer-events: none;
  height: 24px;
  width: 26px;
  left: 0px;
  top: 0px;

  background: ${(props) => {
    const checkedColor = props.checked
      ? brandColors.laasPurple
      : brandColors.lightGreyBorderAA;
    return props.disabled ? css`#d3d2d1` : checkedColor;
  }};

  transform: translateX(${(props) => (props.checked ? '14px' : 0)});

  transition: ${animationDuration.medium};
  border-radius: 50%;
`;

const Label = styled.label<{ disabled?: boolean }>`
  padding-left: 13px;

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;

  letter-spacing: -0.22px;

  color: ${(props) =>
    props.disabled ? brandColors.coalGrey50 : brandColors.laasPurple};
`;

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Switch = ({
  children,
  className,
  ...inputProps
}: PropsWithChildren<SwitchProps>) => {
  const [checked, setChecked] = useState(
    inputProps.defaultChecked || inputProps.checked,
  );

  useEffect(() => setChecked(inputProps.checked), [inputProps.checked]);
  return (
    <Container
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <InputContainer>
        <Input
          {...inputProps}
          checked={checked}
          type="checkbox"
          onChange={(event) => {
            setChecked(event.target.checked);
            inputProps.onChange?.(event);
          }}
        />
        <Slider disabled={inputProps.disabled} checked={checked} />
      </InputContainer>
      <Label disabled={inputProps.disabled} htmlFor={inputProps.id}>
        {children}
      </Label>
    </Container>
  );
};
