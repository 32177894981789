import { ReactNode } from 'react';
import { BodyLSecondary } from '../typography';
import { FormItemLabel } from './FormItemLabel';

export interface ReadonlyProps {
  label: ReactNode;
  value: ReactNode;
}

export const Readonly = ({ label, value }: ReadonlyProps) => (
  <FormItemLabel label={label}>
    <BodyLSecondary>{value}</BodyLSecondary>
  </FormItemLabel>
);
