import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { brandColors } from '../constants';
import { buttonTextStyles } from '../typography';
import { textColors } from '../typography/semantic/textColors';
import { PlainButton } from './PlainButton';

const StyledButton = styled(PlainButton)`
  gap: 8px;
  flex-shrink: 0;

  ${buttonTextStyles}
  color: ${textColors.primary};

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:disabled {
    color: ${brandColors.coalGrey20};
    cursor: not-allowed;
  }
`;

export interface SupplementaryButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export const SupplementaryButton = forwardRef<
  HTMLButtonElement,
  SupplementaryButtonProps
>((props, ref) => (
  <StyledButton {...props} ref={ref}>
    {props.icon}
    {props.children}
    {props.rightIcon}
  </StyledButton>
));
SupplementaryButton.displayName = 'SupplementaryButton';
