import * as Sentry from '@sentry/nextjs';

const CLOUDINARY_SCHEME = 'cloudinary://';

export const getImageId = (url: string | undefined | null) => {
  if (!url) {
    return url;
  }
  if (url.startsWith(CLOUDINARY_SCHEME)) {
    return url.replace(CLOUDINARY_SCHEME, '');
  } else {
    Sentry.captureMessage('invalid cloudinary url ' + url, {
      level: 'error',
    });
  }
};
