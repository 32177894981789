import Lottie from 'react-lottie-player';
import animationJson from './FormFilledAnimation.json';

export interface FormFilledAnimationArgs {}

export function FormFilledAnimation({ className }: { className?: string }) {
  return (
    <Lottie
      className={className}
      animationData={animationJson}
      play
      loop={false}
    />
  );
}
