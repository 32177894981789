export const Work = (props: any) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0746754 10.887L2.25624 14.2983C2.40803 14.0659 2.6039 13.8707 2.84032 13.7167L1.0224 10.8738L5.42339 8.92706L7.72361 12.5245L8.05263 11.5441L5.98498 8.30977C5.81248 8.04088 5.60536 7.97403 5.33197 8.08918L0.22173 10.3492C-0.00047946 10.4461 -0.0673495 10.6647 0.0746754 10.887Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.599 16.5179H17.7472L16.9479 10.927C16.5461 8.44569 15.1138 7.35547 13.2145 7.35547C11.8312 7.35547 10.5575 8.11078 9.8317 9.86071L8.06384 13.9379L4.92904 14.2362L5.39052 13.0808C5.50359 12.799 5.36153 12.4807 5.07425 12.3704C4.78525 12.2591 4.46124 12.3991 4.34845 12.6809L3.6657 14.3892C3.14711 14.5444 2.7832 14.9283 2.7832 15.6524C2.7832 16.5179 3.35546 16.9847 4.33267 16.9847H9.37281C10.1069 16.9847 10.6686 16.5817 11.0262 15.8672C11.5169 14.8869 12.3515 13.1966 12.8738 12.1368L13.8085 12.3868L12.0633 15.8672C11.9089 16.1405 11.7583 16.3534 11.599 16.5179Z"
      fill="#907BA6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28613 3.11116C7.28613 4.82944 8.81196 6.22177 10.6933 6.22177C12.5749 6.22177 14.101 4.82944 14.101 3.11116C14.101 1.39287 12.5749 0 10.6933 0C8.81196 0 7.28613 1.39287 7.28613 3.11116Z"
      fill="#907BA6"
    />
  </svg>
);
