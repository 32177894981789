import {
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
} from 'react-dates';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import {
  useFloating,
  shift,
  flip,
  offset,
  autoUpdate,
} from '@floating-ui/react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { defaultStyles, singleDatePickerStyles } from './common/styles';
import { zIndex } from '../constants';
import styled from '@emotion/styled';

export type SingleDatePickerControlProps =
  PropsWithChildren<DayPickerSingleDateControllerShape>;

const ControlContainer = styled.div`
  display: inline-flex;
`;

const Container = styled.div``;

export const SingleDatePickerControl = ({
  children,
  ...rest
}: SingleDatePickerControlProps) => {
  const { x, y, strategy, refs } = useFloating({
    middleware: [shift({ padding: 8 }), flip(), offset(8)],
    whileElementsMounted: autoUpdate,
  });

  return (
    <Container>
      <OutsideClickHandler
        onOutsideClick={() => rest.onFocusChange({ focused: false })}
        display="inline-block"
      >
        <ControlContainer ref={refs.setReference}>{children}</ControlContainer>

        {rest.focused && (
          <div
            ref={refs.setFloating}
            className={classNames([defaultStyles, singleDatePickerStyles])}
            style={{
              position: strategy,
              left: x ?? '',
              top: y ?? '',
              zIndex: zIndex.tooltip,
            }}
          >
            <DayPickerSingleDateController {...rest} />
          </div>
        )}
      </OutsideClickHandler>
    </Container>
  );
};
