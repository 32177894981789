import styled from '@emotion/styled';
import { AnchorHTMLAttributes } from 'react';
import { ChevronLeft } from '../icons';
import { BoldLink } from '../typography';

const LinkWrapper = styled(BoldLink)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const GoBackLink = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <LinkWrapper {...props}>
    <ChevronLeft />
    {props.children}
  </LinkWrapper>
);
