import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Measure, { Rect } from 'react-measure';
import { HeadingS } from '../../typography';
import { animationDuration, brandColors, mediaQuery } from '../../constants';
import { ErrorExclamationMark } from '../../icons';

export type StepState = 'default' | 'active' | 'disabled';

export const stepBallDiagonal = {
  large: 48,
  medium: 40,
  small: 32,
};

const StepNumber = styled.div<{ state: StepState }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: ${stepBallDiagonal.large}px;
  height: ${stepBallDiagonal.large}px;

  border: 1px solid
    ${(props) =>
      props.state === 'disabled'
        ? brandColors.coalGrey20
        : brandColors.laasPurple};
  box-sizing: border-box;
  border-radius: 50vh;

  filter: ${(props) =>
    props.state === 'active'
      ? css`drop-shadow(5px 4px 12px rgba(77, 42, 111, 0.1))`
      : 'none'};

  margin-right: 24px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.05em;

  @media screen and ${mediaQuery.medium} {
    width: ${stepBallDiagonal.medium}px;
    height: ${stepBallDiagonal.medium}px;
    margin-right: 0px;
  }

  @media screen and ${mediaQuery.small} {
    width: ${stepBallDiagonal.small}px;
    height: ${stepBallDiagonal.small}px;
  }

  color: ${(props) => {
    if (props.state === 'default') {
      return brandColors.laasPurple;
    }
    if (props.state === 'disabled') {
      return brandColors.coalGrey20;
    }
    return 'white';
  }};
  transition: background ${animationDuration.long} ease-in-out;

  background: ${(props) =>
    props.state === 'active'
      ? brandColors.laasPurple
      : brandColors.laasLightBg};
`;

const StepLabel = styled(HeadingS)<{ isDisabled: boolean }>`
  white-space: nowrap;

  color: ${(props) =>
    props.isDisabled ? brandColors.coalGrey20 : brandColors.laasPurple};
  @media screen and ${mediaQuery.medium} {
    display: none;
  }
`;

const StepButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  display: flex;
  align-items: center;

  padding: 0 48px 0 0;
  @media screen and ${mediaQuery.medium} {
    padding: 0 24px 0 0;
  }
  &:not(:disabled) {
    cursor: pointer;
  }

  filter: brightness(100%);

  &:hover:not(:active) {
    &:not(:disabled) {
      filter: brightness(150%);
    }
  }

  transition: filter ${animationDuration.long} ease-in-out;
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepErrorMarker = styled(ErrorExclamationMark)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;

export interface StepProps {
  step: number;
  state: StepState;
  error?: boolean;
  onClick: () => void;
  onMeasure: (step: number, rect: Rect) => void;
  'data-test'?: string;
}

export const Step = (props: PropsWithChildren<StepProps>) => (
  <Measure
    bounds
    onResize={(rect) =>
      rect.bounds && props.onMeasure?.(props.step!, rect.bounds)
    }
  >
    {({ measureRef }) => (
      <StepWrapper
        ref={measureRef}
        role="tab"
        aria-selected={props.state === 'active'}
      >
        <StepButton
          onClick={props.onClick}
          disabled={props.state === 'disabled'}
          data-test={props['data-test']}
        >
          <StepNumber state={props.state!}>
            <span>{props.step! + 1}</span>
            {props.error && <StepErrorMarker />}
          </StepNumber>
          <StepLabel isDisabled={props.state === 'disabled'}>
            {props.children}
          </StepLabel>
        </StepButton>
      </StepWrapper>
    )}
  </Measure>
);
