import styled from '@emotion/styled';
import { KeyboardArrowDown } from './KeyboardArrowDown';
import { animationDuration } from '../constants';

export const DropdownExpansionChevron = styled(KeyboardArrowDown, {
  shouldForwardProp: () => false,
})<{ isOpen: boolean }>`
  width: 24px;
  height: 24px;
  transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
  transition: transform ${animationDuration.short} ease-in-out !important;
`;
