import styled from '@emotion/styled';
import { NotificationBadgeWrapper } from '@laasco/ui-lib/indicator/NotificationBadgeWrapper';
import Link from 'next/link';
import { UnstyledLink } from '../../../UnstyledLink';
import { brandColors } from '../../../constants';
import { bodyLStyles } from '../../../typography';
import { NavChildrenContextProvider } from '../context/NavChildrenContext';
import { NavItemContextProvider } from '../context/NavItemContext';
import { DropDownMenuProps, LinkMenuItemProps } from '../types';
import { DesktopInnerDropDownMenu } from './DesktopInnerDropDownMenu';
import { DesktopNavDropdownButton } from './DesktopNavDropdownButton';
import { activeLinkStyles, hoverTextDecoration } from './styles';

const MenuItemContainer = styled.div<{ active?: boolean }>`
  ${bodyLStyles};
  color: ${brandColors.laasPurple};
  :hover {
    ${hoverTextDecoration};
  }
  ${(p) => (p.active ? activeLinkStyles : null)};
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 8px 24px;
`;

const MenuItem = (props: LinkMenuItemProps) => {
  return (
    <Link href={props.href || '#'} passHref legacyBehavior>
      <UnstyledLink
        onClick={props.onClick}
        data-test={props.dataTest && `navbar-${props.dataTest}`}
      >
        <MenuItemContainer active={props.active}>
          {props.icon}
          <NotificationBadgeWrapper
            showBadge={!!props.showBadge}
            right={-16}
            top={-4}
          >
            {props.label}
          </NotificationBadgeWrapper>
        </MenuItemContainer>
      </UnstyledLink>
    </Link>
  );
};

const MenuContainer = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  bottom: 0;
  background: white;
  border-bottom: 6px solid ${brandColors.lightPurpleBorderAA};
  min-width: 256px;
  box-shadow: 0px 4px 16px rgba(77, 42, 111, 0.1);
`;

const StyledInnerDropdownMenu = styled(DesktopInnerDropDownMenu)`
  padding: 12px 24px;
`;

export const NavDropDownMenu = (
  props: Omit<DropDownMenuProps, 'type'> & { fullHeightButton?: boolean },
) => {
  return (
    <DesktopNavDropdownButton
      onClick={props.onClick}
      label={props.label}
      active={!!props.active}
      fullHeightButton={props.fullHeightButton}
      dataTest={props.dataTest && `${props.dataTest}`}
    >
      <MenuContainer>
        <NavChildrenContextProvider>
          {props.children.map((item, i) => {
            switch (item.type) {
              case 'LinkMenuItem':
                return (
                  <MenuItem
                    {...item}
                    key={`${item.type}-${i}`}
                    dataTest={
                      item.dataTest && `${props.dataTest}-${item.dataTest}`
                    }
                  />
                );
              case 'DropDownMenu':
                return (
                  <NavItemContextProvider
                    key={`${item.type}-${i}`}
                    id={`${item.type}-${i}`}
                  >
                    <StyledInnerDropdownMenu
                      {...item}
                      dataTest={
                        item.dataTest && `${props.dataTest}-${item.dataTest}`
                      }
                    />
                  </NavItemContextProvider>
                );
            }
          })}
        </NavChildrenContextProvider>
      </MenuContainer>
    </DesktopNavDropdownButton>
  );
};
